import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../features/remote/config/Config";
import AlternativePageLayout from "../layout/AlternativePageLayout";
import React from "react";

type ErrorPageProps = {
    error: Error;
    allowBack?: boolean;
    customAction?: {
        action: () => any;
        label: string;
    }
}

export default function ErrorPage({ error, allowBack, customAction }: ErrorPageProps){

    const navigate = useNavigate();
    const { parseMessageFromError } = useConfig();

    return <AlternativePageLayout>
        <Box
            position='absolute'
            top={0}
            left={0}
            height='100vh'
            width='100vw'
            display='flex'
            justifyContent="center"
            alignItems="center"
        >
            <Stack direction="column" alignItems="center" spacing={2}>
                <Typography variant="h3" color="white1.main">Errore</Typography>
                <Typography color="white1.main">{parseMessageFromError(error)}</Typography>
                {
                    allowBack &&
                    <Button color="white1" onClick={() => navigate(-1)}>
                        Torna indietro
                    </Button>
                }
                {
                    customAction &&
                    <Button color="white1" onClick={customAction.action}>
                        {customAction.label}
                    </Button>
                }
            </Stack>
        </Box>
    </AlternativePageLayout>
}