import React, { SyntheticEvent, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography, Alert } from "@mui/material";
import { addBalanceEvent, getError, isLoading } from "../features/remote/events/BalanceEventsSlice";
import { BalanceEventRequest } from "../features/remote/events/BalanceEventsAPI";
import { unwrapResult } from "@reduxjs/toolkit";
import { useConfig } from "../features/remote/config/Config";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { User } from "../features/remote/users/UsersAPI";
import { NumberTextField } from "./NumberTextField";

type ComponentProps = {
  user: User;
  handleClose: () => any;
  open: boolean;
};

export default function AddBalanceEventDialog({ user, handleClose, open }: ComponentProps){

  const [value, setValue] = useState('');
  const error = useAppSelector(getError);
  const loading = useAppSelector(isLoading);
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();

  function createEvent(event: SyntheticEvent){
    event.preventDefault();
    const balanceEvent = new BalanceEventRequest(
      { acconto: value },
      user.email
    );
    dispatch(addBalanceEvent(balanceEvent))
    .then(unwrapResult)
    .then(handleClose);
  }

  useEffect(
    () => {
      if(!open)
        setValue('');
    },
    [open]
  );

  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
  >
    <form onSubmit={createEvent}>
      <DialogTitle>Aggiungi pagamento</DialogTitle>
      <DialogContent dividers>
        {
          error &&
          <Alert
            severity="error"
            sx={{ width: 1 }}
          >
            {parseMessageFromError(error)}
          </Alert>
        }
        <NumberTextField
          value={value}
          onChange={event => setValue(event.target.value)}
          inputProps={{
            min: 0,
            step: 0.01
          }}
          InputProps={{
            startAdornment: <Typography sx={{ mr: 1 }}>&euro;</Typography>
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          width={1}
        >
          <Button onClick={handleClose} color="error" disabled={loading}>
            Annulla
          </Button>
          <Button type="submit" disabled={loading}>
            Aggiungi
          </Button>
        </Stack>
      </DialogActions>
    </form>
  </Dialog>
}