import { withAuth0, Auth0ContextInterface, withAuthenticationRequired, IdToken } from '@auth0/auth0-react';
import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { UserIdentity } from '../features/remote/connector/ConnectorAPI';
import WaitingPage from './WaitingPage';
import ErrorPage from './ErrorPage';
import { getLoadedSettings, isLoading, getSettings } from '../features/remote/settings/SettingsSlice';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../app/store';
import { Settings } from '../features/remote/settings/SettingsAPI';

type UserRouteProps = {
    auth0: Auth0ContextInterface;
    settings: Settings;
    isLoadingSettings: boolean;
    getSettings: () => any;
};
type UserRouteState = {
    enabledUser: boolean;
    disconnectUser: (params: any) => any,
    settings: Settings;
    isLoadingSettings: boolean;
};

class UserRoute extends Component<UserRouteProps, UserRouteState>{
    constructor(props: UserRouteProps){
        super(props);
        const { auth0, settings, isLoadingSettings } = props;
        const { user, logout } = auth0;
        this.state = {
            enabledUser: !!user && !!user["https://rivendita.ipaladini/user"]?.abilitato,
            disconnectUser: logout,
            settings,
            isLoadingSettings
        };
        async function retrieveToken(){
            const { getIdTokenClaims } = auth0;
            const token = await getIdTokenClaims();
            return token;
        };
        UserIdentity.build(retrieveToken() as Promise<IdToken>);
    }
    componentDidMount(){
        if(
            !this.state.enabledUser &&
            !this.state.settings &&
            !this.state.isLoadingSettings
        )
            this.props.getSettings();
    }
    render(){
        if(this.state.enabledUser)
            return <Outlet />;
        return this.state.isLoadingSettings ?
            <WaitingPage /> :
            <ErrorPage
                error={new Error(
                    this.state.settings?.howToGetAbilitationMessage ||
                    "Account non ancora abilitato. Attendi la conferma del proprietario."
                )}
                customAction={
                    {
                        action: () => this.state.disconnectUser(
                            { returnTo: window.location.origin }
                        ),
                        label: 'Esci'
                    }
                }
            />;
    }
}

const mapStateToProps = (state: RootState) => ({
    settings: getLoadedSettings(state),
    isLoadingSettings: isLoading(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getSettings: () => dispatch(getSettings())
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withAuth0(withAuthenticationRequired(UserRoute, {
        onRedirecting: () => <WaitingPage />,
    }))
);