import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../utils';
import { PagedResponse } from '../connector/ConnectorAPI';
import { getPendingOrdersGroups as getPendingOrdersGroupsApi, addOrder as addOrderApi, getOrders as getOrdersApi, getOrder as getOrderApi, changeOrderStatus as changeOrderStatusApi, getComanda as getComandaApi, Order, OrderGroup, Comanda, OrderRequest, OrderState, DetailedOrder } from './OrdersAPI';

interface OrdersState{
  status: 'idle' | 'loading',
  error: Error | null,
  generatedComanda: Comanda | null,
  loadedOrders: Record<number, DetailedOrder | Order>,
  loadedOrdersPage: PagedResponse<Order>,
  loadedOrdersGroups: PagedResponse<OrderGroup>
}
const initialState = {
  status: 'idle',
  error: null,
  generatedComanda: null,
  loadedOrders: {},
  loadedOrdersPage: {
    count: 0,
    data: [],
    page: 0
  },
  loadedOrdersGroups: {
    count: 0,
    data: [],
    page: 0
  }
} as OrdersState;

interface FetchPagedOptions{
  page: number;
  length: number;
}

export const addOrder = createAsyncThunk(
  'remote/orders/add',
  async (order: OrderRequest, { rejectWithValue }) => {
    try{
      const response = await addOrderApi(order);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface ChangeOrderStatusRequest{
  id: number;
  state: OrderState
}
export const changeOrderStatus = createAsyncThunk(
  'remote/orders/changeStatus',
  async ({ id, state }: ChangeOrderStatusRequest, { rejectWithValue }) => {
    try{
      const response = await changeOrderStatusApi(id, state);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface FetchOrdersOptions extends FetchPagedOptions{
  ids?: number[];
}
export const getOrders = createAsyncThunk(
  'remote/orders/getAll',
  async (
    { page, length, ids }: FetchOrdersOptions,
    { rejectWithValue }
  ) => {
    try{
      const response = await getOrdersApi(page, length, ids);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const getPendingOrdersGroups = createAsyncThunk(
  'remote/orders/getGroups',
  async ({ page, length }: FetchPagedOptions, { rejectWithValue }) => {
    try{
      const response = await getPendingOrdersGroupsApi(page, length);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface FetchOrderOptions{
  id: number;
}
export const getOrder = createAsyncThunk(
  'remote/orders/get',
  async ({ id }: FetchOrderOptions, { rejectWithValue }) => {
    try{
      const response = await getOrderApi(id);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const getComanda = createAsyncThunk(
  'remote/orders/get_comanda',
  async (orders: number[], { rejectWithValue }) => {
    try{
      const response = await getComandaApi(orders);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const ordersSlice = createSlice({
  name: 'remote/orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addOrder.fulfilled, (state, action) => {
        state.status = 'idle';
        const order = action.payload;
        state.loadedOrders[order.id] = order;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedOrdersPage = action.payload;
      })
      .addCase(getPendingOrdersGroups.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedOrdersGroups = action.payload;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.status = 'idle';
        const order = action.payload;
        state.loadedOrders[order.id] = order;
      })
      .addCase(changeOrderStatus.fulfilled, (state, action) => {
        state.status = 'idle';
        const freshOrder = action.payload;
        if(state.loadedOrders[freshOrder.id]){
          state.loadedOrders[freshOrder.id].copyFrom(freshOrder);
        }else
        state.loadedOrders[freshOrder.id] = freshOrder;
        state.loadedOrdersPage.data = state.loadedOrdersPage.data.map(
          order =>{
            if(order.id === freshOrder.id)
              order.copyFrom(freshOrder);
            return order;
          }
        )
      })
      .addCase(getComanda.fulfilled, (state, action) => {
        state.status = 'idle';
        const comanda = action.payload;
        state.generatedComanda = comanda;
      })
      .addMatcher(isNamespacePending('remote/orders'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/orders'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getGeneratedComanda = (state: RootState) => state.remote.orders.generatedComanda;
export const getLoadedOrderById =
  (id: number) => (state: RootState) => state.remote.orders.loadedOrders[id];
export const getLoadedOrders = (state: RootState) => state.remote.orders.loadedOrdersPage;
export const getLoadedOrdersGroups =
  (state: RootState) => state.remote.orders.loadedOrdersGroups;
export const isLoading = (state: RootState) => state.remote.orders.status === "loading";
export const getError = (state: RootState) => state.remote.orders.error;

export default ordersSlice.reducer;
