import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, GridColumns, GridRowId } from '@mui/x-data-grid';
import PageLayout from '../layout/PageLayout';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getError, getLoadedOrders, getLoadedOrdersGroups, getPendingOrdersGroups } from '../features/remote/orders/OrdersSlice';
import { isLoading, getOrders } from '../features/remote/orders/OrdersSlice';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Card, CardActionArea, CardHeader, Grid, IconButton, Menu, MenuItem, Pagination, Typography } from '@mui/material';
import { useConfig } from '../features/remote/config/Config';
import { ArrowForwardIos } from '@mui/icons-material';
import { User } from '../features/remote/users/UsersAPI';

const itTranslation = require('../features/datagrid/locale/it');

const ORDERS_PER_PAGE =
  process.env.REACT_APP_ORDERS_PER_PAGE ?
  parseInt(process.env.REACT_APP_ORDERS_PER_PAGE) :
  10;
const PENDING_ORDERS_PER_PAGE =
  process.env.REACT_APP_PENDING_ORDERS_PER_PAGE ?
  parseInt(process.env.REACT_APP_PENDING_ORDERS_PER_PAGE) :
  4;

const columns: GridColumns = [
  {
    field: 'timestamp', headerName: 'Data', flex: 0.3, minWidth: 100, type: 'date',
    valueFormatter: (params) => (params.value as Date).toLocaleDateString()
  },
  { field: 'state', headerName: 'Stato', flex: 0.75, minWidth: 90 },
  { field: 'id', headerName: 'Codice', flex: 0.75, minWidth: 150 },
  {
    field: 'total', headerName: 'Totale', flex: 0.5, minWidth: 100,
    valueFormatter: (params) => `${params.value} €`
  },
  { field: 'address', headerName: 'Indirizzo', flex: 1, minWidth: 200 },
  { field: 'nicknameUser', headerName: 'Utente', flex: 0.75, minWidth: 150 }
];

type UserCardProps = {
  ordersCount: number;
  user: User;
  date: string;
  total: string;
  onClick: () => any;
}

function UserCard({ ordersCount, user, date, total, onClick }: UserCardProps){
  return <Card elevation={4}>
    <CardActionArea onClick={onClick} component="div">
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            {ordersCount}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <ArrowForwardIos />
          </IconButton>
        }
        title={user.nickname || user.email}
        subheader={`${date} — ${total} €`}
      />
    </CardActionArea>
  </Card>
}

export default function OrderList(){

  const orders = useAppSelector(getLoadedOrders);
  const ordersGroups = useAppSelector(getLoadedOrdersGroups);
  const ordersIsLoading = useAppSelector(isLoading);
  const apiError = useAppSelector(getError);
  const printButtonRef = useRef<HTMLButtonElement>(null);
  const ordersTableRef = useRef<HTMLDivElement>(null);
  const [showAllOrders, setShowAllOrders] = useState(true);
  const [ordersToShow, setOrdersToShow] = useState<number[]>([]);
  const [selected, setSelected] =
    useState<({ id: GridRowId, user: string, address: string })[]>([]);
  const [openPrintMenu, setOpenPrintMenu] = useState(false);
  const [page, setPage] = useState(0);
  const [pendingPage, setPendingPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  useEffect(
    () => {
      dispatch(
        getOrders({
          page,
          length: ORDERS_PER_PAGE,
          ids: !showAllOrders ? ordersToShow : undefined
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, ordersToShow]
  );
  
  useEffect(
    () => {
      dispatch(
        getPendingOrdersGroups({
          page: pendingPage,
          length: PENDING_ORDERS_PER_PAGE
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingPage]
  );

  useEffect(
    () => {
      if(apiError)
        enqueueSnackbar(parseMessageFromError(apiError), {variant: 'error'});
    },
    [apiError] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function handleSelectionChange(changed: GridRowId[]){
    setSelected(selected => {
      selected = selected.filter(
        currentSelected => changed.includes(currentSelected.id)
      );
      const newItems = changed.filter(
        change => selected.findIndex(already => already.id === change) < 0
      )
      for(const item of newItems){
        const order = orders?.data.find(order => order.id === item);
        if(order){
          selected.push({
            id: item,
            user: order.nicknameUser,
            address: order.address
          })
        }
      }
      return selected;
    })
  }

  function goToOrder({ id }: { id: GridRowId }){
    navigate(id.toString());
  }

  function handlePrint(noPrices: boolean){
    const ordersMap = selected.reduce<Record<string, number[]>>(
      (groupsMap, selection) => {
        const groupId = `${selection.user}/${selection.address}`;
        if(!groupsMap[groupId]) groupsMap[groupId] = [];
        groupsMap[groupId].push(selection.id as number);
        return groupsMap;
      },
      {}
    );
    const grouppedOrders = Object.values(ordersMap);
    navigate(
      'print',
      {
        state: {
          noPrices,
          orders: grouppedOrders
        }
      }
    );
  }
  function handleCompletePrint(){
    handlePrint(false);
  }
  function handleWhitoutPricesPrint(){
    handlePrint(true);
  }

  function goToOrdersProductsListPrinter(){
    navigate(
      'comanda',
      { state: { orders: selected.map(selection => selection.id) } }
    );
  }

  return <PageLayout pageTitle="Lista ordini">
    <Grid container direction="row" spacing={4}>
      <Grid item xs={12} sm={5} lg={4}
        container direction="column" alignItems="stretch" spacing={2}
      >
        <Grid item>
          <Typography
            variant="h5" sx={{ p: 2 }} color="black1.main"
          >
            Ordini pendenti
          </Typography>
        </Grid>
        {
          ordersGroups &&
          ordersGroups.data.map(
            ordersGroup =>
              <Grid item key={ordersGroup.user.email}>
                <UserCard
                  ordersCount={ordersGroup.orders?.length}
                  user={ordersGroup.user}
                  date={ordersGroup.timestamp.toLocaleDateString()}
                  total={ordersGroup.textualTotal}
                  onClick={() => {
                    setOrdersToShow(ordersGroup.orders);
                    setShowAllOrders(false);
                    ordersTableRef.current?.scrollIntoView();
                  }}
                />
              </Grid>
          )
        }
        {
          ordersGroups &&
          ordersGroups.count > 0 &&
          <Grid item mt={2} container justifyContent="center">
            <Pagination
              siblingCount={0}
              count={Math.ceil(ordersGroups.count/PENDING_ORDERS_PER_PAGE)}
              page={pendingPage + 1}
              color="primary"
              onChange={(_, value) => setPendingPage(value - 1)}
            />
          </Grid>
        }
        {
          (!ordersGroups ||
          ordersGroups.count <= 0) &&
          <Grid item mt={2} container justifyContent="center">
            <Typography variant="subtitle1" color="black3.main">
              Non sono presenti ordini pendenti.
            </Typography>
          </Grid>
        }
      </Grid>
      <Grid item xs={12} sm={7} lg={8}
        container direction="column" alignItems="stretch" spacing={2}
      >
        <Grid item container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={3} lg={3}>
            <Button
              variant="contained"
              onClick={() => {
                setOrdersToShow([]);
                setShowAllOrders(true);
              }}
              fullWidth
            >
              Mostra tutti
            </Button>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Button
              ref={printButtonRef}
              variant="contained"
              onClick={() => setOpenPrintMenu(true)}
              fullWidth
              disabled={selected.length <= 0}
            >
              Stampa dettagli
            </Button>
            <Menu
              anchorEl={printButtonRef.current}
              open={openPrintMenu}
              onClose={() => setOpenPrintMenu(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCompletePrint();
                  setOpenPrintMenu(false);
                }}
              >
                Completo
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleWhitoutPricesPrint();
                  setOpenPrintMenu(false);
                }}
              >
                Senza prezzi
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Button
              variant="contained"
              onClick={goToOrdersProductsListPrinter}
              fullWidth
              disabled={selected.length <= 0}
            >
              Stampa comanda
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <DataGrid
            ref={ordersTableRef}
            loading={ordersIsLoading}
            autoHeight={true}
            rows={orders?.data || []}
            columns={columns}
            rowCount={orders?.count || 0}
            autoPageSize={true}
            pageSize={ORDERS_PER_PAGE}
            pagination={true}
            paginationMode='server'
            onPageChange={setPage}
            onRowDoubleClick={goToOrder}
            keepNonExistentRowsSelected={true}
            selectionModel={selected.map(selection => selection.id)}
            onSelectionModelChange={handleSelectionChange}
            error={apiError}
            disableSelectionOnClick
            checkboxSelection
            sx={{
              width: '100%',
              border: 'none',
              color: 'black1.main'
            }}
            localeText={itTranslation}
          />
        </Grid>
      </Grid>
    </Grid>
  </PageLayout>
}