import { Button, Stack, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, { ReactInstance, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import OrderDetails from '../components/OrderDetails';
import AlternativePageLayout from '../layout/AlternativePageLayout';
import { getError, isLoading } from '../features/remote/orders/OrdersSlice';
import ErrorPage from './ErrorPage';
import WaitingPage from './WaitingPage';

type PageState = {
  noPrices?: boolean;
  orders: (number | number[])[];
}

export default function OrdersPrinter(){

  const loading = useSelector(isLoading);
  const lastError = useSelector(getError);
  const [generatedError, setGeneratedError] = useState<Error | undefined>();
  const [printed, setPrinted] = useState(false);
  const { state } = useLocation();
  const { noPrices, orders } = state as PageState;
  const printableRef = useRef<ReactInstance>();
  const theme = useTheme();
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => printableRef.current || null,
    bodyClass: noPrices ? "no-prices" : undefined
  });

  useEffect(
    () => {
      if(lastError)
        setGeneratedError(lastError);
    },
    [lastError]
  );

  function goBack(){
    navigate(-1);
  }

  function print(){
    handlePrint();
    setPrinted(true);
  }

  return <Box width="100vw" height="100vh" overflow="hidden">
    {
      !generatedError ? (
        !loading ?
        <AlternativePageLayout
          sx={{
            justifyContent: "center", height: `calc(100vh - ${theme.spacing(2)})`,
            displayPrint: 'none'
          }}
        >
          <Stack
            direction="column"
            alignItems="stretch"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h4" color="white1.main">
              { printed ? 'Stampa completata' : 'Ordini caricati' }
            </Typography>
            <Button color="white1" onClick={() => printed ? goBack() : print()}>
              { printed ? 'Torna indietro' : 'Stampa' }
            </Button>
          </Stack>
        </AlternativePageLayout> :
        <WaitingPage />
      ) :
      <ErrorPage error={generatedError} allowBack={true} />
    }
    <Box ref={printableRef}>
      {
        orders.map(
          orderCode =>
            <div key={orderCode instanceof Array ? orderCode.join("|") : orderCode}>
              <OrderDetails
                orderCode={orderCode}
                adminControls={true}
              />
            </div>
        )
      }
    </Box>
  </Box>;
}