import { AnyAction, isPending, isRejectedWithValue } from "@reduxjs/toolkit";

export function isNamespacePending(namespace: string):
    (action: any) => boolean
{
    return (action: AnyAction) => action.type.startsWith(namespace) && isPending(action);
}

export function isNamespaceRejectedWithValue(namespace: string):
    (action: any) => boolean
{
    return (action: AnyAction) =>
        action.type.startsWith(namespace) && isRejectedWithValue(action);
}