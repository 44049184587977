import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Grid, InputAdornment } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { isLoading } from "../features/remote/events/commercialEvents/CommercialEventsSlice";
import { addSaleEvent } from "../features/remote/events/commercialEvents/SaleEventsActions";
import { SaleEventRequest } from "../features/remote/events/commercialEvents/SaleEventsAPI";
import ProductSearchField from "./ProductSearchField";
import QuantityUnitSelector from "./QuantityUnitSelector";
import { useConfig } from "../features/remote/config/Config";
import { Decimal } from "decimal.js";
import { Product, ProductStatistics } from "../features/remote/products/ProductsAPI";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { UnitDescriptionField } from "./UnitDescriptionField";
import { NumberTextField } from "./NumberTextField";

type AddSaleEventDialogProps = {
  orderId: number;
  open: boolean;
  handleClose: () => any;
}

export default function AddSaleEventDialog(
  { orderId, open, handleClose }: AddSaleEventDialogProps
){

  const [product, setProduct] = useState<Product | null>(null);
  const [stockUnit, setStockUnit] = useState<ProductStatistics>();
  const [packageUnitDescription, setPackageUnitDescription] = useState<string>('');
  const [quantity, setQuantity] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [totPrice, setTotPrice] = useState('');
  const loading = useAppSelector(isLoading);
  const { enqueueSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if(!open){
        setProduct(null);
        setStockUnit(undefined);
        setPackageUnitDescription('');
        setQuantity('');
        setUnitPrice('');
        setTotPrice('');
      }
    },
    [open]
  );
  useEffect(
    () => {
      setStockUnit(undefined);
      setPackageUnitDescription('');
      setQuantity('');
      setUnitPrice('');
      setTotPrice('');
    },
    [product]
  );
  useEffect(
    () => {
      if(product && stockUnit?.unit.name === "Collo")
        setPackageUnitDescription(
          (product as Product).prices.map(
            price => price.measure
          ).find(
            measure => measure.name === "Collo"
          )?.description || ''
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stockUnit]
  );

  function createEvent(event: SyntheticEvent){
    event.preventDefault();
    if(product && stockUnit){
      const saleEvent = new SaleEventRequest({
        ordine: orderId,
        quantita: quantity,
        costo: totPrice,
        unita: (stockUnit as ProductStatistics).unit.name,
        prodotto: product.id,
        descrizioneUnita: packageUnitDescription
      });
      dispatch(addSaleEvent(saleEvent))
      .then(unwrapResult)
      .then(handleClose)
      .catch(
        error => enqueueSnackbar(
          parseMessageFromError(error),
          { variant: 'error' }
        )
      );
    }
  }

  function handleUnitPriceChange(event: React.ChangeEvent<HTMLInputElement>){
    const value = event.target.value;
    updateUnitPrice(value);
  }
  function updateUnitPrice(value: string){
    let totPrice: string = "";
    if(value){
      const unitPrice = new Decimal(value);
      totPrice = unitPrice.times(quantity || 0).toFixed(3);
    }
    setPricesFields(value, totPrice);
  }
  function handleTotPriceChange(event: React.ChangeEvent<HTMLInputElement>){
    const value = event.target.value;
    updateTotPrice(value);
  }
  function updateTotPrice(value: string){
    let unitPrice: string = "";
    if(value){
      const totPrice = new Decimal(value);
      unitPrice = (totPrice.dividedBy(quantity || 0)).toFixed(3);
    }
    setPricesFields(unitPrice, value);
  }
  function setPricesFields(unitPrice: string, totPrice: string){
    setUnitPrice(unitPrice);
    setTotPrice(totPrice);
  }

  useEffect(
    () => {
      if(totPrice)
        updateTotPrice(totPrice);
      else if(unitPrice)
        updateUnitPrice(unitPrice);
    }, [quantity] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
  >
    <form onSubmit={createEvent}>
      <DialogTitle>Aggiungi prodotto</DialogTitle>
      <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProductSearchField
                selection={product || undefined}
                onSelectionChange={selection => setProduct(selection)}
                disabled={loading}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <QuantityUnitSelector
                productId={product?.id}
                fullWidth
                value={stockUnit}
                onChange={setStockUnit}
                SelectProps={{
                  disabled: loading,
                  required: true
                }}
                disabled={loading}
              />
            </Grid>
            {
              stockUnit?.unit.name === "Collo" &&
              <Grid item xs={12}>
                <UnitDescriptionField
                  value={packageUnitDescription}
                  onChange={setPackageUnitDescription}
                  descriptionPrefix="Collo da "
                  disabled={loading}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <NumberTextField
                label="Quantità"
                value={quantity}
                onChange={event => setQuantity(event.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {stockUnit && stockUnit.unit.name}
                  </InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  step: stockUnit ? stockUnit.unit.step.toFixed(2) : 0
                }}
                helperText={stockUnit && `in magazzino ${stockUnit.textualAvailable}`}
                fullWidth
                disabled={loading}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                label="Prezzo unitario"
                value={unitPrice}
                onChange={handleUnitPriceChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">&euro;</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  step: 0.001
                }}
                fullWidth
                disabled={loading}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                label="Prezzo totale"
                value={totPrice}
                onChange={handleTotPriceChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">&euro;</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  step: 0.001
                }}
                fullWidth
                autoComplete="off"
                disabled={loading}
                required
              />
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: 1 }}
        >
          <Button onClick={handleClose} color="error">
            Chiudi
          </Button>
          <Button type="submit">
            Aggiungi
          </Button>
        </Stack>
      </DialogActions>
    </form>
  </Dialog>
}