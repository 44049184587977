import { Box, Chip, Grid, GridProps, IconButton, List, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { ExtendedProduct, ProductStatistics } from "../features/remote/products/ProductsAPI";
import AddToCartDialog from "./AddToCartDialog";
import ProductCard from "./ProductCard";
import ProductsList from "./ProductsList";

function SkeletonCards(){
  return <>{
    [1, 2, 3, 4, 5, 6].map(
      i => <Grid
        item key={i} xs={6} md={4} lg={2}
        sx={{
          "@media screen and (max-width: 350px)":{
            minWidth: '100%'
          }
        }}
      >
        <Skeleton
          variant="rectangular" width="100%" sx={{ borderRadius: '2rem' }}
        >
          <Box width={1} height={140}></Box>
          <Box sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>Title</Typography>
            <Chip sx={{ m: 0.5 }} label="Stock" />
          </Box>
        </Skeleton>
      </Grid>
    )
  }</>
}
function SkeletonRows(){
  return <List sx={{ width: 1 }}>
    {
      [95, 85, 120, 110].map(
        width => <ListItem key={width}>
          <Grid container alignItems="center" justifyContent="left">
            <Grid item>
              <IconButton>
                <Skeleton width="1em" variant="circular" />
              </IconButton>
            </Grid>
            <Grid item>
              <Skeleton width="2em" />
            </Grid>
            <Grid item>
              <IconButton>
                <Skeleton width="1em" variant="circular" />
              </IconButton>
            </Grid>
            <Grid item sx={{ ml: 2 }}>
              <ListItemText
                primary={<Skeleton width={`${width}px`} />}
                secondary={<Skeleton width="55px" />}
              />
            </Grid>
          </Grid>
        </ListItem>
      )
    }
  </List>
}

export type ViewMode = 'list' | 'grid';

type ProductsViewProps = {
  products: ExtendedProduct[];
  loading?: boolean;
  viewMode: ViewMode;
} & GridProps;

export default function ProductsView(
  { products, loading, viewMode, ...props }: ProductsViewProps
){
  const [selectedStock, setSelectedStock] = useState<ProductStatistics>();
  return <>
    <AddToCartDialog
      stock={selectedStock}
      open={!!selectedStock}
      onClose={() => setSelectedStock(undefined)}
    />
    <Grid container spacing={2} {...props}>
      {
        products &&
        viewMode === 'grid' ?
        products.map(
          product => <Grid
            item key={product.id} xs={6} sm={4} md={3} lg={2}
            sx={{
              "@media screen and (max-width: 350px)":{
                minWidth: '100%'
              }
            }}
          >
            <ProductCard
              element={(product as ExtendedProduct)}
              onSelect={selected => setSelectedStock(selected)}
            />
          </Grid>
        ) :
        <ProductsList products={products} />
      }
      {
        (!products || loading) && (
          viewMode === 'grid' ?
          <SkeletonCards /> :
          <SkeletonRows />
        )
      }
    </Grid>
  </>
}