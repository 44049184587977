import React, { useEffect, useState } from 'react';
import PageLayout from '../layout/PageLayout';
import { useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getError, getLoadedOrders, isLoading, getOrders } from '../features/remote/orders/OrdersSlice';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { IconButton, Box, Skeleton, ListItemText, ListItemButton, Divider, ListItem, List, Pagination, Typography } from '@mui/material';
import { ArrowForwardIos } from "@mui/icons-material";
import { useConfig } from '../features/remote/config/Config';

const ORDERS_PER_PAGE =
  process.env.REACT_APP_MY_ORDERS_PER_PAGE ?
  parseInt(process.env.REACT_APP_MY_ORDERS_PER_PAGE) :
  10;

export default function MyOrderList(){

  const [page, setPage] = useState(0);
  const orders = useAppSelector(getLoadedOrders);
  const loading = useAppSelector(isLoading);
  const error = useAppSelector(getError);
  const { enqueueSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  useEffect(
    () => {
      dispatch(getOrders({page, length: ORDERS_PER_PAGE}));
    },
    [page, dispatch]
  );

  useEffect(
    () => {
      if(error)
        enqueueSnackbar(parseMessageFromError(error), {variant: 'error'});
    },
    [error] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function goToOrder({ id }: { id: number }){
    navigate(id.toString());
  }

  return <PageLayout pageTitle="I miei ordini">
    {
      orders && orders.count <= 0 &&
      <Typography>Nessun ordine effettuato.</Typography>
    }
    <List sx={{ width: 1, maxWidth: 500, mb: 4 }}>
      {
        orders ?
        orders.count > 0 && orders.data.map(
          order => <Box key={order.id}>
            <ListItem
              disabled={loading}
              secondaryAction={
                <IconButton>
                  <ArrowForwardIos />
                </IconButton>
              }
              onClick={() => !loading && goToOrder(order)}
            >
              <ListItemButton>
                <ListItemText
                  primary={
                    `${order.timestamp.toLocaleDateString()} (#${order.id})`
                  }
                  primaryTypographyProps={{
                    color: 'black1.main'
                  }}
                  secondary={order.state}
                  secondaryTypographyProps={{
                    color: 'black3.main'
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Box>
        ) :
        [86, 92, 106, 76].map(
          (widthSecondary, i) => <ListItem
            key={i}
            secondaryAction={
              <Skeleton variant="circular" width={40} height={40} />
            }
          >
            <ListItemText
              primary={<Skeleton variant="text" width={250} />}
              primaryTypographyProps={{
                component: "div"
              }}
              secondary={<Skeleton variant="text" width={widthSecondary} />}
              secondaryTypographyProps={{
                component: "div"
              }}
              sx={{
                pr: 6,
                pl: 2,
                py: 1
              }}
            />
          </ListItem>
        )
      }
    </List>
    {
      orders && orders.count > 0 &&
      <Pagination
        count={Math.ceil(orders.count / ORDERS_PER_PAGE)}
        color="primary"
        page={page + 1}
        onChange={(_, value) => setPage(value - 1)}
        sx={{ position: 'fixed', bottom: theme.spacing(2) }}
      />
    }
  </PageLayout>
}