import { ConvertibleToApiData, del, EditableApiConcretization, get, PagedResponse, remapTypeInPagedResponse } from "../../connector/ConnectorAPI";
import { Decimal } from 'decimal.js';
import { IQuantityUnit, QuantityUnits } from "../../helpers/types";

export async function deleteCommercialEvent(id: number){
    await del(`/eventoCommerciale/${id}`);
}

interface EventsFiltersOption{
    product?: number;
}
export async function getCommercialEvents(
    page: number, length: number, options: EventsFiltersOption
): Promise<PagedResponse<CommercialEvent>> {
    const query: any = {page, length};
    if(options?.product){
        query.products = [options.product];
    }
    const response: PagedResponse<ApiCommercialEvent> = await get(
        '/eventoCommerciali', query
    );
    const parsedResponse: PagedResponse<CommercialEvent> =
        remapTypeInPagedResponse<ApiCommercialEvent, CommercialEvent>(
            response, element => new CommercialEvent(element)
        );
    return parsedResponse;
}

export interface ApiCommercialEventRequest{
    quantita: string;
    costo: string;
    unita: string;
}

export interface ApiCommercialEvent extends ApiCommercialEventRequest{
    id: number;
    timestamp: number;
    tipoEvento: string;
}

export class CommercialEventRequest
    implements ConvertibleToApiData<ApiCommercialEventRequest>
{
    protected _quantity: Decimal;
    protected _totPrice: Decimal;
    protected _unitPrice: Decimal;
    readonly unit: IQuantityUnit;
    constructor(event: ApiCommercialEventRequest){
        this._quantity = new Decimal(event.quantita);
        this._totPrice = new Decimal(event.costo);
        this._unitPrice = this._totPrice.dividedBy(this.quantity);
        this.unit = QuantityUnits.getInstance(event.unita);
    }

    public get quantity(): Decimal{
        return this._quantity;
    }
    public set quantity(quantity: Decimal){
        this._quantity = quantity;
        this._totPrice = this._unitPrice.times(this._quantity);
    }
    public get textualQuantity(): string{
        return this._quantity.toFixed(this.unit.decimals);
    }
    public set textualQuantity(quantity: string){
        this.quantity = new Decimal(quantity);
    }

    public get totPrice(): Decimal{
        return this._totPrice;
    }
    public set totPrice(totPrice: Decimal){
        this._totPrice = totPrice;
        this._unitPrice = this._totPrice.dividedBy(this._quantity);
    }
    public get textualTotPrice(): string{
        return this._totPrice.toFixed(3);
    }
    public set textualTotPrice(price: string){
        this.totPrice = new Decimal(price);
    }

    public get unitPrice(): Decimal{
        return this._unitPrice;
    }
    public set unitPrice(unitPrice: Decimal){
        this._unitPrice = unitPrice;
        this._totPrice = this._unitPrice.times(this._quantity);
    }
    public get textualUnitPrice(): string{
        return this._unitPrice.toFixed(3);
    }
    public set textualUnitPrice(price: string){
        this.unitPrice = new Decimal(price);
    }

    toApiObject(): ApiCommercialEventRequest {
        return {
            quantita: this.textualQuantity,
            costo: this.textualTotPrice,
            unita: this.unit.name
        }
    }
}

export class CommercialEvent extends CommercialEventRequest
    implements EditableApiConcretization
{
    readonly id: number;
    readonly timestamp: Date;
    readonly type: string;
    readonly unit: IQuantityUnit;
    private originalData: ApiCommercialEvent;

    constructor(event: ApiCommercialEvent){
        super(event);
        this.originalData = event;
        this.id = event.id;
        this.timestamp = new Date(event.timestamp);
        this.type = event.tipoEvento;
        this.unit = QuantityUnits.getInstance(event.unita);
    }

    public get stockImpact(): string{
        return '';
    }

    public get stockDiff(): string{
        return `${this.stockImpact}${this.textualQuantity}`;
    }

    public get stockDiffMessage(): string{
        return `${this.stockDiff} ${this.unit.toString()}`;
    }

    toApiObject(): ApiCommercialEvent {
        const apiObject = super.toApiObject();
        return {
            ...apiObject,
            id: this.id,
            timestamp: this.timestamp.getTime(),
            tipoEvento: this.type
        }
    }

    toEditedApiObject(): object {
        const changes = {};
        const edited = this.toApiObject();
        for(const field in this.originalData){
            if(
                this.originalData[field] !== edited[field] &&
                field !== 'prodotto'
            ){
                changes[field] = edited[field];
            }
        }
        return changes;
    }
}