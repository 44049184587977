import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../utils';
import { PagedResponse } from '../connector/ConnectorAPI';
import { requestPasswordChangeEmail as requestPasswordChangeEmailApi, editUser as editUserApi, searchUsersByEmail as searchUsersByEmailApi, User } from './UsersAPI';

interface UsersState{
  status: 'idle' | 'loading',
  error: Error | null,
  loadedUser: User | null,
  loadedUsers: PagedResponse<User>
}
const initialState = {
  status: 'idle',
  error: null,
  loadedUser: null,
  loadedUsers: {
    count: 0,
    data: [],
    page: 0
  }
} as UsersState;

export const requestPasswordChangeEmail = createAsyncThunk(
  'remote/users/request_password_change',
  async (email: string, { rejectWithValue }) => {
    try{
      const response = await requestPasswordChangeEmailApi(email);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const editUser = createAsyncThunk(
  'remote/users/edit',
  async (user: User, { rejectWithValue }) => {
    try{
      const response = await editUserApi(user);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface UsersSearchOptions{
  email: string;
}
export const searchUsersByEmail = createAsyncThunk(
  'remote/users/search',
  async ({ email }: UsersSearchOptions, { rejectWithValue }) => {
    try{
      const response = await searchUsersByEmailApi(email);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const userSlice = createSlice({
  name: 'remote/users',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUsersByEmail.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedUsers = action.payload;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.status = 'idle';
        if(state.loadedUser && state.loadedUser.id)
          state.loadedUser = action.payload;
      })
      .addMatcher(isNamespacePending('remote/users'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/users'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getLoadedUser = (state: RootState) => state.remote.users.loadedUser;
export const getLoadedUsers = (state: RootState) => state.remote.users.loadedUsers;
export const isLoading = (state: RootState) => state.remote.users.status === "loading";
export const getError = (state: RootState) => state.remote.users.error;

export default userSlice.reducer;
