import React, { createContext, Component, useContext } from 'react';
import { connect } from 'react-redux';
import FatalErrorPage from '../../../pages/FatalErrorPage';
import WaitingPage from '../../../pages/WaitingPage';
import { getLoadedConfig, getError, getConfig } from './ConfigSlice';
import { ApiError } from '../connector/ConnectorAPI';
import { AppDispatch, RootState } from '../../../app/store';
import { Config } from './ConfigAPI';

const parseMessageFromErrorGenerator = (config?: Config) => (error: Error) => {
  if(process.env.NODE_ENV === "development")
      console.log(error);
  
  let message = error.message || 'È avvenuto un errore';
  if(config && (error instanceof ApiError)){
    const errorsMap = config.ERRORS_CATALOG;
    if(message in errorsMap)
      message = errorsMap[message];
  }
  return message;
}

type ConfingContextInterface = {
  config: Config | null;
  parseMessageFromError: (error: Error) => any
}

const ConfigContext = createContext<ConfingContextInterface>({
  config: null,
  parseMessageFromError: parseMessageFromErrorGenerator()
});

interface StateProps {
  config: Config | null;
  error: Error | null;
}

const mapStateToProps = (state: RootState): StateProps => ({
  config: getLoadedConfig(state),
  error: getError(state)
});

interface DispatchProps {
  getConfig: () => any;
}

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getConfig: () => dispatch(getConfig())
});

type ConfigProviderProps = StateProps & DispatchProps & {
  children?: React.ReactNode;
};

class ConfigProviderComponent extends Component<ConfigProviderProps, {}>{
  componentDidMount(){
    this.props.getConfig();
  }
  render(){
    if(!this.props.config)
      if(!this.props.error)
        return <WaitingPage />;
      else
        return <FatalErrorPage error={this.props.error} />;

    return <ConfigContext.Provider
      value={{
        config: this.props.config,
        parseMessageFromError: parseMessageFromErrorGenerator(this.props.config)
      }}
    >
      {this.props.children}
    </ConfigContext.Provider>;
  }
}

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider =
  connect(mapStateToProps, mapDispatchToProps)(ConfigProviderComponent);