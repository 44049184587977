import { ConvertibleToApiData, EditableApiConcretization, get, PagedResponse, post } from "../connector/ConnectorAPI";
import { IQuantityUnit, QuantityUnits } from "../helpers/types";

export async function requestPasswordChangeEmail(email: string): Promise<void>{
    await get(`/utente/${email}/password/reset`);
    return;
}

export async function editUser(user: User): Promise<User>{
    const response: ApiUser = await post(`/utente/${user.email}`, user.toEditedApiObject());
    const parsedResponse: User = new User(response);
    return parsedResponse;
}

export async function searchUsersByEmail(email: string): Promise<PagedResponse<User>>{
    const response: Auth0PagedResponse<ApiAuth0User> = await get('/utenti', { user: email });
    const parsedResponse: PagedResponse<User> = {
        count: response.total,
        page: 0,
        data: (response.items || []).map(
            element => {
                let nome = "";
                let cognome = "";
                if(element.given_name)
                    nome = element.given_name;
                else if(element.name){
                    const names = element.name.split(' ');
                    if(names.length > 0)
                        nome = names[0];
                }
                if(element.family_name)
                    cognome = element.family_name;
                else if(element.name){
                    const names = element.name.split(' ');
                    if(names.length > 1)
                        cognome = names[names.length - 1];
                }
                return new User({
                    id: element.user_id,
                    email: element.email,
                    nickname: element.nickname,
                    username: element.username || '',
                    nome,
                    cognome,
                    admin: element.user_metadata?.admin || false,
                    abilitato: element.user_metadata?.abilitato || false,
                    misura: element.user_metadata?.unita || 'Kg',
                    iva: element.user_metadata?.iva || 0
                })
            }
        )
    };
    return parsedResponse;
}

export interface Auth0PagedResponse<T>{
    items: Array<T>,
    total: number
}

export interface ApiUserRequest{
    email: string;
    nickname: string;
    username: string;
    nome: string;
    cognome: string;
    admin: boolean;
    abilitato: boolean;
    misura: string;
    iva: number;
}
export interface ApiUser extends ApiUserRequest{
    id: string;
}
export interface ApiAuth0UserMetadata{
    abilitato?: boolean;
    admin?: boolean;
    unita?: string;
    iva?: number;
}
export interface ApiAuth0User{
    user_id: string;
    email: string;
    nickname: string;
    name: string;
    given_name: string;
    family_name: string;
    user_metadata?: ApiAuth0UserMetadata;
    username?: string;
}

export class UserRequest implements ConvertibleToApiData<ApiUserRequest>{

    readonly email: string;
    readonly nickname: string;
    readonly username: string;
    readonly name: string;
    readonly surname: string;
    admin: boolean;
    enabled: boolean;
    measure: IQuantityUnit;
    iva: number;

    constructor(user: ApiUserRequest){
        this.email = user.email;
        this.nickname = user.nickname;
        this.username = user.username;
        this.admin = user.admin;
        this.enabled = user.abilitato || false;
        this.measure = QuantityUnits.getInstance(user.misura);
        this.iva = user.iva;
        this.name = user.nome;
        this.surname = user.cognome;
    }

    public get fullName(){
        return (this.name && this.surname) ?
            `${this.name} ${this.surname}` :
            null;
    }

    toApiObject(): ApiUserRequest {
        return {
            email: this.email,
            nickname: this.nickname,
            username: this.username,
            admin: this.admin,
            misura: this.measure.name,
            nome: this.name,
            cognome: this.surname,
            iva: this.iva,
            abilitato: this.enabled
        };
    }
}

export class User extends UserRequest implements EditableApiConcretization{

    id: string;

    constructor(user: ApiUser){
        super(user);
        this.id = user.id;
    }
    toEditedApiObject(): object {
        return {
            misura: this.measure.name,
            iva: this.iva,
            abilitato: this.enabled,
            admin: this.admin
        }
    }

    toApiObject(): ApiUser {
        return {
            ...super.toApiObject(),
            id: this.id
        };
    }
}