import React from "react";
import { Stack, Select, Typography, MenuItem } from "@mui/material";
import { NumberTextField } from "./NumberTextField";

function getQuantityFromDescription(description: string){
  const subDescription = description.substring(9);
  const separatorPosition = subDescription.indexOf(' ');
  return (separatorPosition >= 0) ? subDescription.substring(0, separatorPosition) : '';
}

function getUnitFromDescription(description: string){
  const subDescription = description.substring(9);
  const separatorPosition = subDescription.indexOf(' ');
  return (separatorPosition >= 0) ? subDescription.substring(separatorPosition + 1) : '';
}

function generateDescription(
  defaultValue: string | undefined, prefix: string | undefined,
  quantity: string | undefined, unit: string | undefined
){
  if(!quantity)
    return defaultValue || '';
  return `${prefix || ''}${quantity || ''} ${unit || ''}`;
}

type UnitDescriptionFieldProps = {
  disabled?: boolean;
  value?: string;
  onChange: (newValue: string) => any;
  descriptionPrefix?: string;
  defaultDescription?: string;
}

export function UnitDescriptionField(
  { disabled, value, descriptionPrefix, defaultDescription, onChange }: UnitDescriptionFieldProps
){
  return <Stack direction="row" spacing={0}>
    <NumberTextField
      value={ getQuantityFromDescription(value || '') }
      inputProps={{
        min: 0,
        step: 0.1,
        sx:{
          textAlign: "right"
        }
      }}
      onChange={
        event => onChange(
          generateDescription(
			      defaultDescription,
            descriptionPrefix,
            event.target.value,
            getUnitFromDescription(value || '')
          )
        )
      }
      InputProps={{
        startAdornment:
          descriptionPrefix ? 
          <Typography sx={{width: `${descriptionPrefix.length}rem`}}>
            { descriptionPrefix }
          </Typography> :
          undefined,
        sx:{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }
      }}
      disabled={disabled}
      sx={{
        width: 0.5
      }}
    />
    <Select
      disabled={disabled}
      value={ getUnitFromDescription(value || '') }
      onChange={
        event => onChange(
          generateDescription(
			      defaultDescription,
            descriptionPrefix,
            getQuantityFromDescription(value || ''),
            event.target.value
          )
        )
      }
      sx={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        width: 0.5
      }}
    >
      {
        ["Kg", "pezzi"].map(
          unit => <MenuItem
            value={unit}
            key={unit}
          >
            {unit}
          </MenuItem>
        )
      }
    </Select>
  </Stack>
}
