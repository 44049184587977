import { put } from "../../connector/ConnectorAPI";
import { ApiCommercialEvent, ApiCommercialEventRequest, CommercialEvent, CommercialEventRequest } from "./CommercialEventsAPI";

export async function addReversed(event: ReversedEventRequest){
    const response: ApiReversedEvent = await put('/eventoStorno', event.toApiObject());
    return new ReversedEvent(response);
}

export interface ApiReversedEventRequest extends ApiCommercialEventRequest{
    prodotto: number;
}

export interface ApiReversedEvent extends ApiCommercialEvent{
    prodotto: number;
}

export class ReversedEventRequest extends CommercialEventRequest{
    product: number;
    
    constructor(event: ApiReversedEventRequest){
        super({...event, costo: "0"});
        this.product = event.prodotto;
    }
    toApiObject(): ApiReversedEventRequest {
        const apiObject = super.toApiObject();
        return {
            ...apiObject,
            prodotto: this.product
        }
    }
}

export class ReversedEvent extends CommercialEvent{
    product: number;
    
    constructor(event: ApiReversedEvent){
        super(event);
        this.product = event.prodotto;
    }

    public get stockImpact(): string{
        return '+';
    }

    toApiObject(): ApiReversedEvent {
        const apiObject = super.toApiObject();
        return {
            ...apiObject,
            prodotto: this.product
        }
    }
}