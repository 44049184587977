import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../utils';
import { editPrice } from '../prices/PricesActions';
import { PagedResponse } from '../connector/ConnectorAPI';
import { ProductStatistics, getCategories as getCategoriesApi, getExtendedProducts as getExtendedProductsApi, getProductStatistics as getProductStatisticsApi, getProducts as getProductsApi, enableProduct as enableProductApi, disableProduct as disableProductApi, addProduct as addProductApi, editProduct as editProductApi, getProduct as getProductApi, Product, Category, ProductRequest, ExtendedProduct } from './ProductsAPI';
import { RootState } from '../../../app/store';

interface ProductsState{
  status: 'idle' | 'loading',
  error: Error | null,
  loadedCategories: Category[] | null,
  loadedProductStatistics: ProductStatistics[],
  loadedProduct: Product | null,
  loadedProducts: PagedResponse<Product | ExtendedProduct>
}
const initialState = {
  status: 'idle',
  error: null,
  loadedCategories: null,
  loadedProductStatistics: [],
  loadedProduct: null,
  loadedProducts: {
    count: 0,
    data: [],
    page: 0
  }
} as ProductsState;

export const addProduct = createAsyncThunk(
  'remote/products/add',
  async (product: ProductRequest, { rejectWithValue }) => {
    try{
      const response = await addProductApi(product);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface ProductIdentifyOptions{
  id: number;
}
export const disableProduct = createAsyncThunk(
  'remote/products/disable',
  async ({ id }: ProductIdentifyOptions, { rejectWithValue }) => {
    try{
      const response = await disableProductApi(id);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const enableProduct = createAsyncThunk(
  'remote/products/enable',
  async ({ id }: ProductIdentifyOptions, { rejectWithValue }) => {
    try{
      const response = await enableProductApi(id);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const editProduct = createAsyncThunk(
  'remote/products/edit',
  async (product: Product, { rejectWithValue }) => {
    try{
      const response = await editProductApi(product);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const getCategories = createAsyncThunk(
  'remote/products/get_categories',
  async (_, { rejectWithValue }) => {
    try{
      const response = await getCategoriesApi();
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface CategoriesFilterOption{
  name: string;
}

export const searchCategories = createAsyncThunk(
  'remote/products/search_categories',
  async ({ name }: CategoriesFilterOption, { rejectWithValue }) => {
    try{
      const response = await getCategoriesApi({name});
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface FetchPagedResponseOptions{
  page: number;
  length: number;
  sort?: "ASC" | "DESC";
}

interface SearchProductOptions extends FetchPagedResponseOptions{
  name?: string;
}
export const searchProductsByName = createAsyncThunk(
  'remote/products/search',
  async ({ name, sort, page, length }: SearchProductOptions, { rejectWithValue }) => {
    try{
      const response = await getProductsApi(page, length, {sort, name});
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const getProduct = createAsyncThunk(
  'remote/products/get',
  async ({ id }: ProductIdentifyOptions, { rejectWithValue }) => {
    try{
      const response = await getProductApi(id);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const getProductStatistics = createAsyncThunk(
  'remote/products/get_stats',
  async ({ id }: ProductIdentifyOptions, { rejectWithValue }) => {
    try{
      const response = await getProductStatisticsApi(id);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const searchExtendedProductsByName = createAsyncThunk(
  'remote/products/search_extended',
  async ({page, length, sort, name}: SearchProductOptions, { rejectWithValue }) => {
    try{
      const response = await getExtendedProductsApi(page, length, {sort, name});
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface ExtendedSearchProductOptions extends SearchProductOptions{
  categories?: number[];
}
export const getExtendedProducts = createAsyncThunk(
  'remote/products/get_all_extended',
  async (
    {page, length, sort, name, categories}: ExtendedSearchProductOptions,
    { rejectWithValue }
  ) => {
    try{
      const response = await getExtendedProductsApi(page, length, {sort, name, categories});
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const getProducts = createAsyncThunk(
  'remote/products/get_all',
  async ({page, length, sort}: FetchPagedResponseOptions, { rejectWithValue }) => {
    try{
      const response = await getProductsApi(page, length, { sort });
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const productsSlice = createSlice({
  name: 'remote/products',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPrice.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedPrice = action.payload;
        if(state.loadedProduct)
          state.loadedProduct.prices = state.loadedProduct.prices.map(
            price => price.id === updatedPrice.id ? updatedPrice : price
          );
        state.loadedProducts.data = state.loadedProducts.data.map(
          product => {
            product.prices = product.prices.map(
              price => price.id === updatedPrice.id ? updatedPrice : price
            );
            return product;
          }
        );
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedProduct = action.payload;
        state.loadedProducts.count++;
        state.loadedProducts.data.unshift(action.payload);
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedProduct = action.payload;
      })
      .addCase(getProductStatistics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedProductStatistics = action.payload;
      })
      .addMatcher(
        isAnyOf(
          searchCategories.fulfilled,
          getCategories.fulfilled
        ),
        (state, action) => {
          state.status = 'idle';
          state.loadedCategories = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(
          searchExtendedProductsByName.fulfilled,
          getExtendedProducts.fulfilled
        ),
        (state, action) => {
          state.status = 'idle';
          state.loadedProducts = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(getProducts.fulfilled, searchProductsByName.fulfilled),
        (state, action) => {
          state.status = 'idle';
          state.loadedProducts = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(
          editProduct.fulfilled,
          enableProduct.fulfilled,
          disableProduct.fulfilled,
        ),
        (state, action) => {
          state.status = 'idle';
          const freshProduct = action.payload;
          state.loadedProduct = freshProduct;
          state.loadedProducts.data = state.loadedProducts.data.map(
            product => product.id === freshProduct.id ? freshProduct : product
          );
        }
      )
      .addMatcher(isNamespacePending('remote/products'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/products'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getLoadedCategories =
  (state: RootState) => state.remote.products.loadedCategories;
export const getLoadedProduct = (state: RootState) => state.remote.products.loadedProduct;
export const getLoadedProductStatistics =
  (state: RootState) => state.remote.products.loadedProductStatistics;
export const getLoadedProducts = (state: RootState) => state.remote.products.loadedProducts;
export const isLoading = (state: RootState) => state.remote.products.status === "loading";
export const getError = (state: RootState) => state.remote.products.error;

export default productsSlice.reducer;
