import React from "react";
import { TextField, TextFieldProps } from "@mui/material";



export function NumberTextField(props: TextFieldProps){
  return <TextField
    type="number"
    inputMode="numeric"
    onWheel={(event) => (event.target as HTMLElement).blur()}
    {...props}
  />
}