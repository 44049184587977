import { Button, Grid, Menu, MenuItem, Select, Skeleton, Stack, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { changeOrderStatus, getError, getLoadedOrderById, getOrder, isLoading } from "../features/remote/orders/OrdersSlice";
import { deleteCommercialEvent } from "../features/remote/events/commercialEvents/CommercialEventsSlice";
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useReactToPrint } from "react-to-print";
import { useConfig } from "../features/remote/config/Config";
import { useAuth0 } from "@auth0/auth0-react";
import { DetailedOrder, OrderState } from "../features/remote/orders/OrdersAPI";
import { useSnackbar } from "notistack";
import { unwrapResult } from "@reduxjs/toolkit";
import AddSaleEventDialog from "./AddSaleEventDialog";
import EditSaleEventDialog from "./EditSaleEventDialog";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Product } from "../features/remote/products/ProductsAPI";

import '@fontsource/roboto-mono/500.css';
import './OrderPrint.css';
import { SaleEvent } from "../features/remote/events/commercialEvents/SaleEventsAPI";

const itTranslation = require('../features/datagrid/locale/it');

const baseColumns: GridColumns<SaleEvent> = [
  {
    field: 'textualQuantity', type: 'number', headerName: 'Q.tà', flex: 0.25,
    minWidth: 150, align: 'center', headerAlign: 'center',
    valueGetter: (params) => params.row.textualQuantityWithUnit
  },
  {
    field: 'product', headerName: 'Nome', flex: 1, minWidth: 200,
    valueFormatter: (params) => {
      const product = params.value as Product;
      let description = product.name;
      if(product.origin){
        description += ` (${product.origin.toUpperCase()})`
      }
      return description;
    }
  },
  {
    field: 'category', headerName: 'Cat.', flex: 0.1, minWidth: 100,
    align: 'center', headerAlign: 'center',
    valueGetter: (params) => params.row.product.commercialCategory
  },
];

type ComponentProps = {
  orderCode: number | number[];
  adminControls?: boolean;
}

export default function OrderDetails({ orderCode, adminControls }: ComponentProps){
  adminControls = adminControls || false;
  let gridColumns = baseColumns;
  if(adminControls)
    gridColumns = gridColumns.concat([
      {
        field: 'textualUnitPrice', type: 'number', headerName: 'Pr. unit.', flex: 0.5,
        minWidth: 100, valueFormatter: (params) => `${params.value} €`
      },
      {
        field: 'textualTotPrice', type: 'number', headerName: 'Pr. tot.', flex: 0.5,
        minWidth: 100, valueFormatter: (params) => `${params.value} €`
      }
    ]);
  const mainOrderCode = orderCode instanceof Array ? orderCode[0] : orderCode;
  const order = useAppSelector(getLoadedOrderById(mainOrderCode)) as DetailedOrder;
  const otherOrders = useAppSelector(
    orderCode instanceof Array ?
    (state) => orderCode.slice(1).reduce<DetailedOrder[]>(
      (accumulator, code) => {
        const order = getLoadedOrderById(code)(state) as DetailedOrder;
        if(order)
          accumulator.push(order);
        return accumulator;
      },
      []
    ) :
    () => []
  ) as DetailedOrder[];
  const error = useAppSelector(getError);
  const orderIsLoading = useAppSelector(isLoading);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedSale, setSelectedSale] = useState<number>();
  const [selectedSales, setSelectedSales] = useState<number[]>([]);
  const [openAddSaleDialog, setOpenAddSaleDialog] = useState(false);
  const { user } = useAuth0();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ownOrder = order && (order.user.email === user?.email);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { parseMessageFromError } = useConfig();
  const [openPrintMenu, setOpenPrintMenu] = useState(false);
  const printButtonRef = useRef<HTMLButtonElement>(null);
  const printableRef = useRef<HTMLDivElement>(null);
  const handleCompletePrint = useReactToPrint({
    content: () => printableRef.current,
  });
  const handleWhitoutPricesPrint = useReactToPrint({
    content: () => printableRef.current,
    bodyClass: "no-prices"
  });
  const sales = useMemo(
    () => {
      let sales: SaleEvent[] = [];
      if(order)
        sales = sales.concat(order.detail);
      if(otherOrders){
        for(const order of otherOrders){
          sales = sales.concat(order.detail);
        }
      }
      return sales;
    },
    [order, otherOrders]
  )

  function fetchOrders(){
    const toFetch = orderCode instanceof Array ? orderCode : [orderCode];
    for(const code of toFetch)
      dispatch(getOrder({ id: code }));
  }

  useEffect(
    () => {
      if(!openAddSaleDialog || !selectedSale){
        fetchOrders();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSale, openAddSaleDialog, orderCode, dispatch]
  );

  useEffect(
    () => {
      const setError = async (error: Error | null) => {
        if(error){
          const errorMessage = parseMessageFromError(error);
          if(!order)
            setErrorMessage(errorMessage);
          else
            enqueueSnackbar(errorMessage, {variant: 'error'});
        }
      };
      setError(error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error]
  );

  function handleOrderStateChange(state: OrderState){
    if(order.state === state) return;
    const waitSnackBar = enqueueSnackbar(
      "Aggiornamento ordine...",
      {variant: 'info', persist: true}
    );
    dispatch(changeOrderStatus({ id: order.id, state }))
      .then(unwrapResult)
      .then(
        () => enqueueSnackbar("Ordine aggiornato", {variant: 'success'})
      )
      .finally(
        () => closeSnackbar(waitSnackBar)
      );
  }

  async function removeEntry(){
    if(selectedSales && selectedSales.length > 0){
      if(selectedSales.length < order.detail.length){
        const waitSnackBar = enqueueSnackbar(
          "Aggiornamento ordine...",
          {variant: 'info', persist: true}
        );
        for(const sale of selectedSales){
          await dispatch(deleteCommercialEvent({ id: sale }))
            .then(unwrapResult)
            .catch(error => enqueueSnackbar(
              parseMessageFromError(error), {variant: 'error'}
            ));
        }
        closeSnackbar(waitSnackBar);
        enqueueSnackbar("Ordine aggiornato", {variant: 'success'});
        fetchOrders();
      }else{
        enqueueSnackbar(
          "Non puoi eliminare tutti i prodotti da un ordine",
          {variant: 'error'}
        );
      }
    }
  }

  function printAction(){
    if(adminControls){
      setOpenPrintMenu(true);
    }else{
      handleWhitoutPricesPrint();
    }
  }

  function getLowestState(){
    if(order && otherOrders){
      const states = [order.state];
      for(const order of otherOrders)
        states.push(order.state);
      const orderedStates = [
        OrderState.CANCELED, OrderState.SENT, OrderState.CONFIRMED, OrderState.DELIVERED
      ]
      return states.reduce(
        (minimum, current) =>
          orderedStates.indexOf(minimum) < orderedStates.indexOf(current) ?
          minimum :
          current
        ,
        OrderState.SENT
      );
    }
    return undefined;
  }

  return <Box
    ref={printableRef} width={1} height={1}
    sx={{'@media print': {
      pageBreakAfter: 'always',
    }}}
  >
    <EditSaleEventDialog
      eventId={selectedSale}
      open={!!selectedSale}
      handleClose={() => setSelectedSale(undefined)}
    />
    <AddSaleEventDialog
      orderId={mainOrderCode}
      open={openAddSaleDialog}
      handleClose={() => setOpenAddSaleDialog(false)}
    />
    {
      errorMessage !== null ?
      <Box>
        <Typography variant="h3">Errore</Typography>
        <Typography>{errorMessage}</Typography>
      </Box> :
      <Grid container justifyContent="center">
        <Grid
          item lg={11} container direction="column" sx={{pt: 2}} spacing={4}
          className="order-details-container"
        >
          <Grid item container spacing={2}>
            <Grid item xs={4} md={2} order={{ xs: 2, md: 1 }}>
              <Typography variant="h5" color="black1.main" fontWeight="bold">
                Data
              </Typography>
            </Grid>
            <Grid item xs={8} md={6} order={{ xs: 3, md: 2 }}>
              <Typography variant="h5" color="black3.main" fontWeight="bold" overflow="auto">
                {
                  order ?
                  new Date(order.timestamp).toLocaleString() :
                  <Skeleton variant="text" width={160} />
                }
              </Typography>
            </Grid>
            <Grid
              item xs={12} md={4}
              container justifyContent="flex-end"
              order={{ xs: 1, md: 3 }}
              sx={{
                [theme.breakpoints.only('xs')]: {
                  mb: 2,
                  justifyContent: "center"
                }
              }}
            >
              <Button
                ref={printButtonRef}
                variant="contained"
                onClick={printAction}
                sx={{
                  displayPrint: "none"
                }}
              >
                Stampa
              </Button>
              {
                adminControls &&
                <Menu
                  anchorEl={printButtonRef.current}
                  open={openPrintMenu}
                  onClose={() => setOpenPrintMenu(false)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    displayPrint: 'none'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCompletePrint();
                      setOpenPrintMenu(false);
                    }}
                  >
                    Completo
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleWhitoutPricesPrint();
                      setOpenPrintMenu(false);
                    }}
                  >
                    Senza prezzi
                  </MenuItem>
                </Menu>
              }
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4} md={2}>
              <Typography variant="h5" color="black1.main" fontWeight="bold">
                Codice
              </Typography>
            </Grid>
            <Grid item xs={8} md={10}>
              <Typography variant="h5" color="black3.main" fontWeight="bold" overflow="auto">
                {
                  order ?
                  order.id :
                  <Skeleton variant="text" width={150} />
                }
              </Typography>
            </Grid>
          </Grid>
          {
            otherOrders?.map(
              order => <Grid item container spacing={2} key={order.id}>
                <Grid item xs={4} md={2}></Grid>
                <Grid item xs={8} md={10}>
                  <Typography
                    variant="h5" color="black3.main" fontWeight="bold" overflow="auto"
                  >
                    { order.id }
                  </Typography>
                </Grid>
              </Grid>
            )
          }
          {
            !ownOrder &&
            <Grid item container spacing={2}>
              <Grid item xs={4} md={2}>
                <Typography variant="h5" color="black1.main" fontWeight="bold">
                  Utente
                </Typography>
              </Grid>
              <Grid item xs={8} md={10}>
                <Typography variant="h5" color="black3.main" fontWeight="bold" overflow="auto">
                  {
                    order ?
                    ( order.user?.nickname || order.user?.email ) :
                    <Skeleton variant="text" width={100} />
                  }
                </Typography>
              </Grid>
            </Grid>
          }
          <Grid item container spacing={2}>
            <Grid item xs={4} md={2}>
              <Typography variant="h5" color="black1.main" fontWeight="bold">
                Indirizzo
              </Typography>
            </Grid>
            <Grid item xs={8} md={10}>
              <Typography variant="h5" color="black3.main" fontWeight="bold" overflow="auto">
                {
                  order ?
                  order.address :
                  <Skeleton variant="text" width={120} />
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4} md={2}>
              <Typography variant="h5" color="black1.main" fontWeight="bold">
                Note
              </Typography>
            </Grid>
            <Grid item xs={8} md={10}>
              <Typography variant="h5" color="black3.main" fontWeight="bold" overflow="auto">
                {
                  order ?
                      order.note :
                      <Skeleton variant="text" width={120} />
                }
              </Typography>
            </Grid>
          </Grid>
          {
            otherOrders?.map(
              order => order.note ?
              <Grid item container spacing={2} key={order.id}>
                <Grid item xs={4} md={2}></Grid>
                <Grid item xs={8} md={10}>
                  <Typography
                    variant="h5" color="black3.main" fontWeight="bold" overflow="auto"
                  >
                    { order.note }
                  </Typography>
                </Grid>
              </Grid> :
              <></>
            )
          }
          <Grid item container spacing={2}>
            <Grid item xs={4} md={2}>
              <Typography variant="h5" color="black1.main" fontWeight="bold">
                Stato
              </Typography>
            </Grid>
            <Grid item xs={8} md={4}>
              {
                order ? (
                  adminControls ?
                  <Select
                    fullWidth
                    value={getLowestState()}
                    onChange={
                      event => handleOrderStateChange(event.target.value as OrderState)
                    }
                    disabled={orderIsLoading}
                  >
                    {
                      Object.keys(OrderState).map(
                        state => <MenuItem key={state} value={OrderState[state]}>
                          {OrderState[state]}
                        </MenuItem>
                      )
                    }
                  </Select> :
                  <Typography
                    variant="h5" color="black3.main"
                    fontWeight="bold" overflow="auto"
                  >
                    {getLowestState()}
                  </Typography>
                ) :
                <Skeleton
                  variant="rectangular"
                  width={180}
                  height={`calc(1.4375em + ${theme.spacing(4)})`}
                />
              }
            </Grid>
          </Grid>
          {
            adminControls &&
            <Grid item sx={{ displayPrint: "none" }}>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  color="error" variant="contained" onClick={removeEntry}
                  disabled={selectedSales.length <= 0}
                >
                  Elimina
                </Button>
                <Button
                  variant="contained" onClick={() => setOpenAddSaleDialog(true)}
                >
                  Aggiungi
                </Button>
              </Stack>
            </Grid>
          }
          <Grid item className="sale-rows">
            <DataGrid
              hideFooter
              autoHeight
              columns={gridColumns}
              rows={sales}
              disableSelectionOnClick
              onSelectionModelChange={
                selectedModel =>
                  setSelectedSales(
                    selectedModel.map(
                      id => id.valueOf() as number
                    )
                  )
              }
              checkboxSelection
              sx={{
                width: '100%',
                border: 'none',
                color: 'black1.main'
              }}
              localeText={itTranslation}
              onRowDoubleClick={
                event => adminControls && setSelectedSale(event.id.valueOf() as number)
              }
            />
          </Grid>
          { (adminControls || (getLowestState() === OrderState.DELIVERED)) &&
            <Grid item container spacing={2} className="price-row">
              <Grid item xs={4} md={2}>
                <Typography variant="h5" color="black1.main" fontWeight="bold">
                  Totale
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5" color="black3.main" fontWeight="bold" overflow="auto"
                  sx={{
                    borderBottom: 3,
                    borderBottomColor: 'secondary.main'
                  }}
                >
                  {
                    order && otherOrders ?
                    `${order.getTotalOfGroup(otherOrders)} €` :
                    <Skeleton variant="text" width={100} />
                  }
                </Typography>
              </Grid>
            </Grid>
          }
          { (adminControls || (getLowestState() === OrderState.DELIVERED)) &&
            <Grid item container spacing={2} className="price-row">
              <Grid item xs={4} md={2}>
                <Typography variant="h6" color="black1.main" fontWeight="bold">
                  di cui IVA
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6" color="black3.main" fontWeight="bold" overflow="auto"
                >
                  {
                    order && otherOrders ?
                    `${order.getTotalIvaOfGroup(otherOrders)} €` :
                    <Skeleton variant="text" width={110} />
                  }
                </Typography>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    }
  </Box>
}