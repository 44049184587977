import { useAuth0 } from "@auth0/auth0-react";
import {Stack, Grid, IconButton, ListItem, ListItemText, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExtendedProduct, ProductStatistics } from "../features/remote/products/ProductsAPI";
import {NumberTextField} from "./NumberTextField";
import {Add, Remove} from "@mui/icons-material";
import {Decimal} from "decimal.js";
import {CartEntry, CartEntryRequest} from "../features/remote/cart/CartAPI";
import {addToCart, editCartEntry, isLoading} from "../features/remote/cart/CartSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useSnackbar} from "notistack";
import {useConfig} from "../features/remote/config/Config";

const ALLOW_SHOPPING_WITHOUT_STOCK =
    process.env.REACT_APP_ALLOW_SHOPPING_WITHOUT_STOCK || true;

type ComponentProps = {
  element: ExtendedProduct;
  cartEntry: CartEntry | undefined;
}

export default function ProductRow({ element, cartEntry }: ComponentProps){

  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const { user } = useAuth0();
  const [allowedStocks, setAllowedStocks] = useState<ProductStatistics[]>([]);
  const [selectedStock, setSelectedStock] = useState<ProductStatistics>();
  const [quantity, setQuantity] = useState<string>('');
  const loading = useAppSelector(isLoading);

  useEffect(
    () => {
      const stocksStats = [...element.statistics.values()];
      const allowedStocks = [];
      for(const stockStats of stocksStats){
        const unit = stockStats.unit.name;
        if(
          user && user['https://rivendita.ipaladini/user'].unita === unit &&
          (ALLOW_SHOPPING_WITHOUT_STOCK || stockStats.available.greaterThan(0))
        )
          allowedStocks.push(stockStats);
      }
      setAllowedStocks(allowedStocks);
      setSelectedStock(allowedStocks[0]);
    },
    [element, user]
  );

  useEffect(
    () => {
      if(quantity && cartEntry?.textualQuantity !== quantity)
        confirm();
    },
    [quantity] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if(cartEntry?.textualQuantity && cartEntry.textualQuantity !== quantity)
        setQuantity(cartEntry.textualQuantity);
    },
    [cartEntry] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function add(){
    if(selectedStock){
      if(selectedStock?.unit.name === 'Kg'){
        setQuantity(
          quantity =>
            new Decimal(quantity || '0')
            .plus(0.5)
            .toFixed(selectedStock?.unit.decimals)
        );
      }else{
        setQuantity(
          quantity =>
            new Decimal(quantity || '0')
            .plus(1)
            .toFixed(selectedStock?.unit.decimals)
        );
      }
    }
  }
  function minus(){
    if(selectedStock){
      let value;
      if(selectedStock?.unit.name === 'Kg'){
        value = new Decimal(quantity || '0').minus(0.5);
      }else{
        value = new Decimal(quantity || '0').minus(1);
      }
      if (value.comparedTo(0) < 0) {
        value = new Decimal(0);
      }
      const textualValue = value.toFixed(selectedStock?.unit.decimals);
      setQuantity(textualValue);
    }
  }

  function confirm(){
    let cartUpdatePromise: Promise<CartEntry> | null = null;
    const quantityDecimal = new Decimal(quantity);
    if(cartEntry){
      cartEntry.textualQuantity = quantity;
      cartUpdatePromise = dispatch(editCartEntry(cartEntry)).then(unwrapResult);
    }else if(selectedStock && quantityDecimal.greaterThan(0)){
      const entry: CartEntryRequest = new CartEntryRequest({
        prodotto: selectedStock?.product,
        quantita: quantity,
        misura: selectedStock?.unit.name
      });
      cartUpdatePromise = dispatch(addToCart(entry)).then(unwrapResult);
    }

    cartUpdatePromise?.then((entry) => {
      cartEntry = entry;
      const snackbarId = enqueueSnackbar("Carrello aggiornato", {
        preventDuplicate: true, variant: 'success',
        onClick: () => closeSnackbar(snackbarId)
      })
    })
      .catch(
        (error: Error) => {
          const snackbarId = enqueueSnackbar(
            parseMessageFromError(error),
            { variant: 'error', onClick: () => closeSnackbar(snackbarId) }
          )
        }
      );
  }

  return <ListItem divider disabled={!(allowedStocks?.length > 0)}>
    <Grid container alignItems="center" justifyContent="left">
      <Grid item>
        <IconButton onClick={minus} disabled={loading}>
          <Remove />
        </IconButton>
      </Grid>
      <Grid item>
        <NumberTextField
          inputProps={{
            min: 0,
            step: selectedStock ? selectedStock?.unit.step.toFixed(2) : 0
          }}
          value={quantity || '0'}
          sx={{
            "& fieldset": {
              border: "none"
            },
            "& input": {
              width: "2em",
              p: 0,
              textAlign: "center"
            }
          }}
          disabled
          onChange={event => setQuantity(event.target.value)}
        />
      </Grid>
      <Grid item>
        <IconButton onClick={add} disabled={loading}>
          <Add />
        </IconButton>
      </Grid>
      <Grid item sx={{ ml: 2 }}>
        <ListItemText
          primary={
            <Stack direction="row" spacing={2}>
              <Typography>
                { element?.name }
              </Typography>
              {
                element?.origin &&
                <img
                  alt={element.origin}
                  width="24px"
                  src={`https://countryflagsapi.com/svg/${element.origin}`}
                />
              }
            </Stack>
          }
          secondary={
            allowedStocks.length === 0 ?
            "Non disponibile" :
            selectedStock?.unit.toHumanReadableString()
          }
        />
      </Grid>
    </Grid>
  </ListItem>;
}