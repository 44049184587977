import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { User } from "../features/remote/users/UsersAPI";
import { getLoadedUsers, isLoading, searchUsersByEmail } from "../features/remote/users/UsersSlice";

type UserSearchFieldProps = Partial<AutocompleteProps<User, false, false, false>> & {
  required?: boolean;
  selection?: User;
  onSelectionChange: (selection: User | undefined) => any;
  TextFieldProps?: TextFieldProps;
}

export default function UserSearchField({
  selection, onSelectionChange, required, TextFieldProps, ...otherProps
}: UserSearchFieldProps){
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const users = useAppSelector(getLoadedUsers);
  const loading = useAppSelector(isLoading) && open;
  const dispatch = useAppDispatch();

  function searchUser(){
    inputValue && inputValue.length >= 3 &&
    dispatch(searchUsersByEmail({ email: inputValue }));
  };

  return <Autocomplete
    sx={{ width: 1 }}
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    isOptionEqualToValue={
      (option, value) => 
        (option.email === value.email) ||
        (option.fullName === value.fullName)
    }
    getOptionLabel={(option) => option.fullName || option.email || ''}
    options={users ? users.data : []}
    loading={loading}
    value={selection}
    onChange={(_, newValue) => onSelectionChange(newValue || undefined)}
    onInputChange={(_, newInputValue) => {
      setInputValue(newInputValue);
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        required={required}
        label="Utente"
        {...TextFieldProps}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          onKeyDown: event => {
            if(event.key === 'Enter'){
              event.stopPropagation();
              searchUser();
            }
          }
        }}
      />
    )}
    {...otherProps}
  />
}