import { useAuth0 } from "@auth0/auth0-react";
import { Card, CardActionArea, CardContent, CardMedia, Chip, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExtendedProduct, ProductStatistics } from "../features/remote/products/ProductsAPI";
import productPlaceholder from "../resources/product_placeholder.png";

const ALLOW_SHOPPING_WITHOUT_STOCK =
  process.env.REACT_APP_ALLOW_SHOPPING_WITHOUT_STOCK || true;

type ComponentProps = {
  element: ExtendedProduct;
  onSelect: (selected: ProductStatistics) => any
}

export default function ProductCard({ element, onSelect }: ComponentProps){

  const { user } = useAuth0();
  const [allowedStocks, setAllowedStocks] = useState<ProductStatistics[]>([]);
  const [selectedStock, setSelectedStock] = useState<ProductStatistics>();

  useEffect(
    () => {
      const stocksStats = [...element.statistics.values()];
      const allowedStocks = [];
      for(const stockStats of stocksStats){
        const unit = stockStats.unit.name;
        if(
          user && user['https://rivendita.ipaladini/user'].unita === unit &&
          (ALLOW_SHOPPING_WITHOUT_STOCK || stockStats.available.greaterThan(0))
        )
          allowedStocks.push(stockStats);
      }
      setAllowedStocks(allowedStocks);
      setSelectedStock(allowedStocks[0]);
    },
    [element, user]
  );

  function selectProduct(stock?: ProductStatistics){
    const nextStock: ProductStatistics | undefined = stock || selectedStock;
    if(onSelect && nextStock)
      onSelect(nextStock);
  }

  return <Card
    elevation={6}
    sx={{
      opacity: allowedStocks?.length > 0 ? 1 : 0.6,
      borderRadius: '1rem', borderColor: 'primary.main', border: '2px solid'
    }}
  >
    <CardActionArea
      disabled={!(allowedStocks?.length > 0)}
      onClick={() => selectProduct()}
    >
      <CardMedia
        component="img"
        height="140"
        image={element?.image || productPlaceholder}
        alt="product"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          { element?.name }
        </Typography>
        {
          element?.origin &&
          <img
            alt={element.origin}
            width="24px"
            src={`https://countryflagsapi.com/svg/${element.origin}`}
            style={{ marginBottom: "0.35em" }}
          />
        }
        <Grid container>
          {
            allowedStocks?.map(
              stockStats => {
                const label = stockStats.unit.toHumanReadableString();
                return <Grid
                  item
                  key={stockStats.unit.toString()}
                >
                  <Chip
                    label={label}
                    variant="outlined"
                    onClick={event => {
                      event.preventDefault();
                      setSelectedStock(stockStats);
                      selectProduct(stockStats);
                    }}
                    sx={{
                      m: 0.5,
                      borderColor:
                        selectedStock === stockStats ?
                          'secondary.main' :
                          'primary.main'
                    }}
                  />
                </Grid>
              }
            )
          }
          {
            allowedStocks.length === 0 &&
            <Typography color="primary.main">
              Non disponibile
            </Typography>
          }
        </Grid>
      </CardContent>
    </CardActionArea>
  </Card>;
}