import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { PagedResponse } from "../features/remote/connector/ConnectorAPI";
import { Product } from "../features/remote/products/ProductsAPI";
import { getLoadedProducts, isLoading, searchProductsByName } from "../features/remote/products/ProductsSlice";

type ProductSearchFieldProps = Partial<AutocompleteProps<Product, false, false, false>> & {
  selection?: Product;
  onSelectionChange: (selection: Product | null) => any;
  required?: boolean;
  TextFieldProps?: TextFieldProps;
}

export default function ProductSearchField({
  selection, onSelectionChange, required, TextFieldProps, ...otherProps
}: ProductSearchFieldProps){
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const products = useAppSelector(getLoadedProducts) as PagedResponse<Product>;
  const loading = useAppSelector(isLoading) && open;
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if(inputValue)
        dispatch(
          searchProductsByName({ name: inputValue, page: 0, length: 1 })
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue]
  );

  return <Autocomplete
    sx={{ width: 1 }}
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    isOptionEqualToValue={(option, value) => option.name === value.name}
    getOptionLabel={(option) => option.name || ''}
    options={products ? products.data : []}
    loading={loading}
    value={selection}
    onChange={(_, newValue) => onSelectionChange(newValue)}
    onInputChange={(_, newInputValue) => {
      setInputValue(newInputValue);
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        {...TextFieldProps}
        required={required}
        label="Prodotto"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
        }}
      />
    )}
    {...otherProps}
  />
}