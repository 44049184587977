import Decimal from "decimal.js";

export interface IQuantityUnit{
  name: string;
  decimals: number;
  step: Decimal;
  toString(): string;
  toHumanReadableString(): string;
}

class QuantityUnit implements IQuantityUnit{
  name: string;
  decimals: number;
  step: Decimal;
  toString(): string {
    return this.name;
  }
  toHumanReadableString(): string{
    return this.toString();
  }
  equals(toCompare: IQuantityUnit){
    if(!toCompare) return false;
    return this.name === toCompare.name;
  }
}

export class KgQuantityUnit extends QuantityUnit{
  name: string = "Kg";
  decimals: number = 2;
  step: Decimal = new Decimal("0.01");
}

export class PackQuantityUnit extends QuantityUnit{
  name: string = "Collo";
  decimals: number = 0;
  step: Decimal = new Decimal(1);
}

export class UnexpectedQuantityUnit implements IQuantityUnit{
  name: string;
  decimals: number = 2;
  step: Decimal = new Decimal(1);

  constructor(name: string){
    this.name = name;
  }
  toHumanReadableString(): string{
    return this.name;
  }
}

export class QuantityUnits{
  static getInstance(name: string): IQuantityUnit{
    switch(name){
      case "Kg":
        return new KgQuantityUnit();
      case "Collo":
        return new PackQuantityUnit();
      default:
        return new UnexpectedQuantityUnit(name);
    }
  }
  static getAll(): IQuantityUnit[]{
    return [
      this.getInstance("Kg"),
      this.getInstance("Collo")
    ]
  }
}

export class ShoppedUnit extends QuantityUnit{
  description?: string;
  constructor(unit: IQuantityUnit, description?: string){
    super();
    this.name = unit.name;
    this.decimals = unit.decimals;
    this.step = unit.step;
    this.description = description;
  }
  toString(): string {
    return this.name;
  }
  toHumanReadableString(): string{
    return this.description || this.name;
  }
}