import { ConvertibleToApiData, EditableApiConcretization, post } from "../connector/ConnectorAPI";
import { Decimal } from "decimal.js";
import { QuantityUnits, ShoppedUnit } from "../helpers/types";

export async function editPrice(price: PriceRequest){
    const response: ApiPrice = await post('/prezzo', price.toEditedApiObject());
    return new Price(response, price.product);
}

export interface ApiPriceRequest{
    valore: string;
    misura: string;
    descrizioneUnita?: string;
}
export interface ApiPrice extends ApiPriceRequest{
    id: number;
}

export class SimplePriceRequest
    implements ConvertibleToApiData<ApiPriceRequest>, EditableApiConcretization
{
    value: Decimal;
    measure: ShoppedUnit;
    protected editedValue: boolean = false;

    constructor(price: ApiPriceRequest){
        this.value = new Decimal(price.valore);
        this.measure = new ShoppedUnit(
            QuantityUnits.getInstance(price.misura),
            price.descrizioneUnita
        );
    }

    public get textualValue(): string{
        const fullString = this.value.toFixed(3);
        return fullString[fullString.length - 1] === '0' ?
            fullString.substring(0, fullString.length - 1) :
            fullString;
    }

    public set textualValue(value: string){
        this.editedValue = true;
        this.value = new Decimal(value);
    }

    toApiObject(): ApiPriceRequest{
        return {
            valore: this.value.toFixed(3),
            misura: this.measure.name,
            descrizioneUnita: this.measure.description
        };
    }

    toEditedApiObject(): object{
        return this.editedValue ?
            {
                valore: this.value.toFixed(3),
                misura: this.measure.name,
                descrizioneUnita: this.measure.description
            } :
            {};
    }
}

export class PriceRequest extends SimplePriceRequest{
    product: number;

    constructor(price: ApiPriceRequest, product: number){
        super(price);
        this.product = product;
    }

    toEditedApiObject(): object{
        return this.editedValue ?
            {
                ...super.toEditedApiObject(),
                prodotto: this.product
            } :
            {};
    }
}

export class Price extends PriceRequest{
    id: number;

    constructor(price: ApiPrice, product: number){
        super(price, product);
        this.id = price.id;
    }

    toApiObject(): ApiPrice{
        return {
            ...super.toApiObject(),
            id: this.id
        };
    }
}