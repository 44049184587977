import { Box, Button, Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getComanda, getGeneratedComanda, isLoading } from '../features/remote/orders/OrdersSlice';
import PageLayout from '../layout/PageLayout';

import './Comanda.css';

type ComandaState = {
	orders: number[]
};

export default function Comanda(){
	const comanda = useAppSelector(getGeneratedComanda);
	const loading = useAppSelector(isLoading);
	const printableRef = useRef<HTMLUListElement>(null);
  const { state } = useLocation();
  const { orders } = state as ComandaState;
	const dispatch = useAppDispatch();
  const print = useReactToPrint({
    content: () => printableRef.current,
  });

	useEffect(
		() => {
			dispatch(getComanda(orders));
		},
		[orders] // eslint-disable-line react-hooks/exhaustive-deps
	);

	return <PageLayout pageTitle="Comanda">
		<Stack direction="column" spacing={2} alignItems="stretch" width={1}>
			<Button variant="contained" onClick={print} disabled={loading}>
				Stampa
			</Button>
			<List
				sx={{
					width: '100%',
					bgcolor: 'background.paper'
				}}
				ref={printableRef}
			>
      {
				comanda ?
				Array.from(comanda.details, ([product, requests]) => ({product, requests}))
				.map(
					({product, requests}) => requests.map(
						request => {
							const id = `${product.id}-${request.unit.toString()}`
							return <Box key={id}>
								<ListItem
									secondaryAction={
										<Typography>{ request.textualQuantityWithUnit }</Typography>
									}
									disablePadding
								>
									<ListItemButton role={undefined} dense>
										<ListItemIcon>
											<Checkbox
												edge="start"
												tabIndex={-1}
												disableRipple
												inputProps={{
													'aria-labelledby': `checkbox-list-label-${id}`
												}}
											/>
										</ListItemIcon>
										<ListItemText
											id={`checkbox-list-label-${id}`}
											primary={product.name}
										/>
									</ListItemButton>
								</ListItem>
								<Divider />
							</Box>
						}
					)
				) :
				[120, 100, 130, 80, 70, 110].map(
					(width, i) => <Box key={i}>
						<ListItem
							secondaryAction={
								<Skeleton variant="text">
									<Typography>123 Un(12)</Typography>
								</Skeleton>
							}
							disablePadding
						>
							<ListItemButton role={undefined} dense>
								<ListItemIcon>
									<Skeleton>
										<Checkbox
											edge="start"
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': `checkbox-list-label-${i}`
											}}
										/>
									</Skeleton>
								</ListItemIcon>
								<ListItemText
									id={`checkbox-list-label-${i}`}
									primary={
										<Skeleton variant="text" width={width}/>
									}
								/>
							</ListItemButton>
						</ListItem>
					</Box>
				)
			}
    </List>
		</Stack>
	</PageLayout>
}