import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography, FormControl, Select, InputLabel, MenuItem, SxProps } from '@mui/material';
import { editSettings, getLoadedSettings, getSettings, isLoading } from '../features/remote/settings/SettingsSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

type SettingDialogProps = {
  handleClose: () => any;
  open: boolean;
  sx?: SxProps
}

export default function SettingsDialog({ handleClose, open, sx }: SettingDialogProps){

  const settings = useAppSelector(getLoadedSettings);
  const loading = useAppSelector(isLoading);
  const [selectedMessage, setSelectedMessage] = useState('presentazione');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [howToGetAbilitationMessage, setHowToGetAbilitationMessage] = useState('');
  const dispatch = useAppDispatch();

  function updateSettings(event: SyntheticEvent){
    event.preventDefault();
    if(settings.welcomeMessage !== welcomeMessage)
      settings.welcomeMessage = welcomeMessage;
    if(settings.howToGetAbilitationMessage !== howToGetAbilitationMessage)
      settings.howToGetAbilitationMessage = howToGetAbilitationMessage;
    dispatch(editSettings(settings));
  }

  useEffect(
    () => {
      dispatch(getSettings());
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if(settings){
        setWelcomeMessage(settings.welcomeMessage);
        setHowToGetAbilitationMessage(settings.howToGetAbilitationMessage);
      }
    },
    [settings]
  );
  
  const messages = {
    "presentazione": {
      key: "presentazione",
      label: "Messaggio di presentazione",
      handleChange: (value: string) => setWelcomeMessage(value),
      value: welcomeMessage
    },
    "abilitazione": {
      key: "abilitazione",
      label: "Messaggio abilitazione",
      handleChange: (value: string) => setHowToGetAbilitationMessage(value),
      value: howToGetAbilitationMessage
    }
  }

  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
    sx={sx}
  >
    <DialogTitle>Impostazioni sistema</DialogTitle>
    <DialogContent dividers>
      <Stack
        direction="column" alignItems="stretch" width={1} spacing={2}
        component="form" onSubmit={updateSettings} sx={{ mb: 2 }}
      >
        <Typography variant="h5">
          Generali
        </Typography>
        <FormControl fullWidth sx={{ pl: 2 }}>
          <InputLabel id="setting-dialog-message-selector" sx={{ pl: 2 }}>
            Messaggio
          </InputLabel>
          <Select
            labelId="setting-dialog-message-selector"
            value={selectedMessage}
            label="Messaggio"
            onChange={event => setSelectedMessage(event.target.value)}
          >
            {
              Object.keys(messages).map(
                key =>  <MenuItem
                  key={messages[key].key}
                  value={messages[key].key}
                >
                  {messages[key].label}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
        {
          selectedMessage &&
          <Box width={1} pl={2}>
            <TextField
              label={messages[selectedMessage].label}
              multiline
              rows={4}
              fullWidth
              value={messages[selectedMessage].value || ''}
              onChange={
                event =>
                  messages[selectedMessage]
                    .handleChange(event.target.value)
              }
              disabled={loading}
              inputProps={{
                maxLength: 144
              }}
            />
          </Box>
        }
        <Box width={1} pl={2}>
          <Button type="submit" fullWidth variant="contained" disabled={loading}>
            Salva
          </Button>
        </Box>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="error">
        Chiudi
      </Button>
    </DialogActions>
  </Dialog>
}