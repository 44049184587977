import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import AdminMenu from './pages/AdminMenu';
import AdminRoute from './pages/AdminRoute';
import UserRoute from './pages/UserRoute';
import Login from './pages/Login';
import OrderList from './pages/OrderList';
import Order from './pages/Order';
import ProductList from './pages/ProductList';
import { Reports } from './pages/Reports';
import OrdersPrinter from './pages/OrdersPrinter';
import Comanda from './pages/Comanda';
import Shop from './pages/Shop';
import Cart from './pages/Cart';
import Profile from './pages/Profile';
import MakeOrder from './pages/MakeOrder';
import MyOrderList from './pages/MyOrderList';
import MyOrder from './pages/MyOrder';
import Users from './pages/Users';
import { useConfig } from './features/remote/config/Config';

function AppRoutes(){
  return <Routes>
    <Route path="/" element={<UserRoute />}>
      <Route index element={<Login />} />
      <Route path="vetrina" element={<Shop />} />
      <Route path="ordini">
        <Route index element={<MyOrderList />} />
        <Route path=":orderCode" element={<MyOrder />} />
      </Route>
      <Route path="carrello">
        <Route index element={<Cart />} />
        <Route path="conferma" element={<MakeOrder />} />
      </Route>
      <Route path="profilo" element={<Profile />} />
      <Route path="admin" element={<AdminRoute />}>
        <Route index element={<AdminMenu />} />
        <Route path="ordini">
          <Route index element={<OrderList />} />
          <Route path="print" element={<OrdersPrinter />} />
          <Route path="comanda" element={<Comanda />} />
          <Route path=":orderCode" element={<Order />} />
        </Route>
        <Route path="prodotti" element={<ProductList />} />
        <Route path="utenti" element={<Users />} />
        <Route path="resoconti" element={<Reports />} />
      </Route>
    </Route>
  </Routes>
}

function App() {

  const { config } = useConfig();

  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
    >
      <BrowserRouter basename={process.env.REACT_APP_BASEPATH || ''}>
        <AppRoutes />
      </BrowserRouter>
    </Auth0Provider>
  );
}

export default App;
