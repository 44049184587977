import { TextField, Grid, Stack, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import ProductSearchField from '../components/ProductSearchField';
import PageLayout from '../layout/PageLayout';
import { getSaleEvents } from '../features/remote/events/commercialEvents/SaleEventsActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { getPurchaseEvents } from '../features/remote/events/commercialEvents/PurchaseEventsActions';
import UserSearchField from '../components/UserSearchField';
import { OrderState } from '../features/remote/orders/OrdersAPI';
import { Decimal } from "decimal.js";
import { User } from '../features/remote/users/UsersAPI';
import { useAppDispatch } from '../app/hooks';
import { Product } from '../features/remote/products/ProductsAPI';
import { SaleEvent } from '../features/remote/events/commercialEvents/SaleEventsAPI';
import { PurchaseEvent } from '../features/remote/events/commercialEvents/PurchaseEventsAPI';

const today = new Date().toISOString().substring(0, 10);
const oneWeekBeforeToday = new Date(Date.now() - 604800000).toISOString().substring(0, 10);

function retrieveSaleEvents(from: string, to: string, product?: Product, user?: User){
  return getSaleEvents({
    from: new Date(`${from}T00:00`),
    to: new Date(`${to}T23:59`),
    product: product?.id,
    user: user?.email,
    orderStates: [OrderState.DELIVERED]
  });
}
function retrievePurchaseEvents(from: string, to: string, product?: Product){
  return getPurchaseEvents({
    from: new Date(`${from}T00:00`),
    to: new Date(`${to}T23:59`),
    product: product?.id
  })
}
function calculateEarningsMap(
  sales: SaleEvent[], purchases: PurchaseEvent[], net = false
): Record<string, Decimal>{
  const earnings = {};
  for(const sale of sales){
    const day = sale.timestamp.toLocaleDateString();
    if(!earnings[day])
      earnings[day] = new Decimal(0);
    earnings[day] = earnings[day].plus(
      net ? sale.totPriceExcludedIva : sale.totPrice
    );
  }
  for(const purchase of purchases){
    const day = purchase.timestamp.toLocaleDateString();
    if(!earnings[day])
      earnings[day] = new Decimal(0);
    earnings[day] = earnings[day].minus(purchase.totPrice);
  }
  return earnings;
}

type EarningsList = ({day: string; value: number})[];

function calculateEarningsList(
  sales: SaleEvent[], purchases: PurchaseEvent[], net = false
): EarningsList{
  const earningsMap = calculateEarningsMap(sales, purchases, net);
  const earningsList: EarningsList = [];
  for(const entry of Object.entries(earningsMap)){
    earningsList.push({day: entry[0], value: entry[1].toNumber()});
  }
  return earningsList;
}
function calculateNetEarningsList(sales: SaleEvent[], purchases: PurchaseEvent[]){
  return calculateEarningsList(sales, purchases, true);
}
function calculateGrossEarningsList(sales: SaleEvent[]){
  return calculateEarningsList(sales, [], false);
}
function calculateTotal(list: EarningsList){
  let total = 0;
  for(const day of list){
    total += day.value;
  }
  return total.toFixed(2);
}
function calculateTotalIva(sales: SaleEvent[]){
  return sales.reduce(
      (sum, sale) => sum.plus(
        sale.totPrice
      ).minus(
        sale.totPriceExcludedIva
      ),
      new Decimal(0)
    ).toFixed(3);
}

export function Reports(){

  const theme = useTheme();
  const [netProduct, setNetProduct] = useState<Product | undefined>();
  const [netFrom, setNetFrom] = useState(oneWeekBeforeToday);
  const [netTo, setNetTo] = useState(today);
  const [grossUser, setGrossUser] = useState<User | undefined>();
  const [grossFrom, setGrossFrom] = useState(oneWeekBeforeToday);
  const [grossTo, setGrossTo] = useState(today);

  const [netEarnings, setNetEarnings] = useState<EarningsList | null>(null);
  const [grossEarnings, setGrossEarnings] = useState<EarningsList | null>(null);
  const [netTotal, setNetTotal] = useState('0.00');
  const [grossTotal, setGrossTotal] = useState('0.00');
  const [totalIva, setTotalIva] = useState('0.00');

  const dispatch = useAppDispatch();

  useEffect(
    () => {
      Promise.all([
        dispatch(retrieveSaleEvents(netFrom, netTo, netProduct))
          .then(unwrapResult),
        dispatch(retrievePurchaseEvents(netFrom, netTo, netProduct))
          .then(unwrapResult)
      ]).then(
        ([sales, purchases]) => {
          const earnings = calculateNetEarningsList(sales, purchases);
          setNetEarnings(earnings);
          setNetTotal(calculateTotal(earnings));
        }
      );
    }, [netFrom, netTo, netProduct] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(
    () => {
        dispatch(retrieveSaleEvents(grossFrom, grossTo, undefined, grossUser))
          .then(unwrapResult)
          .then(
            sales => {
              const earnings = calculateGrossEarningsList(sales);
              const totIva = calculateTotalIva(sales);
              setGrossEarnings(earnings);
              setGrossTotal(calculateTotal(earnings));
              setTotalIva(totIva);
            }
          );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [grossFrom, grossTo, grossUser]
  );

  return <PageLayout pageTitle="Resoconti">
    <Grid container direction="column" alignItems="stretch" spacing={8}>
      <Grid item container spacing={4}>
        <Grid item xs={12} md={4} container>
          <Stack direction="column" width={1}>
            <Typography
              variant="h4" width={1} align="center" gutterBottom
            >
              Profitto netto
            </Typography>
            <Typography
              variant="h6" width={1}
              align="center" color="black1.main"
            >
              Totale periodo
            </Typography>
            <Typography
              variant="h6" width={1}
              align="center" color="black3.main"
            >
              {netTotal} &euro;
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item xs={12} md={8} container direction="column" alignItems="stretch"
          spacing={4}
        >
          <Grid
            item direction="row" container justifyContent="space-between"
            spacing={{ xs: 2, md: 8 }}
          >
            <Grid item xs={12} md={4}>
              <ProductSearchField
                selection={netProduct}
                onSelectionChange={selection => setNetProduct(selection || undefined)}
                TextFieldProps={{
                  variant: 'standard'
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Dal giorno"
                variant='standard'
                type="date"
                InputLabelProps={{ shrink: true }}
                value={netFrom}
                onChange={event => setNetFrom(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Al giorno"
                variant='standard'
                type="date"
                InputLabelProps={{ shrink: true }}
                value={netTo}
                onChange={event => setNetTo(event.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item>
            {
              netEarnings ?
              (
                netEarnings.length > 0 ?
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={netEarnings}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="value" fill={theme.palette.primary.main}
                      unit="&euro;" name="Profitto netto"
                    />
                  </BarChart>
                </ResponsiveContainer> :
                <Typography color="black1.main" align="center">
                  Non ci sono abbastanza dati.
                </Typography>
              ) :
              <Skeleton width="100%" height={300} variant="rectangular" />
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={12} md={4} container>
          <Stack direction="column" width={1}>
            <Typography
              variant="h4" width={1} align="center" gutterBottom
            >
              Profitto lordo
            </Typography>
            <Typography
              variant="h6" width={1}
              align="center" color="black1.main"
            >
              Totale periodo
            </Typography>
            <Typography
              variant="h6" width={1}
              align="center" color="black3.main"
            >
              {grossTotal} &euro;
            </Typography>
            <Typography
              variant="h6" width={1}
              align="center" color="black1.main"
            >
              di cui IVA
            </Typography>
            <Typography
              variant="h6" width={1}
              align="center" color="black3.main"
            >
              {totalIva} &euro;
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item xs={12} md={8} container direction="column" alignItems="stretch"
          spacing={4}
        >
          <Grid
            item direction="row" container justifyContent="space-between"
            spacing={{ xs: 2, md: 8 }}
          >
            <Grid item xs={12} md={4}>
              <UserSearchField
                selection={grossUser}
                onSelectionChange={selection => setGrossUser(selection)}
                TextFieldProps={{
                  variant: 'standard'
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Dal giorno"
                variant='standard'
                type="date"
                InputLabelProps={{ shrink: true }}
                value={grossFrom}
                onChange={event => setGrossFrom(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Al giorno"
                variant='standard'
                type="date"
                InputLabelProps={{ shrink: true }}
                value={grossTo}
                onChange={event => setGrossTo(event.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item>
            {
              grossEarnings ?
              (
                grossEarnings.length > 0 ?
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={grossEarnings}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="value" fill={theme.palette.primary.main}
                      unit="&euro;" name="Profitto lordo"
                    />
                  </BarChart>
                </ResponsiveContainer> :
                <Typography color="black1.main" align="center">
                  Non ci sono abbastanza dati.
                </Typography>
              ) :
              <Skeleton width="100%" height={300} variant="rectangular"/>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </PageLayout>
}