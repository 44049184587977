import { Button, CircularProgress, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, TextField } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getDeliveryAddress, setDeliveryAddress } from "../features/user/UserSlice";
import PageLayout from "../layout/PageLayout";
import React, { useState, useEffect } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { requestPasswordChangeEmail } from "../features/remote/users/UsersSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { useConfig } from "../features/remote/config/Config";
import { isASocialAccount } from "../features/user/utils";

export default function Profile(){

  const deliveryAddress = useAppSelector(getDeliveryAddress);
  const [address, setAddress] = useState('');
  const [
    isProcessingPasswordChangeReqeust,
    setIsProcessingPasswordChangeReqeust
  ] = useState(false);
  const [savingUser, setSavingUser] = useState(false);
  const { user } = useAuth0();
  const showUserBlock = !user || !isASocialAccount(user);
  const { enqueueSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();

  useEffect(
    () => setAddress(deliveryAddress),
    [deliveryAddress]
  );

  function saveAddress(){
    setSavingUser(true);
    dispatch(setDeliveryAddress(address));
    enqueueSnackbar('Indirizzo aggiornato', {variant: 'success'});
    setSavingUser(false);
  }

  function handlePasswordChange(){
    setIsProcessingPasswordChangeReqeust(true);
    if(user?.email)
      dispatch(requestPasswordChangeEmail(user.email))
      .then(unwrapResult)
      .then(
        () => enqueueSnackbar(
          'Ti è stata spedita una mail per cambiare la tua password', {variant: 'success'}
        )
      ).catch(
        error => enqueueSnackbar(
          parseMessageFromError(error), {variant: 'error'}
        )
      ).finally(
        () => setIsProcessingPasswordChangeReqeust(false)
      );
  }

  return <PageLayout pageTitle="Il mio profilo">
    <Stack direction="column" spacing={4} width={1} maxWidth={500}>
      <TextField
        label="Email"
        value={user?.email}
        disabled
      />
      <TextField
        label="Indirizzo"
        value={address}
        onChange={event => setAddress(event.target.value)}
        disabled={savingUser}
      />
      <Button variant="contained" onClick={saveAddress} disabled={savingUser}>
        Salva
      </Button>
      {
        showUserBlock &&
        <Divider />
      }
      {
        showUserBlock &&
        <List>
          <ListItem
            onClick={() => {
              if(!isProcessingPasswordChangeReqeust)
                handlePasswordChange()
            }}
            disabled={isProcessingPasswordChangeReqeust}
            secondaryAction={
              isProcessingPasswordChangeReqeust ?
              <CircularProgress size={20} /> :
              <IconButton>
                <ArrowForwardIos fontSize="small" />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemText
                primary="Cambia password"
                primaryTypographyProps={{
                  variant: 'h5'
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      }
    </Stack>
  </PageLayout>
}