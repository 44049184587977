import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { RootState } from '../../app/store';

interface LocalUserState{
  deliveryAddress: string;
}
const initialState = {
  deliveryAddress: ''
} as LocalUserState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDeliveryAddress: (state, action) => {
      const address = action.payload;
      state.deliveryAddress = address;
    }
  },
  extraReducers: {},
});

export const getDeliveryAddress = (state: RootState) => state.user.deliveryAddress;

export const { setDeliveryAddress } = userSlice.actions;


const persistConfig = {
  key: 'user',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);

export default persistedReducer;
