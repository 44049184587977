import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { RootState } from '../../app/store';
import { ViewMode } from '../../components/ProductsView';

interface AppSettingsState{
  defaultProductsView: ViewMode;
}
const initialState = {
  defaultProductsView: 'list'
} as AppSettingsState;

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    toggleDefaultProductsView: (state) => {
      state.defaultProductsView = state.defaultProductsView === 'list' ? 'grid' : 'list';
    }
  },
  extraReducers: {},
});

export const getDefaultProductsView =
  (state: RootState) => state.appSettings.defaultProductsView;

export const { toggleDefaultProductsView } = appSettingsSlice.actions;


const persistConfig = {
  key: 'appSettings',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, appSettingsSlice.reducer);

export default persistedReducer;
