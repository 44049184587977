import { Box } from "@mui/material";
import AlternativePageLayout from "../layout/AlternativePageLayout";
import FruitLoader from "../components/FruitLoader";
import React from "react";

export default function WaitingPage(){
    return <AlternativePageLayout>
        <Box
            position='absolute'
            top={0}
            left={0}
            height='100vh'
            width='100vw'
            display='flex'
            justifyContent="center"
            alignItems="center"
        >
            <FruitLoader width={1} sx={{ maxWidth: 500 }} />
        </Box>
    </AlternativePageLayout>
}