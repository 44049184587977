import React, { useState, useEffect, FormEvent } from 'react';
import { SxProps, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { ReversedEventRequest } from '../features/remote/events/commercialEvents/ReversedEventsAPI';
import { addReversedEvent } from '../features/remote/events/commercialEvents/ReversedEventsActions';
import QuantityUnitSelector from './QuantityUnitSelector';
import { useTheme } from '@mui/system';
import { useAppDispatch } from '../app/hooks';
import { ProductStatistics } from '../features/remote/products/ProductsAPI';
import { NumberTextField } from './NumberTextField';

type ReversedDialogProps = {
  productId?: number;
  open: boolean;
  handleClose: () => any;
  sx?: SxProps;
}

export function ReversedDialog({ productId, open, handleClose, sx }: ReversedDialogProps){

  const [quantity, setQuantity] = useState('');
  const [stock, setStock] = useState<ProductStatistics>();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  function addReversed(event: FormEvent<HTMLFormElement>){
    event.preventDefault();
    if(productId && stock){
      const reversed = new ReversedEventRequest({
        quantita: quantity,
        unita: stock.unit.name,
        prodotto: productId,
        costo: '0'
      });
      dispatch(addReversedEvent(reversed));
      handleClose();
    }
  }

  useEffect(
    () => {
      if(!open){
        setQuantity('');
      }
    },
    [open]
  );

  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
    sx={sx}
  >
    <DialogTitle>Aggiungi storno</DialogTitle>
    <form onSubmit={addReversed}>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <NumberTextField
              fullWidth
              value={quantity}
              onChange={event => setQuantity(event.target.value)}
              label="Quantità"
              inputProps={{
                min: 0,
                step: stock ? stock.unit.step.toFixed(2) : 0,
                max: stock ? stock.textualAvailable : 0
              }}
              helperText={stock && `max. ${stock.textualAvailable}`}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <QuantityUnitSelector
              productId={productId}
              value={stock}
              onChange={value => setStock(value)}
              fullWidth
              required
              SelectProps={{
                MenuProps: {
                  sx: {
                    zIndex: (theme.zIndex as any).modal + 1
                  }
                }
              }}
              checkAvailability={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: 1 }}
        >
          <Button onClick={handleClose} color="error">
            Annulla
          </Button>
          <Button type="submit">
            Aggiungi
          </Button>
        </Stack>
      </DialogActions>
    </form>
  </Dialog>
}