const translation = {
    // Root
    noRowsLabel: 'Nessuna riga',
    noResultsOverlayLabel: 'Nessun risultato trovato.',
    errorOverlayDefaultLabel: 'È avvenuto un errore.',
    
    // Density selector toolbar button text
    toolbarDensity: 'Density',
    toolbarDensityLabel: 'Density',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfortable',
    
    // Columns selector toolbar button text
    toolbarColumns: 'Colonne',
    toolbarColumnsLabel: 'Seleziona colonne',
    
    // Filters toolbar button text
    toolbarFilters: 'Filtri',
    toolbarFiltersLabel: 'Mostra filtri',
    toolbarFiltersTooltipHide: 'Nascondi filtri',
    toolbarFiltersTooltipShow: 'Mostra filtri',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtri attivi` : `${count} filtro attivo`,
    
    // Export selector toolbar button text
    toolbarExport: 'Esporta',
    toolbarExportLabel: 'Esporta',
    toolbarExportCSV: 'Scarica come CSV',
    toolbarExportPrint: 'Stampa',
    
    // Columns panel text
    columnsPanelTextFieldLabel: 'Trova colonna',
    columnsPanelTextFieldPlaceholder: 'Titolo colonna',
    columnsPanelDragIconLabel: 'Riordina colonna',
    columnsPanelShowAllButton: 'Mostra tutto',
    columnsPanelHideAllButton: 'Nascondi tutto',
    
    // Filter panel text
    filterPanelAddFilter: 'Aggiungi filtro',
    filterPanelDeleteIconLabel: 'Elimina',
    filterPanelOperators: 'Operatori',
    filterPanelOperatorAnd: 'E',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Colonne',
    filterPanelInputLabel: 'Valore',
    filterPanelInputPlaceholder: 'Valore filtro',
    
    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'uguale',
    filterOperatorStartsWith: 'inizia con',
    filterOperatorEndsWith: 'finisce con',
    filterOperatorIs: 'è',
    filterOperatorNot: 'non è',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    filterOperatorIsEmpty: 'è vuoto',
    filterOperatorIsNotEmpty: 'non è vuoto',
    filterOperatorIsAnyOf: 'is any of',
    
    // Filter values text
    filterValueAny: 'qualsiasi',
    filterValueTrue: 'vero',
    filterValueFalse: 'falso',
    
    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostra colonne',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Nascondi',
    columnMenuUnsort: 'Unsort',
    columnMenuSortAsc: 'Ordina ascendente',
    columnMenuSortDesc: 'Ordina discendente',
    
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtri attivi` : `${count} filtro attivo`,
    columnHeaderFiltersLabel: 'Mostra filtri',
    columnHeaderSortIconLabel: 'Ordina',
    
    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
        ? `${count.toLocaleString()} righe selezionate`
        : `${count.toLocaleString()} riga selezionata`,
    
    // Total rows footer text
    footerTotalRows: 'Totale righe:',
    
    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} di ${totalCount.toLocaleString()}`,
    
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    
    // Boolean cell text
    booleanCellTrueLabel: 'vero',
    booleanCellFalseLabel: 'falso',
    
    // Actions cell more text
    actionsCellMore: 'altro',
    
    // Column pinning text
    pinToLeft: 'Blocca a sinistra',
    pinToRight: 'Blocca a destra',
    unpin: 'Sblocca',
    
    // Tree Data
    treeDataGroupingHeaderName: 'Group',
    treeDataExpand: 'see children',
    treeDataCollapse: 'hide children',
    
    // Grouping columns
    groupingColumnHeaderName: 'Gruppo',
    groupColumn: (name) => `Raggruppa per ${name}`,
    unGroupColumn: (name) => `Ferma raggruppamento per ${name}`,
    
    // Used core components translation keys
    MuiTablePagination: {},
}

export default translation;