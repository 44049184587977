import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Alert } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { editPrice } from "../features/remote/prices/PricesActions";
import { PriceRequest } from "../features/remote/prices/PricesAPI";
import { editProduct, getError, getLoadedProduct, getProduct, isLoading as isLoadingProduct } from "../features/remote/products/ProductsSlice";
import { useConfig } from "../features/remote/config/Config";
import ProductForm, { ProductData } from "./ProductForm";
import StockPanel from "./StockPanel";

type EditProductDialogProps = {
  open: boolean;
  productId?: number;
  handleClose: () => any;
}

export default function EditProductDialog(
  { open, productId, handleClose }: EditProductDialogProps
){

  const product = useAppSelector(getLoadedProduct);
  const isLoading = useAppSelector(isLoadingProduct);
  const error = useAppSelector(getError);
  const [productData, setProductData] = useState<ProductData>({});
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();

  function confirmEdit(event: SyntheticEvent){
    event.preventDefault();
    if(productId){
      if(productData.kgPrice){
        const price =
          product?.prices.find(price => price.measure.name === 'Kg') ||
          new PriceRequest({ valore: '1', misura: 'Kg' }, productId);
        price.textualValue = productData.kgPrice;
        dispatch(editPrice(price));
      }
      if(productData.packPrice){
        const price =
          product?.prices.find(price => price.measure.name === 'Collo') ||
          new PriceRequest({ valore: '1', misura: 'Collo' }, productId);
        price.textualValue = productData.packPrice;
        price.measure.description = productData.packDescription;
        dispatch(editPrice(price));
      }

      if(
        product && productData.name && (
          product.name !== productData.name ||
          product.code !== productData.code ||
          product.origin !== productData.origin ||
          product.commercialCategory !== productData.commercialCategory ||
          product.image !== productData.image ||
          product.category !== productData.category
        )
      ){
        product.name = productData.name;
        product.code = productData.code;
        product.origin = productData.origin;
        product.commercialCategory = productData.commercialCategory;
        product.image = productData.image;
        product.category = productData.category;
        dispatch(editProduct(product));
      }
    }
  }

  useEffect(
    () => {
      if(productId)
        dispatch(getProduct({id: productId}));
    },
    [productId, dispatch]
  );

  useEffect(
    () => {
      if(product){
        const retrievedData: ProductData = {...product};
        const kgPrice = retrievedData.prices?.find(
          price => price.measure.name === 'Kg'
        );
        const packPrice = retrievedData.prices?.find(
          price => price.measure.name === 'Collo'
        );
        if(kgPrice)
          retrievedData.kgPrice = kgPrice.textualValue;
        if(packPrice)
          retrievedData.packPrice = packPrice.textualValue;
        if(packPrice)
          retrievedData.packDescription = packPrice.measure.description;
        setProductData(retrievedData);
      }
    },
    [product]
  );

  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
  >
    <DialogTitle>Modifica {product?.name}</DialogTitle>
    <DialogContent dividers>
      <Stack direction="column"  spacing={2}>
        <form id="edit_product_form" onSubmit={confirmEdit}>
          <ProductForm
            onDataChange={setProductData}
            values={productData}
            isLoading={isLoading}
          />
        </form>
        {
          error &&
          <Alert severity="error" sx={{width: 1}}>{parseMessageFromError(error)}</Alert>
        }
        <Button
          type="submit" form="edit_product_form" variant="contained"
          disabled={isLoading}
        >
          Salva
        </Button>
        <StockPanel
          productId={productId}
        />
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="error">
        Chiudi
      </Button>
    </DialogActions>
  </Dialog>
}