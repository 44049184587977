import { EditableApiConcretization, get, post } from "../connector/ConnectorAPI";

export async function getSettings(): Promise<Settings>{
    const configuration: ApiSettings = await get('/impostazioni');
    return new Settings(configuration);
}

export async function editSettings(settings: Settings): Promise<Settings>{
    const configuration: ApiSettings = await post(
        '/impostazioni', settings.toEditedApiObject()
    );
    return new Settings(configuration);
}

export interface ApiSettings{
    messaggioBenvenuto: string;
    messaggioAbilitazione: string;
}

export class Settings implements EditableApiConcretization{
    private _welcomeMessage: string;
    private _editedWelcomeMessageValue: boolean;
    private _howToGetAbilitationMessage: string;
    private _editedhowToGetAbilitationMessageValue: boolean;

    constructor(settings?: ApiSettings){
        if(settings){
            this._welcomeMessage = settings.messaggioBenvenuto;
            this._howToGetAbilitationMessage = settings.messaggioAbilitazione;
        }
    }
    public get welcomeMessage(): string{
        return this._welcomeMessage;
    }
    public set welcomeMessage(message: string){
        this._editedWelcomeMessageValue = true;
        this._welcomeMessage = message;
    }
    public get howToGetAbilitationMessage(): string{
        return this._howToGetAbilitationMessage;
    }
    public set howToGetAbilitationMessage(message: string){
        this._editedhowToGetAbilitationMessageValue = true;
        this._howToGetAbilitationMessage = message;
    }
    toEditedApiObject(): object {
        const edited: any = {};
        if(this._editedWelcomeMessageValue)
            edited.messaggioBenvenuto = this._welcomeMessage;
        if(this._editedhowToGetAbilitationMessageValue)
            edited.messaggioAbilitazione = this._howToGetAbilitationMessage;
        return edited;
    }
}