import { get, post, put } from "../../connector/ConnectorAPI";
import { OrderState } from "../../orders/OrdersAPI";
import { ApiProduct, Product } from "../../products/ProductsAPI";
import { ApiCommercialEvent, ApiCommercialEventRequest, CommercialEvent, CommercialEventRequest } from "./CommercialEventsAPI";
import { Decimal } from "decimal.js";
import { QuantityUnits, ShoppedUnit } from "../../helpers/types";

interface SelesFiltersOption{
    from?: Date;
    to?: Date;
    product?: number;
    user?: string;
    orderStates?: OrderState[]
}
export async function getSales(options?: SelesFiltersOption): Promise<SaleEvent[]>{
    const filters: any = {};
    if(options?.from)
        filters.from = options.from.getTime();
    if(options?.to)
        filters.to = options.to.getTime();
    if(options?.product)
        filters.product = options.product;
    if(options?.user)
        filters.user = options.user;
    if(options?.orderStates)
        filters.orderStates = options.orderStates;
    const response: ApiSaleEvent[] = await get('/eventiVendita', filters);
    return response.map(
        apiSale => new SaleEvent(apiSale)
    );
}

export async function getSale(id: number): Promise<SaleEvent>{
    const response: ApiSaleEvent = await get(`/eventoVendita/${id}`);
    return new SaleEvent(response);
}

export async function addSale(event: SaleEventRequest): Promise<SaleEvent>{
    const response: ApiSaleEvent = await put('/eventoVendita', event.toApiObject());
    return new SaleEvent(response);
}

export async function editSale(event: SaleEvent): Promise<SaleEvent>{
    const response: ApiSaleEvent = await post(
        `/eventoVendita/${event.id}`, event.toEditedApiObject()
    );
    return new SaleEvent(response);
}

export interface ApiSaleEventRequest extends ApiCommercialEventRequest{
    prodotto: number;
    ordine: number;
    descrizioneUnita?: string;
}

export interface ApiSaleEvent extends ApiCommercialEvent{
    prodotto: ApiProduct;
    iva: number;
    descrizioneUnita?: string;
}

export class SaleEventRequest extends CommercialEventRequest{
    product: number;
    order: number;
    unit: ShoppedUnit;
    protected _totPriceExcludedIva: Decimal;

    constructor(event: ApiSaleEventRequest){
        super(event);
        this.product = event.prodotto;
        this.order = event.ordine;
        this._totPriceExcludedIva = this.totPrice;
        this.unit = new ShoppedUnit(
            QuantityUnits.getInstance(event.unita),
            event.descrizioneUnita
        );
    }

    public get totPriceExcludedIva(): Decimal{
        return this._totPriceExcludedIva;
    }
    public set totPriceExcludedIva(totPriceExcludedIva: Decimal){
        this._totPriceExcludedIva = totPriceExcludedIva;
        this.totPrice = totPriceExcludedIva;
    }
    public get textualTotPriceExcludedIva(): string{
        return this._totPriceExcludedIva.toFixed(3);
    }
    public set textualTotPriceExcludedIva(price: string){
        this.totPriceExcludedIva = new Decimal(price);
        this.totPrice = this.totPriceExcludedIva;
    }

    toApiObject(): ApiSaleEventRequest {
        const apiObject = super.toApiObject();
        return {
            ...apiObject,
            costo: this.textualTotPriceExcludedIva,
            prodotto: this.product,
            ordine: this.order,
            descrizioneUnita: this.unit.description
        }
    }
}

export class SaleEvent extends CommercialEvent{
    product: Product;
    iva: number;
    unit: ShoppedUnit;
    protected _totPriceExcludedIva: Decimal;
    
    constructor(event: ApiSaleEvent){
        super(event);
        this.product = new Product(event.prodotto);
        this.iva = event.iva;
        this._totPriceExcludedIva = this.totPrice;
        this.totPrice = this.totPrice.plus(
            this.totPrice.times(this.iva).dividedBy(100)
        );
        this.unit = new ShoppedUnit(
            QuantityUnits.getInstance(event.unita),
            event.descrizioneUnita
        );
    }

    public get totPriceExcludedIva(): Decimal{
        return this._totPriceExcludedIva;
    }
    public set totPriceExcludedIva(totPriceExcludedIva: Decimal){
        this._totPriceExcludedIva = totPriceExcludedIva;
        this.totPrice = totPriceExcludedIva.plus(
            totPriceExcludedIva.times(this.iva).dividedBy(100)
        );
    }
    public get textualTotPriceExcludedIva(): string{
        return this._totPriceExcludedIva.toFixed(3);
    }
    public set textualTotPriceExcludedIva(price: string){
        this.totPriceExcludedIva = new Decimal(price);
    }

    public get textualQuantityWithUnit(){
        return `${this.textualQuantity} ${this.unit.toHumanReadableString()}`;
    }

    public get stockImpact(): string{
        return '-';
    }

    toApiObject(): ApiSaleEvent {
        const apiObject = super.toApiObject();
        return {
            ...apiObject,
            prodotto: this.product.toApiObject(),
            iva: this.iva,
            descrizioneUnita: this.unit.description
        }
    }

    toEditedApiObject(): object {
        const eventEdited = super.toEditedApiObject();
        return {
            ...eventEdited,
            costo: this.textualTotPriceExcludedIva,
            iva: this.iva,
            descrizioneUnita: this.unit.description
        }
    }
}