import { ArrowBackIos, GridView, List as ListIcon } from "@mui/icons-material";
import { Chip, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import ProductsView from "../components/ProductsView";
import ShopFooter from "../components/ShopFooter";
import ShopHeader from "../components/ShopHeader";
import { getDefaultProductsView, toggleDefaultProductsView } from "../features/appSettings/appSettingsSlice";
import { ExtendedProduct } from "../features/remote/products/ProductsAPI";
import { getCategories, getExtendedProducts, getLoadedCategories, getLoadedProducts, isLoading } from "../features/remote/products/ProductsSlice";
import PageLayout from "../layout/PageLayout";

const PRODUCTS_PER_PAGE =
  process.env.REACT_APP_PRODUCTS_PER_PAGE ?
  parseInt(process.env.REACT_APP_PRODUCTS_PER_PAGE) :
  12;

const CategoryChip = styled(Chip)(({ theme }) => ({
  "&.active": {
    backgroundColor: theme.palette.secondary.light
  }
}));

type SearchProductRequest = {
  page: number;
  length: number;
  name?: string;
  categories?: number[];
}

export default function Shop(){

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [loadedProducts, setLoadedProducts] =
    useState<Record<number, ExtendedProduct[]>>({});
  const viewMode = useAppSelector(getDefaultProductsView);
  const products = useAppSelector(getLoadedProducts);
  const categories = useAppSelector(getLoadedCategories);
  const loading = useAppSelector(isLoading);
  const loaderRef = useRef<HTMLElement>();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const loadNewPage = useCallback(
    (page: number, newList: boolean) => {
      const productRequest: SearchProductRequest = { page, length: PRODUCTS_PER_PAGE };
      if(search)
        productRequest.name = search;
      if(selectedCategories?.length > 0)
        productRequest.categories = selectedCategories;
      if(newList)
        setLoadedProducts([]);
      setPage(page);
      dispatch(getExtendedProducts(productRequest));
    },
    [setLoadedProducts, setPage, dispatch, search, selectedCategories]
  )

  useEffect(() => {
    if(loaderRef.current){
      const observer = new IntersectionObserver(([entry]) => {
        if(entry.isIntersecting && !loading){
          loadNewPage(page + 1, false);
        }
      });
      observer.observe(loaderRef.current);
    }
  }, [loaderRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      if(products?.data)
        setLoadedProducts(
          pages => ({...pages, [products.page]: products.data as ExtendedProduct[]})
        );
    },
    [products] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      loadNewPage(0, true);
    },
    [search, selectedCategories] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      dispatch(getCategories());
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleSearchChange(value: string){
    setSearch(value);
  }

  return <PageLayout
    header={<ShopHeader onProductSearch={handleSearchChange} />}
    sx={{ minHeight: `calc(100vh - ${theme.spacing(4)} - 56px)` }}
  >
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <Grid item container sx={{ mb: 2 }} spacing={2}>
        <Grid item container sx={{ width: 'fit-content' }}>
          <Typography
            variant="h5" component="div"
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {
              !search ?
                'Prodotti' :
                <>
                  <IconButton
                    onClick={() => setSearch('')}
                    color="primary"
                  >
                    <ArrowBackIos />
                  </IconButton>
                  Ricerca "{search}"
                </>
            }
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => dispatch(toggleDefaultProductsView())}
          >
            {
              viewMode === 'grid' ?
              <ListIcon /> :
              <GridView />
            }
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container direction="row" justifyContent="flex-start" spacing={2}>
        {
          categories &&
          categories.map(
            category => <Grid item key={category.id}>
              <CategoryChip
                label={category.name}
                className={
                  selectedCategories.includes(category.id) ?
                  'active' :
                  ''
                }
                onClick={
                  () => {
                    setSelectedCategories(
                      selecteds =>
                        selecteds.includes(category.id) ?
                        selecteds.filter(
                          selected => selected !== category.id
                        ) :
                        [ ...selecteds, category.id ]
                    );
                  }
                }
              />
            </Grid>
          )
        }
      </Grid>
      {
        <ProductsView
          products={Object.values(loadedProducts).flat()}
          loading={loading}
          viewMode={viewMode}
          item={true}
        />
      }
      {
        products && !loading && products.count <= 0 &&
        <Grid item container justifyContent="center">
          <Typography variant="body1" color="black1.main">
            Nessun prodotto trovato.
          </Typography>
        </Grid>
      }
      {
        products.count > (page * PRODUCTS_PER_PAGE) && !loading &&
        <Grid
          container justifyContent="center"
          item xs={12} sx={{ my: 2 }}
        >
          <CircularProgress ref={loaderRef} />
        </Grid>
      }
    </Grid>
    <ShopFooter />
  </PageLayout>
}