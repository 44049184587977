import Decimal from "decimal.js";
import { ConvertibleToApiData, del, get, put } from "../connector/ConnectorAPI";

export async function addBalanceEvent(event: BalanceEventRequest): Promise<BalanceEvent>{
  const response: ApiBalanceEvent = await put(
    `/eventoSaldo/${event.user}`,
    event.toApiObject()
  );
  const parsedResponse = new BalanceEvent(response, event.user);
  return parsedResponse;
}

export async function deleteBalanceEvent(id: number){
  await del(`/eventoSaldo/${id}`);
}

export async function getBalanceEvents(user: string): Promise<BalanceEvent[]> {
  const response: ApiBalanceEvent[] = await get(
    `/eventiSaldo/${user}`
  );
  const parsedResponse: BalanceEvent[] = response.map(
    element => new BalanceEvent(element, user)
  );
  return parsedResponse;
}

interface ApiBalanceEventRequest{
  acconto: string;
}
interface ApiBalanceEvent extends ApiBalanceEventRequest{
  id: number;
  timestamp: number;
}

export class BalanceEventRequest
  implements ConvertibleToApiData<ApiBalanceEventRequest>
{
  payment: Decimal;
  user: string;
  constructor(event: ApiBalanceEventRequest, user: string){
    this.payment = new Decimal(event.acconto);
    this.user = user;
  }

  public get textualPayment(): string{
    return this.payment.toFixed(3);
  }

  toApiObject(): ApiBalanceEventRequest {
    return {
      acconto: this.payment.toFixed(3)
    };
  }
}

export class BalanceEvent extends BalanceEventRequest{
  id: number;
  timestamp: Date;

  constructor(event: ApiBalanceEvent, user:string){
    super(event, user);
    this.id = event.id;
    this.timestamp = new Date(event.timestamp);
  }
}