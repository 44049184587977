import { Cancel, CheckCircle } from "@mui/icons-material";
import { Typography, Button, Stack, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useTheme } from "@mui/system";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { getCart } from "../features/remote/cart/CartSlice";
import { OrderRequest } from "../features/remote/orders/OrdersAPI";
import { addOrder, getError } from "../features/remote/orders/OrdersSlice";
import { getDeliveryAddress, setDeliveryAddress } from "../features/user/UserSlice";
import { useConfig } from "../features/remote/config/Config";
import PageLayout from "../layout/PageLayout";

export default function MakeOrder(){

  const savedAddress = useAppSelector(getDeliveryAddress);
  const error = useAppSelector(getError);
  const [address, setAddress] = useState('');
  const [note, setNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const theme = useTheme();
  const { parseMessageFromError } = useConfig();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      dispatch(getCart({page: 0, length: 1}))
      .then(unwrapResult)
      .catch(() => ({count: 0}))
      .then(cart => {
        if(cart.count <= 0)
          navigate(-1);
      })
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(
    () => {
      if(savedAddress)
        setAddress(savedAddress);
    },
    [savedAddress]
  );

  function confirmAddress(){
    setIsLoading(true);
    if(!savedAddress) {
      dispatch(setDeliveryAddress(address));
    }
    dispatch(addOrder(new OrderRequest(address, note)))
    .then(unwrapResult)
    .then(() => setIsRequestSuccess(true))
    .catch(() => setIsRequestSuccess(false))
    .finally(() => {
      setIsRequestCompleted(true);
      setIsLoading(false);
    });
  }

  function cancel(){
    navigate(-1);
  }

  function backAfterRequest(){
    navigate(isRequestSuccess ? -2 : -1);
  }

  return <PageLayout>
    <Stepper
      activeStep={isRequestCompleted ? 1 : 0}
      sx={{ width: 1, maxWidth: 500, mb: 4 }}
    >
      <Step completed={isRequestCompleted}>
        <StepLabel>Indirizzo</StepLabel>
      </Step>
      <Step completed={false}>
        <StepLabel>Richiesta inviata</StepLabel>
      </Step>
    </Stepper>
    <Stack direction="column" width={1} sx={{ maxWidth: 500 }} alignItems="center" spacing={2}>
      {
        !isRequestCompleted &&
        <TextField
          label="Indirizzo consegna"
          value={address}
          onChange={event => setAddress(event.target.value)}
          helperText={savedAddress ? "Usa l'indirizzo del tuo profilo" : ""}
          FormHelperTextProps={{
            sx: {
              color: 'primary.main',
              cursor: 'pointer'
            },
            onClick: () => setAddress(savedAddress)
          }}
          disabled={isLoading}
          fullWidth
        />
      }
      {
        !isRequestCompleted &&
        <TextField
          label="Note"
          value={note}
          multiline
          rows={4}
          onChange={event => setNote(event.target.value)}
          placeholder="Inserisci qui eventuali dettagli aggiuntivi"
          FormHelperTextProps={{
            sx: {
              color: 'primary.main',
              cursor: 'pointer'
            }
          }}
          inputProps={{ maxLength: 1000 }}
          disabled={isLoading}
          fullWidth
        />
      }
      {
        isRequestCompleted && isRequestSuccess &&
        <CheckCircle sx={{fontSize: '8rem', mb: 4}} color="success" />
      }
      {
        isRequestCompleted && isRequestSuccess &&
        <Typography>
          Il tuo ordine è stato inviato.
        </Typography>
      }
      {
        isRequestCompleted && !isRequestSuccess &&
        <Cancel sx={{fontSize: '8rem', mb: 4}} color="error" />
      }
      {
        isRequestCompleted && !isRequestSuccess &&
        <Typography>
          {error && parseMessageFromError(error)}
        </Typography>
      }
    </Stack>
    <Stack
      alignItems="center"
      spacing={2}
      sx={{ position: 'fixed', bottom: theme.spacing(2) }}
    >
      {
        !isRequestCompleted &&
        <>
          <Button
            variant="contained"
            onClick={confirmAddress}
            disabled={isLoading}
          >
            Conferma ordine
          </Button>
          <Button
            variant="text"
            color="error"
            onClick={cancel}
            disabled={isLoading}
          >
            Annulla
          </Button>
        </>
      }
      {
        isRequestCompleted &&
        <Button
          variant="contained"
          onClick={backAfterRequest}
          disabled={isLoading}
        >
          Torna indietro
        </Button>
      }
    </Stack>
  </PageLayout>
}