import { List } from "@mui/material";
import React, {useEffect} from "react";
import { ExtendedProduct } from "../features/remote/products/ProductsAPI";
import {getCart, getLoadedCart} from "../features/remote/cart/CartSlice";
import ProductRow from "./ProductRow";
import {useAppDispatch, useAppSelector} from "../app/hooks";

type ProductsListProps = { products: ExtendedProduct[] };

export default function ProductsList({ products }: ProductsListProps){

  const cart = useAppSelector(getLoadedCart);
  const dispatch = useAppDispatch();

  useEffect(
      () => {
        dispatch(getCart({page: 0, length: 50}));
      },
      [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <List sx={{ width: 1 }}>
  {products.map(
    product => <ProductRow
      key={product.id}
      element={(product as ExtendedProduct)}
      cartEntry={cart?.data.find(entry => entry.product.id === product.id)}
    />
  )}
</List>
}