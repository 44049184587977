import { Badge, Box, Fab, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { getCart, getLoadedCart } from "../features/remote/cart/CartSlice";
import CartIcon from "../resources/icons/cart.svg";

const StyledFab = styled(Fab)({
  position: 'fixed',
  zIndex: 1,
  left: 'auto',
  right: 32,
  bottom: 16
});

const StyledBadged = styled(Badge)({
  '.MuiBadge-badge': {
    top: -8,
    right: -8
  }
});

export default function ShopFooter(){

  const cart = useAppSelector(getLoadedCart);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(
    () => {
      dispatch(getCart({page: 0, length: 1}));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function goToCart(){
    navigate('/carrello');
  }

  return <Box sx={{ minHeight: 62 }}>
    {
      cart && cart.count > 0 &&
      <StyledFab color="secondary" onClick={goToCart}>
        <StyledBadged color="primary" badgeContent={cart.count}>
          <img
            src={CartIcon} alt="cart" width={24}
          />
        </StyledBadged>
      </StyledFab>
    }
  </Box>
}