import React, { useState, useEffect, SyntheticEvent } from 'react';
import { MenuItem, Select, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, SxProps } from "@mui/material";
import { PurchaseEventRequest } from '../features/remote/events/commercialEvents/PurchaseEventsAPI';
import { addPurchaseEvent } from '../features/remote/events/commercialEvents/PurchaseEventsActions';
import { useAppDispatch } from '../app/hooks';
import { NumberTextField } from './NumberTextField';

type ComponentProps = {
  productId?: number;
  open: boolean;
  handleClose: () => any;
  sx: SxProps & {
    zIndex?: number
  };
}

export function PurchaseDialog({ productId, open, handleClose, sx }: ComponentProps){

  const [cost, setCost] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('Kg');
  const dispatch = useAppDispatch();

  function addPurchase(event: SyntheticEvent){
    event.preventDefault();
    if(productId){
      const purchase = new PurchaseEventRequest({
        quantita: quantity,
        costo: cost,
        unita: unit,
        prodotto: productId
      });
      dispatch(addPurchaseEvent(purchase));
      handleClose();
    }
  }

  useEffect(
    () => {
      if(!open){
        setCost('');
        setQuantity('');
        setUnit('Kg');
      }
    },
    [open]
  );

  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
    sx={sx}
  >
    <DialogTitle>Aggiungi acquisto</DialogTitle>
    <form onSubmit={addPurchase}>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NumberTextField
              fullWidth
              value={cost}
              onChange={event => setCost(event.target.value)}
              label="Costo"
              inputProps={{
                min: 0,
                step: 0.01
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <NumberTextField
              fullWidth
              value={quantity}
              onChange={event => setQuantity(event.target.value)}
              label="Quantità"
              inputProps={{
                min: 0,
                step: 0.01
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              value={unit}
              onChange={event => setUnit(event.target.value)}
              fullWidth
              MenuProps={{
                style: {zIndex: sx?.zIndex}
              }}
            >
              <MenuItem value="Kg">Kg</MenuItem>
              <MenuItem value="Collo">Collo</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: 1 }}
        >
          <Button onClick={handleClose} color="error">
            Annulla
          </Button>
          <Button type="submit">
            Aggiungi
          </Button>
        </Stack>
      </DialogActions>
    </form>
  </Dialog>
}