import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { ProductRequest } from "../features/remote/products/ProductsAPI";
import { addProduct } from "../features/remote/products/ProductsSlice";
import ProductForm, { ProductData } from "./ProductForm";

type NewProductDialogProps = {
  open: boolean;
  handleClose: () => any;
}

export default function NewProductDialog({ open, handleClose }: NewProductDialogProps){

  const [productData, setProductData] = useState<ProductData>({});
  const dispatch = useAppDispatch();

  function createNew(event: SyntheticEvent){
    event.preventDefault();
    if(productData.name){
      const prices = [];
      if(productData.kgPrice)
        prices.push({
          valore: productData.kgPrice,
          misura: 'Kg'
        });
      if(productData.packPrice)
        prices.push({
          valore: productData.packPrice,
          misura: 'Collo',
          descrizioneUnita: productData.packDescription
        });
      const product = new ProductRequest({
        immagine: productData.image,
        nome: productData.name,
        codice: productData.code,
        provenienza: productData.origin,
        categoriaCommerciale: productData.commercialCategory,
        categoria: productData.category?.toApiObject(),
        prezzi: prices
      });
      dispatch(addProduct(product));
      handleClose();
    }
  }

  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
  >
    <form onSubmit={createNew}>
      <DialogTitle>Nuovo Prodotto</DialogTitle>
      <DialogContent dividers>
          <ProductForm
            onDataChange={setProductData}
          />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: 1 }}
        >
          <Button onClick={handleClose} color="error">
            Chiudi
          </Button>
          <Button type="submit">
            Crea
          </Button>
        </Stack>
      </DialogActions>
    </form>
  </Dialog>
}