import React, { useEffect, useState } from 'react';
import { DataGrid, GridColumns, GridRowId } from '@mui/x-data-grid';
import PageLayout from '../layout/PageLayout';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useSnackbar } from 'notistack';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import NewProductDialog from '../components/NewProductDialog';
import { disableProduct, enableProduct, getError, getExtendedProducts, getLoadedProducts, isLoading } from '../features/remote/products/ProductsSlice';
import EditProductDialog from '../components/EditProductDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import { ProductState } from '../features/remote/products/ProductsAPI';
import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { useConfig } from '../features/remote/config/Config';
import { Price } from '../features/remote/prices/PricesAPI';

const itTranslation = require('../features/datagrid/locale/it');

const PRODUCTS_PER_PAGE =
  process.env.REACT_APP_PRODUCTS_PER_PAGE ?
  parseInt(process.env.REACT_APP_PRODUCTS_PER_PAGE) :
  10;

const columns: GridColumns = [
  {
    field: 'name', headerName: 'Nome', flex: 0.75, minWidth: 150,
  },
  {
    field: 'prices', headerName: 'Prezzo', flex: 0.75, minWidth: 150,
    headerAlign: 'center', renderCell:
      (params) => <Stack
        direction="column" justifyContent="stretch"
        sx={{width: 1}}
      >
        {
          params.row.prices?.map(
            (price: Price) => <Typography align="center" key={price.measure.name}>
              {price.textualValue} &euro;/{price.measure.name}
            </Typography>
          )
        }
      </Stack>
  },
  {
    field: 'stock', headerName: 'Giacenza', flex: 0.75, minWidth: 150,
    headerAlign: 'center', renderCell:
      (params) => <Stack
        direction="column" justifyContent="center" alignItems="stretch"
        sx={{width: 1, height: 1, overflow: 'auto'}}
      >
        {
          [...(params.row.statistics?.keys() || [])]
          .map(
            unitWithParts => {
              const stats = params.row.statistics.get(unitWithParts);
              return stats.available > 0 ? <Typography align="center" key={unitWithParts}>
                {stats.stockDescription}
              </Typography> : null
            }
          ).filter(elements => elements !== null)
        }
      </Stack>
  },
  {
    field: 'state', headerName: 'Abilitato', flex: 0.5, minWidth: 100,
    sortable: false, align: 'center', headerAlign: 'center',
    renderCell:
      (params) =>
        params.row.state === ProductState.ENABLED ?
        <CheckCircleRounded color="success" /> :
        <CancelRounded color="error" />
  }
];

export default function ProductList(){

  const products = useAppSelector(getLoadedProducts);
  const productsIsLoading = useAppSelector(isLoading);
  const apiError = useAppSelector(getError);
  const [openedProduct, setOpenedProduct] = useState<number | undefined>();
  const [selectedProducts, setSelectedProducts] = useState<GridRowId[]>([]);
  const [page, setPage] = useState(0);
  const [openNewProductDialog, setOpenNewProductDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  
  useEffect(
    () => {
      if(!openedProduct){
        dispatch(getExtendedProducts({page, length: PRODUCTS_PER_PAGE, sort: "ASC"}));
      }
    },
    [page, dispatch, openedProduct]
  );

  useEffect(
    () => {
      if(apiError)
        enqueueSnackbar(parseMessageFromError(apiError), {variant: 'error'});
    },
    [apiError] // eslint-disable-line react-hooks/exhaustive-deps
  )

  function showProduct({ id }: { id: GridRowId }){
    setOpenedProduct(id.valueOf() as number);
  }

  async function enableSelected(){
    if(selectedProducts)
      for(const id of selectedProducts){
        const parsedId = id.valueOf() as number;
        await dispatch(enableProduct({ id: parsedId })).then(unwrapResult).catch(() => {});
      }
  }
  async function disableSelected(){
    if(selectedProducts)
      for(const id of selectedProducts){
        const parsedId = id.valueOf() as number;
        await dispatch(disableProduct({ id: parsedId })).then(unwrapResult).catch(() => {});
      }
  }

  return <PageLayout pageTitle="Lista prodotti">
    <NewProductDialog
      open={openNewProductDialog}
      handleClose={() => setOpenNewProductDialog(false)}
    />
    <EditProductDialog
      open={!!openedProduct}
      productId={openedProduct}
      handleClose={() => setOpenedProduct(undefined)}
    />
    <Grid
      container
      spacing={2}
      sx={{
        mb: 3,
        width: 7/8
      }}
    >
      <Grid item xs={12} sm={2}>
        <Button
          color="success"
          variant="contained"
          sx={{[theme.breakpoints.down('sm')]: { width: 1 }}}
          onClick={enableSelected}
        >
          Abilita
        </Button>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button
          color="error"
          variant="contained"
          sx={{[theme.breakpoints.down('sm')]: { width: 1 }}}
          onClick={disableSelected}
        >
          Disabilita
        </Button>
      </Grid>
      <Grid
        item xs={12} sm={8}
        container justifyContent="flex-end"
      >
        <Button
          variant="contained"
          onClick={() => setOpenNewProductDialog(true)}
          sx={{[theme.breakpoints.down('sm')]: { width: 1 }}}
        >
          Nuovo prodotto
        </Button>
      </Grid>
    </Grid>
    <DataGrid
      loading={productsIsLoading}
      autoHeight={true}
      rows={products?.data || []}
      columns={columns}
      rowCount={products?.count || 0}
      autoPageSize={true}
      pageSize={PRODUCTS_PER_PAGE}
      pagination={true}
      paginationMode='server'
      onPageChange={setPage}
      onRowDoubleClick={showProduct}
      checkboxSelection
      error={apiError}
      keepNonExistentRowsSelected={true}
      selectionModel={selectedProducts}
      onSelectionModelChange={setSelectedProducts}
      disableSelectionOnClick
      rowHeight={72}
      sx={{
        width: '100%',
        border: 'none',
        color: 'black1.main'
      }}
      localeText={itTranslation}
    />
  </PageLayout>
}