import { ArrowBackIos } from '@mui/icons-material';
import { Paper, Typography, IconButton, Slide, PaperProps, BoxProps, SxProps } from '@mui/material';
import { useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import React from "react";

type PageLayoutProps = {
  children?: React.ReactNode;
  sx?: BoxProps;
  header?: any;
  pageTitle?: string;
  PaperProps?: PaperProps;
}

export default function PageLayout(
  { children, header, pageTitle, sx, PaperProps }: PageLayoutProps
){

  const navigate = useNavigate();
  const theme = useTheme();

  const navigationBarStyle = {
    py: 3,
    display: 'flex',
    alignItems: 'center'
  };
  const paperStyle: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderTopLeftRadius: '2.5rem',
    borderTopRightRadius: '2.5rem',
    overflowX: 'auto',
    flex: 1,
    p: 4,
    zIndex: 0,
    position: 'relative',
    minHeight: "fill-available",
    "& .MuiDataGrid-overlay": {
      zIndex: 1
    }
  };

  return <Layout
    mainProperties={{
      sx: {
        display: 'flex',
        flexFlow: 'column',
        minHeight: `calc(100vh - ${theme.spacing(1)})`
      }
    }}
  >
      { header }
      {
        pageTitle &&
        <Slide direction="right" in mountOnEnter unmountOnExit timeout={300}>
          <Typography
            color='white1.main' sx={navigationBarStyle} variant="h6" component="div"
          >
            <IconButton
              color='white1'
              sx={{ mr: 1 }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIos />
            </IconButton>
            <span style={{zIndex: 0}}>{pageTitle}</span>
          </Typography>
        </Slide>
      }
    <Slide direction="up" in mountOnEnter unmountOnExit timeout={300}>
      <Paper
        sx={{...paperStyle, ...sx}}
        {...PaperProps}
      >
        { children }
      </Paper>
    </Slide>
  </Layout>
}