import { createAsyncThunk } from '@reduxjs/toolkit';
import { addPurchase as addPurchaseApi, getPurchases as getPurchasesApi, PurchaseEventRequest } from './PurchaseEventsAPI';

interface PurchaseEventsFilterOptions{
  from: Date;
  to: Date;
  product?: number;
}
export const getPurchaseEvents = createAsyncThunk(
  'remote/commercialEvents/purchase/get_all',
  async (
    { from, to, product }: PurchaseEventsFilterOptions,
    { rejectWithValue }
  ) => {
    try{
      const response = await getPurchasesApi({from, to, product});
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const addPurchaseEvent = createAsyncThunk(
  'remote/commercialEvents/purchase/add',
  async (event: PurchaseEventRequest, { rejectWithValue }) => {
    try{
      const response = await addPurchaseApi(event);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);