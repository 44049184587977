import { get, put } from "../../connector/ConnectorAPI";
import { ApiCommercialEvent, ApiCommercialEventRequest, CommercialEvent, CommercialEventRequest } from "./CommercialEventsAPI";

interface PurchaseEventsFilterOptions{
    from: Date;
    to: Date;
    product?: number;
}
export async function getPurchases({from, to, product}: PurchaseEventsFilterOptions):
    Promise<Array<PurchaseEvent>>
{
    const filters: any = {from: from.getTime(), to: to.getTime()};
    if(product)
        filters.product = product;
    const response: Array<ApiPurchaseEvent> = await get(
        '/eventiAcquisto', filters
    );
    return response.map(
        apiPurchase => new PurchaseEvent(apiPurchase)
    );
}

export async function addPurchase(event: PurchaseEventRequest){
    const response: ApiPurchaseEvent = await put('/eventoAcquisto', event.toApiObject());
    return new PurchaseEvent(response);
}

export interface ApiPurchaseEventRequest extends ApiCommercialEventRequest{
    prodotto: number;
}

export interface ApiPurchaseEvent extends ApiCommercialEvent{
    prodotto: number;
}

export class PurchaseEventRequest extends CommercialEventRequest{
    product: number;
    
    constructor(event: ApiPurchaseEventRequest){
        super(event);
        this.product = event.prodotto;
    }
    toApiObject(): ApiPurchaseEventRequest {
        const apiObject = super.toApiObject();
        return {
            ...apiObject,
            prodotto: this.product
        }
    }
}

export class PurchaseEvent extends CommercialEvent{
    product: number;
    
    constructor(event: ApiPurchaseEvent){
        super(event);
        this.product = event.prodotto;
    }

    public get stockImpact(): string{
        return '+';
    }

    toApiObject(): ApiPurchaseEvent {
        const apiObject = super.toApiObject();
        return {
            ...apiObject,
            prodotto: this.product
        }
    }
}