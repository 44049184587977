import { Clear } from "@mui/icons-material";
import { useTheme } from '@mui/system';
import { Box, Stack, Pagination, Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography, Button, Skeleton } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { editCartEntry, getCart, getLoadedCart, isLoading } from "../features/remote/cart/CartSlice";
import { useConfig } from "../features/remote/config/Config";
import PageLayout from "../layout/PageLayout";
import ProductPlaceholder from "../resources/product_placeholder.png"
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { CartEntry } from "../features/remote/cart/CartAPI";

const ENTRIES_PER_PAGE =
  process.env.REACT_APP_ENTRIES_PER_PAGE ?
  parseInt(process.env.REACT_APP_ENTRIES_PER_PAGE) :
  10;

export default function Cart(){

  const cart = useAppSelector(getLoadedCart);
  const loading = useAppSelector(isLoading);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { parseMessageFromError } = useConfig();
  const navigate = useNavigate();

  useEffect(
    () => {
      dispatch(getCart({page, length: ENTRIES_PER_PAGE}))
    },
    [page] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function removeEntry(entry: CartEntry){
    entry.textualQuantity = '0';
    dispatch(editCartEntry(entry))
    .then(unwrapResult)
    .catch(
      (error) => enqueueSnackbar(
        parseMessageFromError(error), { variant: 'error' }
      )
    );
  }

  function confirmCart(){
    navigate('conferma');
  }

  return <PageLayout pageTitle="Carrello">
    <Stack direction="column" alignItems="center" width={1}>
      <List
        sx={{
          width: '100%', maxWidth: 500, bgcolor: 'background.paper', mb: 8
        }}
      >
        {
          cart && cart.count > 0 && !loading &&
          cart.data.map(
            entry => <Box key={entry.id}>
              <ListItem
                sx={{ pl: 0 }}
                alignItems="flex-start"
                secondaryAction={
                  <IconButton
                    onClick={() => removeEntry(entry)}
                    color="error"
                    disabled={loading}
                  >
                    <Clear />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar
                    alt={entry.product.name}
                    src={entry.product.image || ProductPlaceholder}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={entry.product.name}
                  secondary={`${entry.textualQuantity} ${entry.unit.toHumanReadableString()}`}
                />
              </ListItem>
              <Divider component="li" />
            </Box>
          )
        }
        {
          (!cart || loading) &&
          [180, 160, 203, 112].map(
            (textWidth, i) => <ListItem
              key={i}
              sx={{ pl: 0 }}
              alignItems="flex-start"
              secondaryAction={
                <Skeleton variant="circular" width={40} height={40} />
              }
            >
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Skeleton variant="text" width={textWidth} />
                }
                secondary={
                  <Skeleton variant="text" width={textWidth - 80} />
                }
              />
            </ListItem>
          )
        }
      </List>
      {
        cart && cart.count > 0 && !loading &&
        <Stack
          alignItems="center"
          spacing={2}
          sx={{ position: 'fixed', bottom: theme.spacing(2) }}
        >
          <Pagination
            count={Math.ceil(cart.count / ENTRIES_PER_PAGE)}
            color="primary"
            page={page + 1}
            onChange={(_, value) => setPage(value - 1)}
          />
          <Button
            variant="contained"
            onClick={confirmCart}
          >
            Conferma carrello
          </Button>
        </Stack>
      }
      {
        cart && cart.count <= 0 && !loading &&
        <Typography width={1} align="center">
          Il carrello è vuoto
        </Typography>
      }
    </Stack>
  </PageLayout>
}