import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../../utils';
import { PagedResponse } from '../../connector/ConnectorAPI';
import { getCommercialEvents as getCommercialEventsApi, deleteCommercialEvent as deleteCommercialEventApi, CommercialEvent } from './CommercialEventsAPI';
import { addPurchaseEvent, getPurchaseEvents } from './PurchaseEventsActions';
import { PurchaseEvent } from './PurchaseEventsAPI';
import { addReversedEvent } from './ReversedEventsActions';
import { ReversedEvent } from './ReversedEventsAPI';
import { addSaleEvent, editSaleEvent, getSaleEvent, getSaleEvents } from './SaleEventsActions';
import { SaleEvent } from './SaleEventsAPI';

interface CommercialEventsState{
  status: 'idle' | 'loading',
  error: Error | null,
  loadedSaleEvent: SaleEvent | null,
  loadedSaleEvents: SaleEvent[],
  loadedReversedEvent: ReversedEvent | null,
  loadedPurchaseEvent: PurchaseEvent | null,
  loadedPurchaseEvents: PurchaseEvent[],
  loadedEvents: PagedResponse<CommercialEvent>
}
const initialState = {
  status: 'idle',
  error: null,
  loadedSaleEvent: null,
  loadedSaleEvents: [],
  loadedReversedEvent: null,
  loadedPurchaseEvent: null,
  loadedPurchaseEvents: [],
  loadedEvents: {
    count: 0,
    data: [],
    page: 0
  }
} as CommercialEventsState;

interface CommercialEventIdentifyOptions{
  id: number;
}
export const deleteCommercialEvent = createAsyncThunk(
  'remote/commercialEvents/del',
  async ({ id }: CommercialEventIdentifyOptions, { rejectWithValue }) => {
    try{
      await deleteCommercialEventApi(id);
      return { id };
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface FetchCommercialEventsOptions{
  page: number;
  length: number;
  product?: number;
}
export const getCommercialEvents = createAsyncThunk(
  'remote/commercialEvents/get_all',
  async (
    { page, length, product }: FetchCommercialEventsOptions,
    { rejectWithValue }
  ) => {
    try{
      const response = await getCommercialEventsApi(page, length, {product});
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const commercialEventsSlice = createSlice({
  name: 'remote/commercialEvents',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPurchaseEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedPurchaseEvent = action.payload;
        if(state.loadedEvents){
          state.loadedEvents.count++;
          state.loadedEvents.data.unshift(action.payload);
        }
      })
      .addCase(addReversedEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedReversedEvent = action.payload;
        if(state.loadedEvents){
          state.loadedEvents.count++;
          state.loadedEvents.data.unshift(action.payload);
        }
      })
      .addCase(addSaleEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedSaleEvent = action.payload;
        if(state.loadedEvents){
          state.loadedEvents.count++;
          state.loadedEvents.data.unshift(action.payload);
        }
      })
      .addCase(deleteCommercialEvent.fulfilled, (state, action) => {
        state.status = 'idle';

        if(state.loadedSaleEvent?.id === action.payload.id)
          state.loadedSaleEvent = null;
        if(state.loadedReversedEvent?.id === action.payload.id)
          state.loadedReversedEvent = null;
        if(state.loadedPurchaseEvent?.id === action.payload.id)
          state.loadedPurchaseEvent = null;

        if(state.loadedEvents){
          const filteredData = state.loadedEvents.data.filter(
            event => event.id !== action.payload.id
          )
          state.loadedEvents.count = filteredData.length;
          state.loadedEvents.data = filteredData;
        }
      })
      .addCase(getCommercialEvents.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedEvents = action.payload;
      })
      .addCase(getSaleEvents.fulfilled, (state, action) => {
        state.status = 'idle';
        const events = action.payload;
        state.loadedSaleEvents = events;
      })
      .addCase(getPurchaseEvents.fulfilled, (state, action) => {
        state.status = 'idle';
        const events = action.payload;
        state.loadedPurchaseEvents = events;
      })
      .addMatcher(
        isAnyOf(
          editSaleEvent.fulfilled,
          getSaleEvent.fulfilled
        ),
        (state, action) => {
          state.status = 'idle';
          const updatedEvent = action.payload;
          state.loadedSaleEvent = updatedEvent;
          if(state.loadedEvents){
            state.loadedEvents.data = state.loadedEvents.data.map(
              event => event.id === updatedEvent.id ? updatedEvent : event
            );
          }
        }
      )
      .addMatcher(isNamespacePending('remote/commercialEvents'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/commercialEvents'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getLoadedReversedEvent =
  (state: RootState) => state.remote.events.commercialEvents.loadedReversedEvent;
export const getLoadedPurchaseEvent =
  (state: RootState) => state.remote.events.commercialEvents.loadedPurchaseEvent;
export const getLoadedSaleEvent =
  (state: RootState) => state.remote.events.commercialEvents.loadedSaleEvent;
export const getLoadedSalesEvents =
  (state: RootState) => state.remote.events.commercialEvents.loadedSaleEvents;
export const getLoadedPurchaseEvents =
  (state: RootState) => state.remote.events.commercialEvents.loadedPurchaseEvents;
export const getLoadedEvents =
  (state: RootState) => state.remote.events.commercialEvents.loadedEvents;
export const isLoading =
  (state: RootState) => state.remote.events.commercialEvents.status === "loading";
export const getError =
  (state: RootState) => state.remote.events.commercialEvents.error;

export default commercialEventsSlice.reducer;