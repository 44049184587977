import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../utils';
import { getSettings as getSettingsApi, editSettings as editSettingsApi, Settings } from './SettingsAPI';

interface SettingsState{
  status: 'idle' | 'loading',
  error: Error | null,
  loadedSettings: Settings
}
const initialState = {
  status: 'idle',
  error: null,
  loadedSettings: new Settings()
} as SettingsState;

export const getSettings = createAsyncThunk(
  'remote/settings/get',
  async (_, { rejectWithValue }) => {
    try{
      const response = await getSettingsApi();
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const editSettings = createAsyncThunk(
  'remote/settings/edit',
  async (settings: Settings, { rejectWithValue }) => {
    try{
      const response = await editSettingsApi(settings);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const settingsSlice = createSlice({
  name: 'remote/settings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedSettings = action.payload;
      })
      .addCase(editSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedSettings = action.payload;
      })
      .addMatcher(isNamespacePending('remote/settings'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/settings'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getLoadedSettings = (state: RootState) => state.remote.settings.loadedSettings;
export const isLoading = (state: RootState) => state.remote.settings.status === "loading";
export const getError = (state: RootState) => state.remote.settings.error;

export default settingsSlice.reducer;
