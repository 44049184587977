import { combineReducers } from "@reduxjs/toolkit";
import orders from "./orders/OrdersSlice";
import products from "./products/ProductsSlice";
import config from "./config/ConfigSlice";
import events from "./events/eventsSlice";
import users from "./users/UsersSlice";
import settings from "./settings/SettingsSlice";
import cart from "./cart/CartSlice";

export default combineReducers({
    orders,
    products,
    config,
    settings,
    events,
    users,
    cart
});