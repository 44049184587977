import { Box, BoxProps, Slide } from "@mui/material";
import Layout from "./Layout";
import React from "react";

const pageStyle: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  p: 4,
  zIndex: 0
};

type AlternativePageLayoutProps = {
  children?: React.ReactNode;
  sx?: BoxProps;
}

export default function AlternativePageLayout(
  {children, sx = {}}: AlternativePageLayoutProps
){
  return <Layout>
    <Slide
      direction="up"
      in
      mountOnEnter
      unmountOnExit
      timeout={300}
    >
      <Box sx={{...pageStyle, ...sx}}>
        {children}
      </Box>
    </Slide>
  </Layout>
}