import { useAuth0 } from "@auth0/auth0-react";
import { Settings, Storefront } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Divider, List, ListItemButton, ListItemText, Paper, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import AlternativePageLayout from "../layout/AlternativePageLayout";
import SettingsDialog from "../components/SettingsDialog";
import React, { useState } from "react";

export default function AdminMenu(){

  const [openSettings, setOpenSettings] = useState(false);
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const theme = useTheme();

  function goToPage(page: string){
    navigate(page);
  }

  return <AlternativePageLayout
    sx={{justifyContent: "center", height: `calc(100vh - ${theme.spacing(1)})`}}
  >
    <SettingsDialog
      open={openSettings}
      handleClose={() => setOpenSettings(false)}
    />
    <IconButton
      sx={{
        color: 'white1.main',
        position: 'absolute',
        top: 16,
        right: 16
      }}
      size="large"
      onClick={() => setOpenSettings(true)}
    >
      <Settings />
    </IconButton>
    <IconButton
      sx={{
        color: 'white1.main',
        position: 'absolute',
        top: 16,
        left: 16
      }}
      size="large"
      onClick={() => {
        const path = window.location.pathname.split("/");
        path[path.length - 1] = "vetrina";
        window.open(`${window.location.origin}${path.join("/")}`, '_blank')?.focus()
      }}
    >
      <Storefront />
    </IconButton>
    <Grid container justifyContent="center" sx={{zIndex: 1}}>
      <Grid item xs={10} md={6} lg={4}>
        <Paper
          sx={{
            width: 1,
            borderRadius: '2rem',
            "& .MuiList-root": { borderRadius: '2rem' },
            "& .MuiListItemButton-root:first-of-type": {
              borderTopLeftRadius: '2rem',
              borderTopRightRadius: '2rem',
            },
            "& .MuiListItemButton-root:last-of-type": {
              borderBottomLeftRadius: '2rem',
              borderBottomRightRadius: '2rem',
            },
          }}
          elevation={6}
        >
          <List sx={{p: 0}}>
            <ListItemButton sx={{pt: 2}} onClick={() => goToPage('ordini')}>
              <ListItemText
                primary="Lista ordini"
                primaryTypographyProps={{
                  align: 'center', fontWeight: 'bold', variant: 'h6', color: 'black1.main'
                }}
              />
            </ListItemButton>
            <Divider variant="middle" />
            <ListItemButton onClick={() => goToPage('prodotti')}>
              <ListItemText
                primary="Lista articoli"
                primaryTypographyProps={{
                  align: 'center', fontWeight: 'bold', variant: 'h6', color: 'black1.main'
                }}
              />
            </ListItemButton>
            <Divider variant="middle" />
            <ListItemButton onClick={() => goToPage('utenti')}>
              <ListItemText
                primary="Utenti"
                primaryTypographyProps={{
                  align: 'center', fontWeight: 'bold', variant: 'h6', color: 'black1.main'
                }}
              />
            </ListItemButton>
            <Divider variant="middle" />
            <ListItemButton sx={{pb: 2}} onClick={() => goToPage('resoconti')}>
              <ListItemText
                primary="Resoconti"
                primaryTypographyProps={{
                  align: 'center', fontWeight: 'bold', variant: 'h6', color: 'black1.main'
                }}
              />
            </ListItemButton>
          </List>
        </Paper>
      </Grid>
    </Grid>
    <Button
      variant="text" color="white1" sx={{ mt: 2}}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Disconnetti
    </Button>
  </AlternativePageLayout>;
}
