import React from 'react';
import { Box, BoxProps, Container, CssBaseline, SxProps, useTheme } from '@mui/material';

const containerStyle = {
  pt: 1,
  bgcolor: 'primary.main',
  minHeight: '100%'
};

type LayoutProps = {
  children?: React.ReactNode;
  sx?: SxProps;
  mainProperties?: BoxProps;
}

export default function Layout({ children, sx, mainProperties }: LayoutProps){
  const theme = useTheme();
  return (
    <Container component="main" sx={{...containerStyle, ...sx}} maxWidth={false}>
      <CssBaseline />
      <Box
        sx={{
          position: 'fixed',
          height: '100vh',
          [theme.breakpoints.up('lg')]: {
            height: '100vw',
          },
          width: '100vw',
          left: 0, top: 0,
          opacity: 1,
          zIndex: 0
        }}
      >
        <svg
          viewBox="0 0 509.6893 562.37256"
          height='100%'
          width='100%'
          preserveAspectRatio="none"
        >
          <g transform="translate(276.71643,-522.68953)">
            <path
              transform="scale(0.26458333)"
              d="m -1045.8574,1975.5195 c 2.3279,1025.2463 0.2647,840.3907 0,0 z m 0,0 0.4746,2125.5025 c 0,0 662.12074,-181.9543 579.12009,-490.1111 -36.47685,-135.4277 -236.66617,-125.9352 192.42287,-555.0242 34.95973,-34.9597 55.19894,-58.9855 64.68554,-96.8672 2.72412,-10.8779 2.44512,-41.5345 -0.50195,-55.1484 -5.7716,-26.6618 -19.78957,-55.2311 -36.30078,-73.9863 -2.97034,-3.3741 -7.42539,-8.6708 -9.90039,-11.7715 -16.43596,-20.5916 -98.23031,-87.7772 -232.5,-190.9746 -7.15004,-5.4954 -17.725,-13.6485 -23.5,-18.1172 -5.77501,-4.4687 -19.27503,-14.8279 -30,-23.0215 -133.95674,-102.3391 -150.43606,-115.6991 -178.5332,-144.7363 -56.3664,-58.2523 -88.5116,-116.242 -95.92579,-173.0449 -1.96082,-15.0226 -1.97508,-40.2293 -0.0273,-53.1993 5.23381,-34.8833 20.71084,-70.3366 44.74414,-102.5 138.19693,-170.0384 373.25855,-128.7876 579.35938,-136.3867 91.80198,0.4812 68.35192,1.7008 156.384761,5.9238 167.632399,16.9075 337.146269,58.2562 463.999999,114.9375 103.53547,46.2623 185.14922,101.6976 246.35742,167.336 57.94511,62.1392 102.36814,139.2623 136.61524,237.1797 28.48342,81.4381 60.66769,210.267 69.39062,309.0097 0,0 -0.18992,-240.6198 -0.29492,-517.1621 0.0204,6.4289 0.0431,12.7683 0.0644,19.1641 -0.0701,-108.8476 -0.13281,-218.7298 -0.13281,-337.002 H -578.03712 -597.9336 Z m 1926.13474,337.002 c 0.1045,162.2491 0.23047,321.5817 0.23047,497.998 0.007,0.018 0.0129,0.012 0.0195,0.024 v -425.1797 c -0.0854,-24.1609 -0.16868,-48.4324 -0.25,-72.8418 z m 0.23047,497.998 c -0.1015,-0.2391 -0.0587,0.5474 0.0195,1.5176 v -1.2715 c -0.0123,-0.1409 -0.006,-0.096 -0.0195,-0.2461 z"
              style={{
                opacity: 1,
                fill: theme.palette.primary.dark,
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
                paintOrder: 'normal'
              }}
            />
          </g>
        </svg>
      </Box>
      <Box width={1} minHeight={1} zIndex={0} {...mainProperties}>{ children }</Box>
    </Container>
  );
}