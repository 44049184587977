import { Grid, Typography, Stack, Button, Skeleton, useTheme, IconButton, Alert } from '@mui/material';
import { DataGrid, GridRowEntry } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { deleteCommercialEvent, getCommercialEvents, getError as getErrorOnEvents, getLoadedEvents, isLoading as isLoadingEvent } from '../features/remote/events/commercialEvents/CommercialEventsSlice';
import { getError as getErrorOnProduct, getLoadedProductStatistics, getProductStatistics, isLoading as isLoadingProduct } from '../features/remote/products/ProductsSlice';
import { PurchaseDialog } from './PurchaseDialog';
import { ReversedDialog } from './ReversedDialog';
import { Delete } from '@mui/icons-material';
import { useConfig } from '../features/remote/config/Config';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { CommercialEvent } from '../features/remote/events/commercialEvents/CommercialEventsAPI';

const itTranslation = require('../features/datagrid/locale/it');

const EVENTS_PER_PAGE = 5;

const columns = (deleteCallback: (id: number) => any): any[] => [
  {
    field: 'timestamp', headerName: 'Data', flex: 0.3, minWidth: 115,
    valueFormatter: (params: {value: Date}) => params.value.toLocaleDateString()
  },
  {
    field: 'detail', headerName: 'Dettaglio', flex: 0.75, minWidth: 150,
    valueGetter: (params: {row: CommercialEvent}) => params.row.stockDiffMessage,
  },
  {
    field: 'textualTotPrice', headerName: 'Costo', flex: 0.5, minWidth: 100,
    headerAlign: 'center', align: 'center',
    valueFormatter: (params: { value: string }) => `${params.value} €`
  },
  {
    field: 'operations', headerName: '', flex: 0.25, minWidth: 50,
    align: 'center', resizable: false, filterable: false, renderCell:
      (
        { row }: {row: GridRowEntry}
      ) => <IconButton
        color="error" onClick={() => deleteCallback(row.id.valueOf() as number)}
      >
        <Delete />
      </IconButton>
  }
];

type StockPanelProps = {
  productId?: number;
}

export default function StockPanel({ productId }: StockPanelProps){

  const statistics = useAppSelector(getLoadedProductStatistics);
  const events = useAppSelector(getLoadedEvents);
  const isLoadingStatistics = useAppSelector(isLoadingProduct);
  const isLoadingEvents = useAppSelector(isLoadingEvent);
  const errorOnProduct = useAppSelector(getErrorOnProduct);
  const errorOnEvents = useAppSelector(getErrorOnEvents);
  const error = errorOnProduct || errorOnEvents;
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
  const [openReversedDialog, setOpenReversedDialog] = useState(false);
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const { parseMessageFromError } = useConfig();
  const theme = useTheme();

  const colloStockStats =
    statistics
    .filter(
      (value) =>
        value.unit.name === "Collo" &&
        (value.available.greaterThan(0))
    ).map(
      (value) => {
        let stockDescription = value.textualAvailable;
        const description = value.unit.description?.substring(6);
        if(description)
          stockDescription += ` (${description})`;
        return { 
          unit: value.unit,
          text: stockDescription
        }
      }
    );

  const kgStats = statistics.find((value) => value.unit.name === "Kg");

  useEffect(
    () => {
      if(productId && events)
        dispatch(getProductStatistics({ id: productId }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productId, events]
  );

  useEffect(
    () => {
      if(productId){
        dispatch(getCommercialEvents({ product: productId, page, length: EVENTS_PER_PAGE }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productId, page]
  );

  function deleteEvent(id: number){
    dispatch(deleteCommercialEvent({ id }));
  }

  return <Grid
    container
    direction="column"
    justifyContent="stretch"
    spacing={2}
  >
    <ReversedDialog
      open={openReversedDialog}
      handleClose={() => setOpenReversedDialog(false)}
      productId={productId}
      sx={{ zIndex: theme.zIndex.modal + 1 }}
    />
    <PurchaseDialog
      open={openPurchaseDialog}
      handleClose={() => setOpenPurchaseDialog(false)}
      productId={productId}
      sx={{ zIndex: theme.zIndex.modal + 1 }}
    />
    <Grid item>
      <Typography variant="h4">Giacenza</Typography>
    </Grid>
    <Grid item container alignItems="center">
      <Grid item xs={6}>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Typography variant="h4" color="black1.main">Kg</Typography>
          <Typography variant="h4" color="black3.main">
            {
              isLoadingStatistics ?
              <Skeleton variant="text" width={40} /> :
              (kgStats ? kgStats.textualAvailable : '0')
            }
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
          <Typography variant="h4" color="black1.main">Colli</Typography>
          {
            isLoadingStatistics ?
            <Typography variant="h4" color="black3.main">
              <Skeleton variant="text" width={40} />
            </Typography> :
            <Stack direction="column">
              {
                colloStockStats && colloStockStats.length > 0 ?
                colloStockStats.map(({unit, text}) =>
                  <Typography variant="h4" color="black3.main" key={unit.toString()}>
                    { text }
                  </Typography>
                ) :
                <Typography variant="h4" color="black3.main">0</Typography>
              }
            </Stack>
          }
        </Stack>
      </Grid>
    </Grid>
    {
      error &&
      <Grid item container>
        <Alert severity='error' sx={{width: 1}}>{parseMessageFromError(error)}</Alert>
      </Grid>
    }
    <Grid item container>
      <Grid item xs={6} container justifyContent="center">
        <Button variant="contained" onClick={() => setOpenPurchaseDialog(true)}>Acquisto</Button>
      </Grid>
      <Grid item xs={6} container justifyContent="center">
        <Button variant="contained" onClick={() => setOpenReversedDialog(true)}>Storno</Button>
      </Grid>
    </Grid>
    <Grid item height="45vh">
      <DataGrid
        columns={columns(deleteEvent)}
        rows={events?.data || []}
        rowCount={events?.count || 0}
        autoPageSize={true}
        pageSize={EVENTS_PER_PAGE}
        pagination={true}
        paginationMode='server'
        onPageChange={setPage}
        loading={isLoadingEvents}
        disableSelectionOnClick
        sx={{
          width: '100%',
          border: 'none',
          color: 'black1.main'
        }}
        localeText={itTranslation}
      />
    </Grid>
  </Grid>
}