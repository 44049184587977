import { Box, Button, Stack, Typography } from "@mui/material";
import { useConfig } from "../features/remote/config/Config";
import AlternativePageLayout from "../layout/AlternativePageLayout";
import React from "react";

type FatalErrorPageProps = {
    error: Error;
    customAction?: {
        action: () => any;
        label: string;
    }
}

export default function FatalErrorPage({ error, customAction }: FatalErrorPageProps){

    const { parseMessageFromError } = useConfig();

    return <AlternativePageLayout>
        <Box
            position='absolute'
            top={0}
            left={0}
            height='100vh'
            width='100vw'
            display='flex'
            justifyContent="center"
            alignItems="center"
        >
            <Stack direction="column" alignItems="center" spacing={2}>
                <Typography variant="h3" color="white1.main">Errore</Typography>
                <Typography color="white1.main">{parseMessageFromError(error)}</Typography>
                <Button color="white1" onClick={() => window.location.reload()}>
                    Aggiorna la pagina
                </Button>
                {
                    customAction &&
                    <Button color="white1" onClick={customAction.action}>
                        {customAction.label}
                    </Button>
                }
            </Stack>
        </Box>
    </AlternativePageLayout>
}