import { createAsyncThunk } from '@reduxjs/toolkit';
import { addReversed as addReversedApi, ReversedEventRequest } from './ReversedEventsAPI';

export const addReversedEvent = createAsyncThunk(
  'remote/commercialEvents/reversed/add',
  async (event: ReversedEventRequest, { rejectWithValue }) => {
    try{
      const response = await addReversedApi(event);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);