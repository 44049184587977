import { useAuth0 } from "@auth0/auth0-react";
import { AccountCircle, ArrowForward, Search } from "@mui/icons-material";
import { Alert, Grid, IconButton, Menu, MenuItem, Slide, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { getLoadedSettings, getSettings } from "../features/remote/settings/SettingsSlice";
import logoIcon from "../resources/icons/logo.svg";

type ShopHeaderProps = {
  onProductSearch: (query: string) => any;
}

export default function ShopHeader({ onProductSearch }: ShopHeaderProps){

  const [search, setSearch] = useState('');
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const accountButtonRef = useRef<HTMLButtonElement>(null);
  const { user, logout } = useAuth0();
  const settings = useAppSelector(getLoadedSettings);
  const welcomeMessage = settings?.welcomeMessage;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(
    () => {
      dispatch(getSettings());
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function commitSearch(){
    if(onProductSearch)
      onProductSearch(search);
    setSearch("");
  }

  return <Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={300}>
    <Grid
      container direction="column" sx={{ zIndex: 1, px: 3, py: 2, mt: 0 }}
      alignItems="stretch" spacing={2} justifyContent="flex-start"
    >
      <Grid item container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            component="div"
            color="white1.main"
            variant="h5"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& .user-name': {
                display: 'none',
                [theme.breakpoints.up('md')]:{
                  display: 'inline'
                }
              }
            }}
          >
            <img src={logoIcon} alt="lemon" style={{height: "1.5em", marginRight: 8}}/>
            Benvenuto<span className="user-name">&nbsp;{user?.given_name || user?.nickname}</span>
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            color="white1"
            onClick={() => setOpenAccountMenu(true)}
            ref={accountButtonRef}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={accountButtonRef.current}
            open={openAccountMenu}
            onClose={() => setOpenAccountMenu(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={() => navigate('/profilo')}
            >
              Il mio profilo
            </MenuItem>
            <MenuItem
              onClick={() => navigate('/ordini')}
            >
              I miei ordini
            </MenuItem>
            <MenuItem
              onClick={
                () => logout({ returnTo: window.location.origin })
              }
            >
              Disconnetti
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {
        welcomeMessage &&
        <Grid item sx={{ width: 1 }}>
          <Alert
            severity="info"
            sx={{
              width: 1, wordBreak: 'break-word', bgcolor: 'secondary.light',
              color: 'black1.main',
              '& .MuiAlert-icon': {
                color: 'primary.dark'
              }
            }}
          >
            <span dangerouslySetInnerHTML={{__html: welcomeMessage}}></span>
          </Alert>
        </Grid>
      }
      <Grid item container justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            placeholder="Cerca prodotto..."
            variant="outlined"
            sx={{
              borderRadius: '2rem'
            }}
            InputProps={{
              sx: {
                borderRadius: '2rem',
                bgcolor: 'background.paper',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0 !important'
                }
              },
              startAdornment: <Search />,
              endAdornment:
                search ?
                <IconButton onClick={commitSearch}>
                  <ArrowForward />
                </IconButton> :
                <></>
            }}
            onKeyDown={(event) => (event.key === 'Enter') && commitSearch()}
            onChange={event => setSearch(event.target.value)}
            value={search}
          />
        </Grid>
      </Grid>
    </Grid>
  </Slide>
}