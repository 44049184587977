import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../utils';
import { Config, getConfig as getConfigApi } from './ConfigAPI';

interface ConfigState{
  status: 'idle' | 'loading';
  error: Error | null;
  loadedConfig: Config | null;
}
const initialState = {
  status: 'idle',
  error: null,
  loadedConfig: null
} as ConfigState;

export const getConfig = createAsyncThunk(
  'remote/config/get',
  async (_, { rejectWithValue }) => {
    try{
      const response = await getConfigApi();
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const configSlice = createSlice({
  name: 'remote/config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfig.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedConfig = action.payload;
      })
      .addMatcher(isNamespacePending('remote/config'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/config'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getLoadedConfig = (state: RootState) => state.remote.config.loadedConfig;
export const isLoading = (state: RootState) => state.remote.config.status === "loading";
export const getError = (state: RootState) => state.remote.config.error;

export default configSlice.reducer;
