import { Add, Remove } from "@mui/icons-material";
import { Alert, Button, Dialog, DialogActions, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CartEntry, CartEntryRequest } from "../features/remote/cart/CartAPI";
import { addToCart, getLoadedCartEntry, getCartEntry, editCartEntry, isLoading } from "../features/remote/cart/CartSlice";
import { useSnackbar } from "notistack";
import { useConfig } from "../features/remote/config/Config";
import { unwrapResult } from "@reduxjs/toolkit";
import { Decimal } from "decimal.js";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { ProductStatistics } from "../features/remote/products/ProductsAPI";
import { NumberTextField } from "./NumberTextField";

type AddToCartDialogProps = {
  open: boolean;
  onClose: () => any;
  stock?: ProductStatistics;
}

export default function AddToCartDialog({ open, stock, onClose }: AddToCartDialogProps){
  const cartEntry = useAppSelector(getLoadedCartEntry);
  const loading = useAppSelector(isLoading);
  const [quantity, setQuantity] = useState<string>('1');
  const { enqueueSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();
  const loadedCartEntryBelongsToStockProduct = cartEntry?.product.id === stock?.product;

  useEffect(
    () => {
      if(!open)
        setQuantity('1');
    },
    [open]
  );
  useEffect(
    () => {
      if(stock)
        dispatch(getCartEntry({
          product: stock.product,
          unit: stock.unit
        }));
    },
    [stock] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(
    () => {
      if(cartEntry && loadedCartEntryBelongsToStockProduct)
        setQuantity(cartEntry.textualQuantity)
    },
    [cartEntry, loadedCartEntryBelongsToStockProduct]
  );

  function add(){
    if(stock){
      if(stock.unit.name === 'Kg'){
        setQuantity(
          quantity => new Decimal(quantity).plus(0.5).toFixed(stock.unit.decimals)
        );
      }else{
        setQuantity(
          quantity => new Decimal(quantity).plus(1).toFixed(stock.unit.decimals)
        );
      }
    }
  }
  function minus(){
    if(stock){
      let value;
      if(stock.unit.name === 'Kg'){
        value = new Decimal(quantity).minus(0.5);
      }else{
        value = new Decimal(quantity).minus(1);
      }
      if (value.comparedTo(0) < 0) {
        value = new Decimal(0);
      }
      setQuantity(value.toFixed(stock.unit.decimals));
    }
  }

  function confirm(){
    let cartUpdatePromise: Promise<CartEntry> | null = null;
    const quantityDecimal = new Decimal(quantity);
    if(cartEntry && loadedCartEntryBelongsToStockProduct){
      cartEntry.quantity = quantityDecimal;
      cartUpdatePromise = dispatch(editCartEntry(cartEntry)).then(unwrapResult);
    } else if(stock && quantityDecimal.comparedTo(0) > 0){
      const entry: CartEntryRequest = new CartEntryRequest({
        prodotto: stock.product,
        quantita: quantity,
        misura: stock.unit.name
      });
      cartUpdatePromise = dispatch(addToCart(entry)).then(unwrapResult);
    }

    cartUpdatePromise?.then(onClose)
    .catch(
      (error: Error) => enqueueSnackbar(
        parseMessageFromError(error),
        { variant: 'error' }
      )
    );
  }

  return <Dialog
    open={open} onClose={() => !loading && onClose()}
  >
    <form
      onSubmit={event => {
        event.preventDefault();
        confirm();
      }}
    >
      <DialogContent>
        {
          cartEntry && loadedCartEntryBelongsToStockProduct &&
          <Alert severity="info" color="warning" sx={{ mb: 2 }}>
            Il prodotto è già nel carrello
          </Alert>
        }
        <Stack direction="row" justifyContent="space-evenly" spacing={2}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={minus} disabled={loading}>
              <Remove />
            </IconButton>
          </Box>
          <NumberTextField
            InputProps={{
              endAdornment: <Typography>{stock?.unit.toHumanReadableString()}</Typography>
            }}
            inputProps={{
              min: 0,
              step: stock ? stock.unit.step.toFixed(2) : 0
            }}
            value={quantity}
            onChange={event => setQuantity(event.target.value)}
            disabled={loading}
          />
          <Box display="flex" alignItems="center">
            <IconButton onClick={add} disabled={loading}>
              <Add />
            </IconButton>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" width={1}>
          <Button onClick={onClose} color="error" disabled={loading}>
            Annulla
          </Button>
          <Button
            type="submit"
            color="success"
            disabled={
              loading || !quantity
            }
          >
            {
              !cartEntry ?
              'Aggiungi' :
              'Aggiorna'
            }
          </Button>
        </Stack>
      </DialogActions>
    </form>
  </Dialog>
}