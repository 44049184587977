import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import rootReducer from '../features/rootSlice';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
      const middlewareChain = getDefaultMiddleware({
        serializableCheck: false,
      });
      if(process.env.NODE_ENV === "development")
        middlewareChain.push(logger);
      return middlewareChain;
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);