import { ConvertibleToApiData, EditableApiConcretization, get, PagedResponse, post, put, remapTypeInPagedResponse } from '../connector/ConnectorAPI';
import { ApiPrice, ApiPriceRequest, Price, SimplePriceRequest } from '../prices/PricesAPI';
import { Decimal } from 'decimal.js';
import { QuantityUnits, ShoppedUnit } from '../helpers/types';

export enum ProductState{
    ENABLED = "Abilitato",
    DISABLED = "Disabilitato"
}

export enum ProductCommercialCategory{
    CAT1 = "cat. 1",
    CAT2 = "cat. 2",
    EXTRA = "extra"
}

export async function addProduct(product: ProductRequest): Promise<Product> {
    const response: ApiProduct = await put('/prodotto', product.toApiObject());
    return new Product(response);
}

export async function editProduct(product: Product): Promise<Product> {
    const response: ApiProduct = await post(
        `/prodotto/${product.id}`, product.toEditedApiObject()
    );
    return new Product(response);
}

export async function enableProduct(id: number): Promise<Product> {
    const response: ApiProduct = await post(
        `/prodotto/${id}`,
        {stato: ProductState.ENABLED}
    );
    return new Product(response);
}

export async function disableProduct(id: number): Promise<Product> {
    const response: ApiProduct = await post(
        `/prodotto/${id}`,
        {stato: ProductState.DISABLED}
    );
    return new Product(response);
}

export async function getProduct(id: number): Promise<Product> {
    const response: ApiProduct = await get(`/prodotto/${id}`);
    return new Product(response);
}

export async function getProductStatistics(id: number):
    Promise<ProductStatistics[]>
{
    const response: Array<ApiProductStatistics> = await get(`/prodotto/${id}/statistiche`);
    return response.map(
        stats => new ProductStatistics(stats)
    );
}

interface ProductFilterOptions{
    sort?: "ASC" | "DESC";
    name?: string;
    categories?: number[]
}

export async function getProducts(
    page: number, length: number, filters?: ProductFilterOptions
): Promise<PagedResponse<Product>>{
    let query = {page, length};
    if(filters){
        query = {
            ...query,
            ...filters
        };
    }
    const response: PagedResponse<ApiProduct> = await get('/prodotti', query);
    const parsedResponse: PagedResponse<Product> =
        remapTypeInPagedResponse<ApiProduct, Product>(
            response, element => new Product(element)
        );
    return parsedResponse;
}

export async function getProductsStatistics(
    page: number, length: number, filters?: ProductFilterOptions
): Promise<PagedResponse<ApiProductStatisticsContainer>>{
    let query = {page, length};
    if(filters){
        query = {
            ...query,
            ...filters
        };
    }
    const response: PagedResponse<ApiProductStatisticsContainer> =
        await get('/prodotti/statistiche', query);
    return response;
}

export async function getExtendedProducts(
    page: number, length: number, filters?: ProductFilterOptions
): Promise<PagedResponse<ExtendedProduct>>{
    const pagedProducts = await getProducts(page, length, filters);
    const products = pagedProducts.data;
    const pagedStats = await getProductsStatistics(page, length, filters);
    const statsContainers = pagedStats.data;
    const extendedProducts = [];
    for(let i = 0; i < products.length; i++){
        const product = products[i];
        const statsContainer = statsContainers[i];

        extendedProducts.push(
            new ExtendedProduct(
                product.toApiObject(),
                statsContainer.statistiche.map(
                    stats => ({...stats, prodottoId: product.id})
                )
            )
        )
    }

    return {
        count: pagedProducts.count,
        data: extendedProducts,
        page
    };
}

interface CategoryFilterOptions{
    name?: string;
}

export async function getCategories(filters?: CategoryFilterOptions): Promise<Category[]> {
    const query = filters || {};
    const response: ApiCategory[] = await get('/categorie', query);
    const parsedResponse: Category[] = response.map(element => new Category(element));
    return parsedResponse;
}

export interface ApiProductRequest{
    immagine?: string;
    nome: string;
    codice?: string;
    provenienza?: string;
    categoriaCommerciale?: string;
    categoria?: ApiCategoryRequest;
    stato?: ProductState;
    prezzi: Array<ApiPriceRequest>;
}
export interface ApiProduct extends ApiProductRequest{
    id: number;
    prezzi: Array<ApiPrice>;
    categoria?: ApiCategory;
}
export interface ApiProductStatistics{
    unita: string;
    descrizioneUnita?: string;
    quantitaAcquistata: string;
    quantitaStornata: string;
    quantitaVenduta: string;
    quantitaDisponibile: string;
    prodottoId: number;
}
export interface ApiProductStatisticsContainer{
    prodottoId: number;
    statistiche: Array<ApiProductStatistics>;
}
export interface ApiCategoryRequest{
    nome: string;
}
export interface ApiCategory extends ApiCategoryRequest{
    id: number;
}

export class ProductRequest implements ConvertibleToApiData<ApiProductRequest>{
    image?: string;
    name: string;
    code?: string;
    origin?: string;
    commercialCategory?: string;
    category?: CategoryRequest;
    state: ProductState;
    prices: SimplePriceRequest[]

    constructor(product: ApiProductRequest){
        this.image = product.immagine;
        this.name = product.nome;
        this.code = product.codice;
        this.origin = product.provenienza;
        this.commercialCategory = product.categoriaCommerciale;
        this.category = product.categoria ?
            new CategoryRequest(product.categoria) :
            undefined;
        this.state = product.stato || ProductState.DISABLED;
        this.prices = (product.prezzi || []).map(
            apiElement => new SimplePriceRequest(apiElement)
        );
    }

    toApiObject(): ApiProductRequest{
        return {
            immagine: this.image,
            nome: this.name,
            codice: this.code,
            categoria: this.category ?
                this.category.toApiObject():
                undefined,
            stato: this.state,
            prezzi: (this.prices || []).map(
                price => price.toApiObject()
            ),
            provenienza: this.origin,
            categoriaCommerciale: this.commercialCategory
        }
    }
}
export class Product extends ProductRequest implements EditableApiConcretization{
    id: number;
    category?: Category;
    prices: Price[]
    private originalData: ApiProduct;

    constructor(product: ApiProduct){
        super(product);
        this.originalData = product;
        this.id = product.id;
        this.category = product.categoria ?
            new Category(product.categoria) :
            undefined;
        this.prices = (product.prezzi || []).map(
            apiElement => new Price(apiElement, product.id)
        );
    }

    toApiObject(): ApiProduct{
        const apiObject = super.toApiObject();
        const prices: Array<ApiPrice> = this.prices.map(
            (price: Price) => price.toApiObject()
        );
        return {
            ...apiObject,
            id: this.id,
            prezzi: prices,
            categoria: this.category ?
                this.category.toApiObject() :
                undefined
        }
    }

    toEditedApiObject(): object {
        const changes: object = {};
        const edited = this.toApiObject();
        const handlePossibleChange = (field: string) => {
            if(
                this.originalData[field] !== edited[field] &&
                field !== 'prezzi'
            ){
                if(
                    (edited[field] instanceof Object) &&
                    ('toEditedApiObject' in edited[field])
                )
                    changes[field] = edited[field].toEditedApiObject();
                else
                    changes[field] = edited[field];
            }
        }
        for(const field in this.originalData){
            handlePossibleChange(field)
        }
        for(const field in edited){
            if(!changes[field])
                handlePossibleChange(field);
        }
        return changes;
    }
}
export class ProductStatistics implements ConvertibleToApiData<ApiProductStatistics>{
    purchased: Decimal;
    reversed: Decimal;
    sold: Decimal;
    available: Decimal;
    product: number;
    unit: ShoppedUnit;

    constructor(statistics: ApiProductStatistics){
        this.purchased = new Decimal(statistics.quantitaAcquistata || 0);
        this.reversed = new Decimal(statistics.quantitaStornata || 0);
        this.sold = new Decimal(statistics.quantitaVenduta || 0);
        this.available = new Decimal(statistics.quantitaDisponibile || 0);
        this.product = statistics.prodottoId;
        this.unit = new ShoppedUnit(
            QuantityUnits.getInstance(statistics.unita),
            statistics.descrizioneUnita
        );
    }

    public get textualAvailable(): string{
        return this.available.toFixed(this.unit.decimals);
    }

    public get textualSold(): string{
        return this.sold.toFixed(this.unit.decimals);
    }

    public get textualPurchased(): string{
        return this.purchased.toFixed(this.unit.decimals);
    }

    public get textualReversed(): string{
        return this.reversed.toFixed(this.unit.decimals);
    }

    public get stockDescription(){
        return `${this.textualAvailable} ${this.unit.toString()}`;
    }

    toApiObject(): ApiProductStatistics {
        return {
            unita: this.unit.name,
            descrizioneUnita: this.unit.description,
            quantitaAcquistata: this.purchased.toFixed(2),
            quantitaDisponibile: this.available.toFixed(2),
            quantitaStornata: this.reversed.toFixed(2),
            quantitaVenduta: this.sold.toFixed(2),
            prodottoId: this.product
        }
    }
}

export class ExtendedProduct extends Product{
    statistics: Map<ShoppedUnit, ProductStatistics>;

    constructor(product: ApiProduct, statistics: Array<ApiProductStatistics>){
        super(product);
        const grouppedStats = new Map(statistics.map(
            stats => [
                new ShoppedUnit(
                    QuantityUnits.getInstance(stats.unita),
                    stats.descrizioneUnita
                ),
                new ProductStatistics(stats)
            ]
        ));
        this.statistics = grouppedStats;
    }
}

export class ProductStatisticsContainer{
    product: number;
    statistics: Array<ProductStatistics>;

    constructor(container: ApiProductStatisticsContainer){
        this.product = container.prodottoId;
        this.statistics = container.statistiche.map(
            stats => new ProductStatistics(stats)
        );
    }
}

export class CategoryRequest
    implements ConvertibleToApiData<ApiCategoryRequest>,
        EditableApiConcretization
{
    name: string;

    constructor(category: ApiCategoryRequest){
        this.name = category.nome;
    }
    toEditedApiObject(): object {
        return {
            nome: this.name
        }
    }

    toApiObject(): ApiCategoryRequest {
        return {
            nome: this.name
        }
    }
}

export class Category
    extends CategoryRequest
    implements ConvertibleToApiData<ApiCategory>
{
    id: number;

    constructor(category: ApiCategory){
        super(category);
        this.id = category.id;
    }
    toEditedApiObject(): object {
        const superEdited = super.toEditedApiObject();
        return {
            ...superEdited,
            id: this.id
        }
    }

    toApiObject(): ApiCategory {
        return {
            id: this.id,
            nome: this.name
        }
    }
}