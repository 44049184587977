import { useParams } from "react-router-dom";
import OrderDetails from "../components/OrderDetails";
import PageLayout from "../layout/PageLayout";
import React from "react";

export default function MyOrder(){

  const { orderCode: orderCodeParam } = useParams();

  if(!orderCodeParam)
    return <></>;

  const orderCode = parseInt(orderCodeParam)

  return <PageLayout pageTitle={`Ordine #${orderCode}`}>
    <OrderDetails orderCode={orderCode} />
  </PageLayout>
}