import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { CategoryRequest } from "../features/remote/products/ProductsAPI";
import { getLoadedCategories, isLoading, searchCategories } from "../features/remote/products/ProductsSlice";

type ComponentProps =
  Partial<AutocompleteProps<CategoryRequest, false, false, true>> &
  {
    selection?: CategoryRequest;
    onSelectionChange: (selection: CategoryRequest | null) => any;
    required?: boolean;
    TextFieldProps?: TextFieldProps;
  }

export default function CategorySearchField({
  selection, onSelectionChange, required, TextFieldProps, disabled, ...otherProps
}: ComponentProps){
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const categories = useAppSelector(getLoadedCategories);
  const loading = useAppSelector(isLoading) && open;
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if(inputValue && !loading)
        dispatch(searchCategories({ name: inputValue }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue]
  );

  useEffect(
    () => {
      setInputValue(selection?.name || '')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection]
  );

  return <Autocomplete<CategoryRequest  | null, false, false, true>
    sx={{ width: 1 }}
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    isOptionEqualToValue={(option, value) => option?.name === value?.name}
    getOptionLabel={
      (option) => ((option instanceof CategoryRequest) ? option.name : option) || ''
    }
    options={categories || []}
    loading={loading}
    value={inputValue}
    onChange={(_, newValue) => onSelectionChange(newValue as CategoryRequest)}
    onInputChange={(_, newInputValue) => {
      if(newInputValue === '')
        onSelectionChange(null);
      else
        setInputValue(newInputValue);
    }}
    filterOptions={(options, params) => {
      const filtered = options.filter(
        option => option?.name.includes(params.inputValue)
      );

      if(
        params.inputValue !== '' &&
        filtered.every(option => option?.name !== params.inputValue)
      ){
        filtered.unshift(new CategoryRequest({
          nome: params.inputValue
        }));
      }

      return filtered;
    }}
    selectOnFocus
    freeSolo
    renderOption={(props, option) => <li {...props}>{option?.name}</li>}
    renderInput={(params) => (
      <TextField
        {...params}
        {...TextFieldProps}
        required={required}
        label="Categoria"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
        }}
      />
    )}
    disabled={disabled && !loading}
    {...otherProps}
  />
}