import { createAsyncThunk } from "@reduxjs/toolkit";
import { editPrice as editPriceApi, PriceRequest } from "./PricesAPI";

export const editPrice = createAsyncThunk(
  'remote/products/price/edit',
  async (price: PriceRequest, { rejectWithValue }) => {
    try{
      const response = await editPriceApi(price);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);