import { AddPhotoAlternate, ChangeCircle } from "@mui/icons-material";
import { Autocomplete, Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Category, Product, ProductCommercialCategory } from "../features/remote/products/ProductsAPI";
import fruitsAndVegetablesList from "../resources/fruitsAndVegetables.json";
import CategorySearchField from "./CategorySearchField";
import { NumberTextField } from "./NumberTextField";
import { UnitDescriptionField } from "./UnitDescriptionField";

export type ProductData = Partial<Product> & {
  kgPrice?: string;
  packPrice?: string;
  packDescription?: string;
};

type ComponentProps = {
  values?: ProductData;
  onDataChange: (data: ProductData) => any;
  isLoading?: boolean;
}

export default function ProductForm({ values, onDataChange, isLoading }: ComponentProps){

  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [origin, setOrigin] = useState('');
  const [commercialCategory, setCommercialCategory] = useState('');
  const [category, setCategory] = useState<Category>();
  const [kgPrice, setKgPrice] = useState('');
  const [packPrice, setPackPrice] = useState('');
  const [packDescription, setPackDescription] = useState<string>('');
  const imageInputRef = useRef<HTMLInputElement>(null);

  useEffect(
    () => {
      if(values?.image !== image)
        setImage(values?.image || '');
      if(values?.name !== name)
        setName(values?.name || '');
      if(values?.code !== code)
        setCode(values?.code || '');
      if(values?.origin !== origin)
        setOrigin(values?.origin || '');
      if(values?.commercialCategory !== commercialCategory)
        setCommercialCategory(values?.commercialCategory || '');
      if(values?.category !== category)
        setCategory(values?.category);
      if(values?.kgPrice !== kgPrice)
        setKgPrice(values?.kgPrice || '');
      if(values?.packPrice !== packPrice)
        setPackPrice(values?.packPrice || '');
      if(values?.packDescription !== packDescription)
        setPackDescription(values?.packDescription || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  function handleChangeField(field: string, value: any){
    switch(field){
      case 'name':
        setName(value);
        break;
      case 'code':
        setCode(value);
        break;
      case 'origin':
        setOrigin(value);
        break;
      case 'commercialCategory':
        setCommercialCategory(value);
        break;
      case 'kgPrice':
        setKgPrice(value);
        break;
      case 'packPrice':
        setPackPrice(value);
        break;
      case 'category':
        setCategory(value);
        break;
      case 'packDescription':
        setPackDescription(value);
        break;
      default:
        break;
    }
    onDataChange({
      image, name, code, origin, commercialCategory, kgPrice, packPrice, category,
      packDescription, [field]: value
    });
  }

  function onImageChange(event: React.ChangeEvent<HTMLInputElement>){
    if(event.target.files?.length && event.target.files.length > 0){
      const file = event.target.files ? event.target.files[0] : null;
      const reader = new FileReader();
      reader.onloadend = (readerEvent) => {
        const loadedImage = readerEvent.target?.result as string;
        setImage(loadedImage);
        onDataChange({
          name, code, origin, commercialCategory, kgPrice, packPrice,
          image: loadedImage
        });
      };
      if(file)
        reader.readAsDataURL(file);
    }
  }

  function pickImage(){
    imageInputRef.current?.click();
  }

  return <Grid
    container
    direction="column"
    justifyContent="stretch"
    spacing={2}
  >
    <Grid item xs={12} container justifyContent="center" >
      <input
        ref={imageInputRef} type="file" accept="image/*" onChange={onImageChange}
        style={{ display: 'none' }}
      />
      {
        image &&
        <Box position="relative">
          <img
            src={image}
            style={{ maxWidth: '100%', maxHeight: '20vh' }}
            alt="product"
          />
          <IconButton
            onClick={pickImage}
            sx={{
              position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
              opacity: 0, borderRadius: 0,
              '&:hover': {
                bgcolor: 'white1.main',
                opacity: 1
              }
            }}
          >
            <ChangeCircle />
          </IconButton>
        </Box>
      }
      {
        !image &&
        <IconButton onClick={pickImage}>
          <AddPhotoAlternate sx={{ width: 1, height: '20vh' }} />
        </IconButton>
      }
    </Grid>
    <Grid
      item
      container
      direction="row"
      spacing={1}
    >
      <Grid item xs={12} md={6}>
        <Autocomplete
          options={fruitsAndVegetablesList}
          includeInputInList
          freeSolo
          disableClearable
          value={values?.name || name}
          onInputChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
              event &&
              handleChangeField('name', event.target.value)
          }
          disabled={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Nome"
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          value={values?.code || code}
          onChange={event => handleChangeField('code', event.target.value)}
          label="Codice"
          disabled={isLoading}
          required
        />
      </Grid>
    </Grid>
    <Grid item>
      <CategorySearchField
        selection={category}
        onSelectionChange={
          value => handleChangeField('category', value)
        }
        required={false}
        disabled={isLoading}
      />
    </Grid>
    <Grid
      item
      container
      direction="row"
      spacing={1}
    >
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          value={values?.origin || origin}
          onChange={event => handleChangeField('origin', event.target.value)}
          label="Provenienza"
          autoComplete="country"
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Categoria commerciale</InputLabel>
          <Select
            fullWidth
            value={values?.commercialCategory || commercialCategory}
            label="Categoria commerciale"
            onChange={event => handleChangeField('commercialCategory', event.target.value)}
            disabled={isLoading}
          >
            {
              Object.keys(ProductCommercialCategory).map(
                category => <MenuItem
                  key={category} value={ProductCommercialCategory[category]}
                >
                  {ProductCommercialCategory[category]}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <Grid
      item
      container
      direction="row"
      spacing={1}
    >
      <Grid item xs={12} md={6}>
        <NumberTextField
          fullWidth
          value={values?.kgPrice || kgPrice}
          inputProps={{
            min: 0,
            step: 0.001
          }}
          onChange={event => handleChangeField('kgPrice', event.target.value)}
          label="Prezzo al Kg"
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberTextField
          fullWidth
          value={values?.packPrice || packPrice}
          inputProps={{
            min: 0,
            step: 0.001
          }}
          onChange={event => handleChangeField('packPrice', event.target.value)}
          label="Prezzo al Collo"
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <UnitDescriptionField
          disabled={isLoading}
          value={packDescription}
          onChange={value => handleChangeField('packDescription', value)}
          descriptionPrefix="Collo da "
		  defaultDescription="Collo"
        />
      </Grid>
    </Grid>
  </Grid>
}