import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store, persistor } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './index.css';
import { ConfigProvider } from './features/remote/config/Config';

let theme = createTheme({
  palette: {
    primary: {
      main: '#1c528a',
      light: '#01c8f1'
    },
    secondary: {
      main: '#fbcb19',
      light: '#f7e08a'
    },
    text: {
      primary: '#1c528a'
    },
    white1: {
      main: 'rgba(255,255,255,.89)'
    },
    black1: {
      main: 'rgba(0,0,0,0.89)'
    },
    black2: {
      main: 'rgba(0,0,0,0.75)'
    },
    black3: {
      main: 'rgba(0,0,0,0.69)'
    },
  }
});
theme = responsiveFontSizes(theme);

if(
  window.location.protocol === 'http:' &&
  process.env.NODE_ENV === 'production'
){
  const host = window.location.host;
  const pathname = window.location.pathname;
  const search = window.location.search;
  window.location.replace(
    `https://${host}${pathname}${search}`
  );
}

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={4}>
            <ConfigProvider>
              <App />
            </ConfigProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

serviceWorker.register();
