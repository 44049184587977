import { FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { QuantityUnits } from "../features/remote/helpers/types";
import { ProductStatistics } from "../features/remote/products/ProductsAPI";
import { getLoadedProductStatistics, getProductStatistics } from "../features/remote/products/ProductsSlice";

type SelectorProps = Omit<FormControlProps, "onChange"> & {
  productId?: number;
  value?: ProductStatistics;
  onChange: (value?: ProductStatistics) => any;
  SelectProps: SelectProps;
  checkAvailability?: boolean;
};

export default function QuantityUnitSelector(
  { productId, value, onChange, SelectProps, checkAvailability, ...props }: SelectorProps
){

  const statistics = useAppSelector(getLoadedProductStatistics);
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if(productId)
        dispatch(getProductStatistics({ id: productId }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productId]
  );

  function handleChange(unitName: string){
    let stock: ProductStatistics | undefined = undefined;
    if(productId){
      stock = statistics.find(statistic => statistic.unit.name === unitName);
      if(!checkAvailability && !stock)
        stock = new ProductStatistics({
          quantitaDisponibile: '0',
          unita: unitName,
          quantitaAcquistata: '0',
          quantitaStornata: '0',
          quantitaVenduta: '0',
          prodottoId: productId
        });
    }
    onChange(stock);
  }

  let options = QuantityUnits.getAll();
  if(checkAvailability){
    options = options.filter(
      option => statistics.find(
        statistic => statistic.unit.equals(option) && statistic.available.greaterThan(0)
      )
    );
  }

  return <FormControl {...props}>
    <InputLabel id="product_stock_selector_label">Formato</InputLabel>
    <Select
      value={value?.unit.name || ''}
      onChange={
        (event: SelectChangeEvent<string>) => handleChange(event.target.value)
      }
      label="Formato" labelId="product_stock_selector_label" {...SelectProps}
    >
      {
        options.filter(option => !!productId).map(
          (unit) => <MenuItem
            value={unit.name}
            key={unit.toString()}
          >
            {unit.toHumanReadableString()}
          </MenuItem>
        )
      }
    </Select>
  </FormControl>
}