import { Delete } from "@mui/icons-material";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { isLoading as isLoadingSaleEvents, getLoadedSalesEvents } from "../features/remote/events/commercialEvents/CommercialEventsSlice";
import { deleteBalanceEvent, getBalanceEvents, getLoadedEvents, isLoading as isLoadingBalanceEvents } from "../features/remote/events/BalanceEventsSlice";
import { getSaleEvents } from "../features/remote/events/commercialEvents/SaleEventsActions";
import Decimal from "decimal.js";
import { BalanceEvent } from "../features/remote/events/BalanceEventsAPI";
import { SaleEvent } from "../features/remote/events/commercialEvents/SaleEventsAPI";
import AddBalanceEventDialog from "./AddBalanceEventDialog";
import { OrderState } from "../features/remote/orders/OrdersAPI";
import { User } from "../features/remote/users/UsersAPI";

const itTranslation = require('../features/datagrid/locale/it');

const columns = (deleteCallback: (id: number) => any): GridColumns => [
  {
    field: 'timestamp', headerName: 'Data', flex: 0.3, minWidth: 115,
    valueFormatter: (params) => (params.value as Date).toLocaleDateString()
  },
  {
    field: 'detail', headerName: 'Dettaglio', flex: 0.75, minWidth: 150,
    valueGetter: (params) =>
      params.row instanceof BalanceEvent ?
      "Acconto" :
      `Acquisto ${params.row.product.name}`,
  },
  {
    field: 'total', headerName: 'Saldo', flex: 0.5, minWidth: 100,
    headerAlign: 'center', align: 'center',
    valueGetter: (params) =>
      params.row instanceof BalanceEvent ?
      `+${params.row.textualPayment} €` :
      `-${params.row.textualTotPrice} €`
  },
  {
    field: 'operations', headerName: '', flex: 0.25, minWidth: 50,
    align: 'center', resizable: false, filterable: false, renderCell:
      (params) => params.row instanceof BalanceEvent ?
        <IconButton color="error" onClick={() => deleteCallback(params.row.id)}>
          <Delete />
        </IconButton> :
        <></>
  }
];

const noEvents = {count: 0, data: []};
function concatenateEvents(
  saleEvents: SaleEvent[], balanceEvents: BalanceEvent[]
){
  let events: (SaleEvent | BalanceEvent)[] = saleEvents;
  events = events.concat(balanceEvents);
  return {
    count: saleEvents.length + balanceEvents.length,
    data: events.sort(
      (first, second) => second.timestamp.getTime() - first.timestamp.getTime()
    )
  };
}
function calculateSum(events: {count: number; data: (SaleEvent | BalanceEvent)[]}){
  return events.data.reduce(
    (accumulator, event) =>
      (event instanceof BalanceEvent) ?
        accumulator.plus(event.payment) :
        accumulator.minus(event.totPrice),
    new Decimal(0)
  ).toFixed(2)
}

type UserBalanceProps = {
  user: User;
}

export default function UserBalance({ user }: UserBalanceProps){

  const saleEvents = useAppSelector(getLoadedSalesEvents);
  const balanceEvents = useAppSelector(getLoadedEvents);
  const events =
    saleEvents && balanceEvents ?
    concatenateEvents(saleEvents, balanceEvents) :
    noEvents;
  const loadingSaleEvents = useAppSelector(isLoadingSaleEvents);
  const loadingBalanceEvents = useAppSelector(isLoadingBalanceEvents);
  const loading = loadingSaleEvents || loadingBalanceEvents;
  const [openAddPayment, setOpenAddPayment] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if(user && !openAddPayment){
        dispatch(getSaleEvents({ user: user.email, orderStates:[OrderState.DELIVERED] }));
        dispatch(getBalanceEvents({ user: user.email }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, openAddPayment]
  );

  function deleteEvent(id: number){
    dispatch(deleteBalanceEvent({ id }));
  }

  return <Stack direction="column" sx={{ width: 1, height: "90vh" }}>
    <Grid container direction="row" spacing={2} justifyContent="space-between">
      <Grid item xs>
        <Stack direction="row" width={1} spacing={2}>
          <Typography variant="h4" color="black1.main">
            Saldo
          </Typography>
          <Typography variant="h4" color="black3.main">
            {events && calculateSum(events)} &euro;
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4} container justifyContent="flex-end">
        <Button
          onClick={() => setOpenAddPayment(true)}
          variant="contained" fullWidth
        >
          Aggiungi
        </Button>
      </Grid>
    </Grid>
    <DataGrid
      columns={columns(deleteEvent)}
      rows={events?.data || []}
      rowCount={events?.count || 0}
      autoPageSize={true}
      loading={loading}
      disableSelectionOnClick
      sx={{
        width: '100%',
        border: 'none',
        color: 'black1.main'
      }}
      localeText={itTranslation}
    />
    <AddBalanceEventDialog
      open={openAddPayment}
      handleClose={() => setOpenAddPayment(false)}
      user={user}
    />
  </Stack>
}