import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../utils';
import { PagedResponse } from '../connector/ConnectorAPI';
import { IQuantityUnit } from '../helpers/types';
import { editCartEntry as editCartEntryApi, getCart as getCartApi, putCartEntry as putCartEntryApi, getCartEntry as getCartEntryApi, CartEntry, CartEntryRequest } from './CartAPI';

interface CartState{
  status: 'idle' | 'loading',
  error: Error | null,
  loadedCart: PagedResponse<CartEntry>,
  loadedCartEntry: CartEntry | null
}

const initialState = {
  status: 'idle',
  error: null,
  loadedCart: {
    count: 0,
    data: [],
    page: 0
  },
  loadedCartEntry: null
} as CartState;

interface CartPageFetchOptions{
  page: number;
  length: number;
}
export const getCart = createAsyncThunk(
  'remote/cart/get',
  async ({page, length}: CartPageFetchOptions, { rejectWithValue }) => {
    try{
      const response = await getCartApi(page, length);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface CartEntryFetchOptions{
  product: number;
  unit: IQuantityUnit;
}
export const getCartEntry = createAsyncThunk(
  'remote/cart/get_entry',
  async (
    {product, unit}: CartEntryFetchOptions,
    { rejectWithValue }
  ) => {
    try{
      const response = await getCartEntryApi(product, unit);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const editCartEntry = createAsyncThunk(
  'remote/cart/edit_entry',
  async (entry: CartEntry, { rejectWithValue }) => {
    try{
      await editCartEntryApi(entry);
      return entry;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const addToCart = createAsyncThunk(
  'remote/cart/add',
  async (entry: CartEntryRequest, { rejectWithValue }) => {
    try{
      const response = await putCartEntryApi(entry);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const cartSlice = createSlice({
  name: 'remote/cart',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedCart = action.payload;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedCart.count++;
        state.loadedCart.data.push(action.payload);
      })
      .addCase(getCartEntry.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedCartEntry = action.payload;
      })
      .addCase(editCartEntry.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedCartEntry = null;
        const updatedEntry = action.payload;
        const quantity = updatedEntry.quantity;
        if(quantity.greaterThan(0)){
          state.loadedCartEntry = updatedEntry;
          state.loadedCart.data = state.loadedCart.data.map(
            entry => entry.id === updatedEntry.id ? updatedEntry : entry
          );
        }else{
          state.loadedCart.data = state.loadedCart.data.filter(
            entry => entry.id !== updatedEntry.id
          );
          state.loadedCart.count--;
        }
      })
      .addMatcher(isNamespacePending('remote/cart'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/cart'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getLoadedCartEntry = (state: RootState) => state.remote.cart.loadedCartEntry;
export const getLoadedCart = (state: RootState) => state.remote.cart.loadedCart;
export const isLoading = (state: RootState) => state.remote.cart.status === "loading";
export const getError = (state: RootState) => state.remote.cart.error;

export default cartSlice.reducer;