import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderState } from '../../orders/OrdersAPI';
import { editSale as editSaleApi, addSale as addSaleApi, getSales as getSalesApi, getSale as getSaleApi, SaleEvent, SaleEventRequest } from './SaleEventsAPI';

interface SaleEventsFilterOptions{
  from?: Date;
  to?: Date;
  product?: number;
  user?: string;
  orderStates?: OrderState[]
}
export const getSaleEvents = createAsyncThunk(
  'remote/commercialEvents/sale/get_all',
  async (
    { from, to, product, user, orderStates }: SaleEventsFilterOptions,
    { rejectWithValue }
  ) => {
    try{
      const response = await getSalesApi({from, to, product, user, orderStates});
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface SalveEventIdentifyOptions{
  id: number;
}
export const getSaleEvent = createAsyncThunk(
  'remote/commercialEvents/sale/get',
  async ({ id }: SalveEventIdentifyOptions, { rejectWithValue }) => {
    try{
      const response = await getSaleApi(id);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const addSaleEvent = createAsyncThunk(
  'remote/commercialEvents/sale/add',
  async (event: SaleEventRequest, { rejectWithValue }) => {
    try{
      const response = await addSaleApi(event);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const editSaleEvent = createAsyncThunk(
  'remote/commercialEvents/sale/edit',
  async (event: SaleEvent, { rejectWithValue }) => {
    try{
      const response = await editSaleApi(event);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);