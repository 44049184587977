import { ConvertibleToApiData, EditableApiConcretization, get, PagedResponse, post, put } from "../connector/ConnectorAPI";
import { ApiProduct, Product } from "../products/ProductsAPI";
import { Decimal } from "decimal.js";
import { IQuantityUnit, QuantityUnits, ShoppedUnit } from "../helpers/types";

export async function getCart(page: number, length: number):
  Promise<PagedResponse<CartEntry>>
{
  const response: PagedResponse<ApiCartEntry> = await get(
    '/carrello',
    {page, length}
  );
  return {
    count: response.count,
    page,
    data: response.data.map(
      entry => new CartEntry(entry)
    )
  }
}

export async function getCartEntry(
  product: number, unit: IQuantityUnit
): Promise<CartEntry>{
  const response: ApiCartEntry = await get(
    `/carrello/${product}`,
    {unit: unit.name}
  );
  return new CartEntry(response);
}

export async function editCartEntry(entry: CartEntry): Promise<void>{
  await post(
    '/carrello',
    entry.toEditedApiObject()
  );
  return;
}

export async function putCartEntry(entry: CartEntryRequest): Promise<CartEntry>{
  const response: ApiCartEntry = await put(
    '/carrello',
    entry.toApiObject()
  );
  const parsedResponse: CartEntry = new CartEntry(response);
  return parsedResponse;
}

interface ApiCartEntryRequest{
  prodotto: number|ApiProduct;
  quantita: string;
  misura: string;
}

interface ApiCartEntry extends ApiCartEntryRequest{
  id: number;
  prodotto: ApiProduct;
  descrizioneUnita?: string;
}

export class CartEntryRequest
  implements ConvertibleToApiData<ApiCartEntryRequest>, EditableApiConcretization
{
  product: number|Product;
  quantity: Decimal;
  unit: IQuantityUnit;

  constructor(entry: ApiCartEntryRequest){
    this.product =
      typeof entry.prodotto !== "number" ?
        new Product(entry.prodotto) :
        entry.prodotto;
    this.unit = QuantityUnits.getInstance(entry.misura);
    this.quantity = new Decimal(entry.quantita);
  }
  toEditedApiObject(): object {
    return {
      prodotto:
        this.product instanceof Product ?
        this.product.id :
        this.product,
      misura: this.unit.name,
      quantita: this.quantity.toFixed(2)
    }
  }

  public get textualQuantity(): string{
    return this.quantity.toFixed(this.unit.decimals);
  }

  public set textualQuantity(quantity: string){
    this.quantity = new Decimal(quantity);
  }

  toApiObject(): ApiCartEntryRequest {
    return {
      prodotto:
        this.product instanceof Product ?
        this.product.id :
        this.product,
      quantita: this.quantity.toFixed(2),
      misura: this.unit.name
    }
  }
}
export class CartEntry extends CartEntryRequest{
  id: number;
  product: Product;
  unit: ShoppedUnit;

  constructor(entry: ApiCartEntry){
    super(entry);
    this.product =
      typeof entry.prodotto !== "number" ?
      new Product(entry.prodotto) :
      entry.prodotto;
    this.id = entry.id;
    this.unit = new ShoppedUnit(
      QuantityUnits.getInstance(entry.misura),
      entry.descrizioneUnita
    );
  }
}