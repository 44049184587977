import { IdToken } from "@auth0/auth0-react";

export class UserIdentity{
    token: Promise<IdToken>;
    static instance: UserIdentity;

    constructor(token: Promise<IdToken>){
        this.token = token;
    }
    static build(token: Promise<IdToken>){
        this.instance = new UserIdentity(token);
    }
    static getInstance(){
        return this.instance;
    }
}

function parseQueryValue(value: any){
    let parsedValue = value;
    if(parsedValue instanceof Array){
        parsedValue = parsedValue.join(',');
    }else{
        parsedValue = encodeURIComponent(parsedValue);
    }
    return parsedValue;
}

export function get(path: string, query?: Object, options?: RequestOptions): Promise<any>{
    let pathWithQuery = path;
    if(query){
        pathWithQuery += '?' + Object.keys(query).map(
            key => query[key] !== undefined ?
                `${key}=${parseQueryValue(query[key])}` :
                null
        ).filter(element => !!element).join('&');
    }
    return makeAPIRequest('GET', pathWithQuery, null, {}, options);
}

export function put(path: string, data: Object, options?: RequestOptions): Promise<any>{
    return makeAPIRequest('PUT', path, data, {}, options);
}

export function post(path: string, data: Object, options?: RequestOptions): Promise<any>{
    return makeAPIRequest('POST', path, data, {}, options);
}

export function del(path: string, options?: RequestOptions): Promise<any>{
    return makeAPIRequest('DELETE', path, null, {}, options);
}

export async function makeAPIRequest(
    method: string, path: string, data: any = null, headers = {}, options?: RequestOptions
): Promise<any>{
    const headersMap = {
        "Content-Type": "application/json",
        ...headers
    };

    if(!options || !options.noIdentity){
        const identity = UserIdentity.getInstance();
        const userToken = await identity.token;
        headersMap["Authorization"] = `Bearer ${userToken.__raw}`;
    }

    return fetch(
        `${process.env.REACT_APP_API_DOMAIN}${path}`,
        {
            method,
            body: data ? JSON.stringify(data) : null,
            headers: headersMap
        }
    ).then(async response => {
        if(!response.ok){
            let responseBody: any;
            try{
                responseBody = await response.json();
            }catch{}
            throw new ApiError(response, responseBody);
        }
        return response.json();
    });
}

export class ApiError extends Error{
    serverResponse: Response;

    constructor(serverResponse: Response, body?: any){
        super(serverResponse.statusText);
        this.serverResponse = serverResponse;
        if(body)
            this.message =
                body.codeMessage ||
                body.message ||
                serverResponse.statusText;
    }
}

export interface PagedResponse<T>{
    data: Array<T>;
    count: number;
    page: number;
}

export function remapTypeInPagedResponse<T,U>(
    response: PagedResponse<T>, mapTypeFunction: (input: T) => U
): PagedResponse<U>{
    return {
        count: response.count,
        data: (response.data || []).map(mapTypeFunction),
        page: response.page
    }
}

export interface ConvertibleToApiData<T>{
    toApiObject(): T;
}

export interface EditableApiConcretization{
    toEditedApiObject(): object;
}

export interface RequestOptions{
    noIdentity: boolean;
}
