import { Alert, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, StackProps, Switch, TextField, Typography } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { editUser, getError } from "../features/remote/users/UsersSlice";
import { useConfig } from "../features/remote/config/Config";
import UserSearchField from "./UserSearchField";
import { QuantityUnits } from "../features/remote/helpers/types";
import UserBalance from "./UserBalance";
import { User } from "../features/remote/users/UsersAPI";
import { NumberTextField } from "./NumberTextField";

export default function UsersEditor(props: StackProps){

	const error = useAppSelector(getError);
	const [user, setUser] = useState<User>();
	const [enabled, setEnabled] = useState(false);
	const [admin, setAdmin] = useState(false);
	const [unit, setUnit] = useState('');
	const [iva, setIva] = useState<number>();
	const { enqueueSnackbar } = useSnackbar();
	const { parseMessageFromError } = useConfig();
	const dispatch = useAppDispatch();

	useEffect(
		() => {
			if(user){
				setUnit(user.measure?.name);
				setIva(user.iva);
				setEnabled(user.enabled);
				setAdmin(user.admin);
			}
		}, [user]
	);

	function saveSelected(){
		if(user){
			user.measure = QuantityUnits.getInstance(unit);
			user.iva = iva || 0;
			user.enabled = enabled;
			user.admin = admin;
			dispatch(editUser(user))
			.then(unwrapResult).then(
				() => enqueueSnackbar('Aggiornato', { variant: 'success' })
			);
		}
	}

	return <Stack spacing={4} {...props}>
		<UserSearchField
			fullWidth
			selection={user}
			onSelectionChange={setUser}
			TextFieldProps={{
				label: 'Cerca utente'
			}}
		/>
		{
			!user &&
			<Typography color="black1.main" align="center">
				Cerca un utente per cominciare.
			</Typography>
		}
		{
			user &&
			<TextField
				label="Email"
				value={user.email}
				disabled
			/>
		}
		{
			user &&
			<TextField
				label="Username"
				value={user.username}
				disabled
			/>
		}
		{
			user &&
			<FormControlLabel
				control={
					<Switch
						checked={enabled}
						onChange={event => setEnabled(event.target.checked)}
					/>
				}
				label={enabled ? "Abilitato" : "Disabilitato"}
			/>
		}
		{
			user &&
			<FormControlLabel
				control={
					<Switch
						checked={admin}
						onChange={event => setAdmin(event.target.checked)}
					/>
				}
				label={admin ? "Amministratore" : "Cliente"}
			/>
		}
		{
			user && unit &&
			<FormControl>
				<InputLabel id="user_editor_unit_selector">Unità d'acquisto</InputLabel>
				<Select
					label="Unità d'acquisto" fullWidth labelId="user_editor_unit_selector"
					value={unit} onChange={event => setUnit(event.target.value)}
				>
					<MenuItem value="Kg">Kg</MenuItem>
					<MenuItem value="Collo">Collo</MenuItem>
				</Select>
			</FormControl>
		}
		{
			user &&
			<NumberTextField
				label="IVA"
				inputMode="numeric"
				inputProps={{
					min: 0,
					max: 100,
					step: 1
				}}
				InputProps={{
					startAdornment: <Typography sx={{pr: 0.5}}>%</Typography>
				}}
				value={iva}
				onChange={event => setIva(parseInt(event.target.value))}
			/>
		}
		{
			error &&
			<Alert severity="error" sx={{ width: 1 }}>{parseMessageFromError(error)}</Alert>
		}
		{
			user &&
			<Button
				variant="contained"
				disabled={
					unit === user.measure.name &&
					iva === user.iva &&
					enabled === user.enabled &&
					admin === user.admin
				}
				onClick={saveSelected}
			>
				Salva
			</Button>
		}
		{
			user && <UserBalance user={user} />
		}
	</Stack>
}