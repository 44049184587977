import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Navigate, Outlet } from 'react-router-dom';
import WaitingPage from './WaitingPage';
import { isAdmin } from '../features/user/utils';
import React from 'react';

function AdminRoute(){
    const { user } = useAuth0();

    return (user && isAdmin(user)) ? <Outlet /> : <Navigate to="/" replace />;
}

export default withAuthenticationRequired(AdminRoute, {
    onRedirecting: () => <WaitingPage />,
});