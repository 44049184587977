import './FruitLoader.css';
import logo from '../resources/icons/logo.svg';
import lemon from '../resources/icons/lemon.svg';
import orange from '../resources/icons/orange.svg';
import watermelon from '../resources/icons/watermelon.svg';
import coconut from '../resources/icons/coconut.svg';
import carrot from '../resources/icons/carrot.svg';
import { Box, BoxProps } from '@mui/material';
import React from 'react';

const fruits = [
  logo,
  lemon,
  watermelon,
  orange,
  coconut,
  carrot
];

export default function FruitLoader(props: BoxProps){
  return <Box className='fruit-loader' {...props}>
    {
      fruits.map(
        (fruit, index) => <img
          src={fruit} className="comet" alt="fruit" key={index}
          style={{
            "--j": `${index}s`
          } as any}
        />
      )
    }
  </Box>
}