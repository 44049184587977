import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { isNamespacePending, isNamespaceRejectedWithValue } from '../../utils';
import { getBalanceEvents as getBalanceEventsApi, addBalanceEvent as addBalanceEventApi, deleteBalanceEvent as deleteBalanceEventApi, BalanceEvent, BalanceEventRequest } from './BalanceEventsAPI';

interface BalanceEventsState{
  status: 'idle' | 'loading',
  error: Error | null,
  loadedEvent: BalanceEvent | null,
  loadedEvents: BalanceEvent[]
}
const initialState = {
  status: 'idle',
  error: null,
  loadedEvent: null,
  loadedEvents: []
} as BalanceEventsState;

export const addBalanceEvent = createAsyncThunk(
  'remote/balanceEvents/add',
  async (event: BalanceEventRequest, { rejectWithValue }) => {
    try{
      const response = await addBalanceEventApi(event);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface BalanceEventIdentifyOptions{
  id: number;
}
export const deleteBalanceEvent = createAsyncThunk(
  'remote/balanceEvents/del',
  async ({ id }: BalanceEventIdentifyOptions, { rejectWithValue }) => {
    try{
      await deleteBalanceEventApi(id);
      return { id };
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

interface FetchBalanceEventsOptions{
  user: string;
}
export const getBalanceEvents = createAsyncThunk(
  'remote/balanceEvents/get_all',
  async (
    { user }: FetchBalanceEventsOptions,
    { rejectWithValue }
  ) => {
    try{
      const response = await getBalanceEventsApi(user);
      return response;
    }catch(e){
      return rejectWithValue(e);
    }
  }
);

export const balanceEventsSlice = createSlice({
  name: 'remote/balanceEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addBalanceEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedEvent = action.payload;
        if(state.loadedEvents)
          state.loadedEvents.unshift(action.payload);
      })
      .addCase(deleteBalanceEvent.fulfilled, (state, action) => {
        state.status = 'idle';

        if(state.loadedEvent?.id === action.payload.id)
          state.loadedEvent = null;

        if(state.loadedEvents){
          const filteredData = state.loadedEvents.filter(
            event => event.id !== action.payload.id
          )
          state.loadedEvents = filteredData;
        }
      })
      .addCase(getBalanceEvents.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedEvents = action.payload;
      })
      .addMatcher(isNamespacePending('remote/balanceEvents'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isNamespaceRejectedWithValue('remote/balanceEvents'), (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const getLoadedEvent =
  (state: RootState) => state.remote.events.balanceEvents.loadedEvent;
export const getLoadedEvents =
  (state: RootState) => state.remote.events.balanceEvents.loadedEvents;
export const isLoading =
  (state: RootState) => state.remote.events.balanceEvents.status === "loading";
export const getError = (state: RootState) => state.remote.events.balanceEvents.error;

export default balanceEventsSlice.reducer;