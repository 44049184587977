import { put, post, get, PagedResponse, ConvertibleToApiData, remapTypeInPagedResponse } from "../connector/ConnectorAPI";
import { ApiSaleEvent, SaleEvent } from "../events/commercialEvents/SaleEventsAPI";
import { ApiUser, User } from "../users/UsersAPI";
import { ApiProduct, Product } from "../products/ProductsAPI";
import { Decimal } from "decimal.js";
import { QuantityUnits, ShoppedUnit } from "../helpers/types";

export async function addOrder(order: OrderRequest): Promise<Order> {
    const response: ApiOrder = await put('/ordine', order.toApiObject());
    return new Order(response);
}

export async function changeOrderStatus(id: number, state: OrderState): Promise<Order> {
    const response: ApiOrder = await post(`/ordine/${id}`, {stato: state});
    return new Order(response);
}

export async function getPendingOrdersGroups(
    page: number, length: number
): Promise<PagedResponse<OrderGroup>> {
    const response: PagedResponse<ApiOrderGroup> = await get(
        '/ordini/pendenti', {page, length}
    );
    const parsedResponse: PagedResponse<OrderGroup> =
        remapTypeInPagedResponse<ApiOrderGroup, OrderGroup>(
            response, element => new OrderGroup(element)
        );
    return parsedResponse;
}

export async function getOrders(
    page: number, length: number, ids?: number[]
): Promise<PagedResponse<Order>> {
    const filter: any = {page, length};
    if(ids)
        filter.orders = ids;
    const response: PagedResponse<ApiOrder> = await get('/ordini', filter);
    const parsedResponse: PagedResponse<Order> =
        remapTypeInPagedResponse<ApiOrder, Order>(
            response, element => new Order(element)
        );
    return parsedResponse;
}

export async function getComanda(orders: Array<number>): Promise<Comanda> {
    const response: Array<ApiComandaRow> = await get(
        '/ordini/comanda',
        { orders }
    );
    const parsedResponse: Comanda = new Comanda(response);
    return parsedResponse;
}

export async function getOrder(id: number): Promise<DetailedOrder> {
    const response: ApiDetailedOrderResponse = await get(`/ordine/${id}`);
    const parsedResponse: DetailedOrder = new DetailedOrder(response);
    return parsedResponse;
}

interface ApiOrderRequest{
    indirizzoConsegna: string;
    nota: string;
}
export interface ApiOrder extends ApiOrderRequest{
    id: number;
    totale: string;
    totaleConIva: string;
    timestampApertura: number;
    stato: OrderState;
    utenteNickname: string;
}

export interface ApiDetailedOrder extends ApiOrder{
    utente: ApiUser;
    eventiVendita: Array<ApiSaleEvent>;
}

interface ApiDetailedOrderResponse{
    utente: ApiUser;
    ordine: ApiOrder;
    eventiVendita: Array<ApiSaleEvent>;
}

interface ApiComandaProductRequest{
    quantita: string;
    misura: string;
    descrizioneUnita?: string;
}
interface ApiComandaRow{
    prodotto: ApiProduct;
    comanda: Array<ApiComandaProductRequest>;
}
interface ApiOrderGroup{
    utente: ApiUser;
    totale: string;
    totaleConIva: string;
    timestamp: number;
    ordini: Array<number>;
}

export enum OrderState{
    SENT = "Inviato",
    CONFIRMED = "Confermato",
    DELIVERED = "Consegnato",
    CANCELED = "Annullato"
}

export class OrderRequest implements ConvertibleToApiData<ApiOrderRequest>{
    address: string;
    note: string;

    constructor(address: string, note: string);
    constructor(order: ApiOrderRequest);
    constructor(...args: any[]){
        if (args.length === 2) {
            this.address = args[0];
            this.note = args[1];
        } else {
            this.address = args[0].indirizzoConsegna;
            this.note = args[0].nota;
        }
    }
    toApiObject(): ApiOrderRequest{
        return {
            indirizzoConsegna: this.address,
            nota: this.note
        };
    }
}
export class Order extends OrderRequest{
    protected _total: Decimal;
    protected _totalExcludedIva: Decimal;
    protected _totalIva: Decimal;
    id: number;
    timestamp: Date;
    state: OrderState;
    nicknameUser: string;

    constructor(address: string);
    constructor(order: ApiOrder);
    constructor(firstArgument: any){
        super(firstArgument);
        if(typeof firstArgument !== "string"){
            this.id = firstArgument.id;
            this._total = new Decimal(firstArgument.totaleConIva);
            this._totalExcludedIva = new Decimal(firstArgument.totale);
            this._totalIva = this._total.minus(this._totalExcludedIva);
            this.state = firstArgument.stato;
            this.timestamp = new Date(firstArgument.timestampApertura);
            this.nicknameUser = firstArgument.utenteNickname;
        }
    }

    public get total(){
        const fullString = this._total.toFixed(3);
        return this.removeLastDigitIfZero(fullString);
    }

    public getTotalOfGroup(others: Order[]){
        const value = others.reduce(
            (sum, order) => sum.add(order._total),
            this._total
        );
        const fullString = value.toFixed(3);
        return this.removeLastDigitIfZero(fullString);
    }

    public get totalIva(){
        const fullString = this._totalIva.toFixed(3);
        return this.removeLastDigitIfZero(fullString);
    }

    public getTotalIvaOfGroup(others: Order[]){
        const value = others.reduce(
            (sum, order) => sum.add(order._totalIva),
            this._totalIva
        );
        const fullString = value.toFixed(3);
        return this.removeLastDigitIfZero(fullString);
    }

    public get totalExcludedIva(){
        const fullString = this._totalExcludedIva.toFixed(3);
        return this.removeLastDigitIfZero(fullString);
    }

    removeLastDigitIfZero(number: string){
        return number[number.length - 1] === '0' ?
            number.substring(0, number.length - 1) :
            number;
    }

    toApiObject(): ApiOrder{
        const apiObject: ApiOrderRequest = super.toApiObject();
        return {
            ...apiObject,
            id: this.id,
            totale: this._totalExcludedIva.toFixed(3),
            totaleConIva: this._total.toFixed(3),
            stato: this.state,
            timestampApertura: this.timestamp.getTime(),
            utenteNickname: this.nicknameUser
        };
    }

    copyFrom(source: Order){
        this.id = source.id;
        this._total = source._total;
        this._totalExcludedIva = source._totalExcludedIva;
        this._totalIva = source._totalIva;
        this.state = source.state;
        this.timestamp = source.timestamp;
    }
}

export class DetailedOrder extends Order{
    user: User;
    detail: Array<SaleEvent>;
    constructor(detailedOrder: ApiDetailedOrder);
    constructor(detailedOrder: ApiDetailedOrderResponse);
    constructor(detailedOrder: any){
        super(
            'ordine' in detailedOrder ?
            detailedOrder.ordine :
            detailedOrder
        );
        this.user = new User(detailedOrder.utente);
        this.detail = (detailedOrder.eventiVendita || []).map(
            (apiEvent: ApiSaleEvent) => new SaleEvent(apiEvent)
        );
        this._total = (detailedOrder.eventiVendita || []).reduce(
            (partialSum: Decimal, event: ApiSaleEvent) => {
                const cost = new Decimal(event.costo);
                return partialSum.plus(cost).plus(
                    cost.times(event.iva).dividedBy(100)
                );
            },
            new Decimal(0)
        );
        this._totalIva = (detailedOrder.eventiVendita || []).reduce(
            (partialSum: Decimal, event: ApiSaleEvent) => {
                const cost = new Decimal(event.costo);
                return partialSum.plus(
                    cost.times(event.iva).dividedBy(100)
                );
            },
            new Decimal(0)
        );
        this._totalExcludedIva = (detailedOrder.eventiVendita || []).reduce(
            (partialSum: Decimal, event: ApiSaleEvent) =>
                partialSum.plus(event.costo),
            new Decimal(0)
        );
    }
    toApiObject(): ApiDetailedOrder{
        const apiObject: ApiOrder = super.toApiObject();
        return {
            ...apiObject,
            utente: this.user.toApiObject(),
            eventiVendita: this.detail.map(
                event => event.toApiObject()
            )
        };
    }
}

class ProductRequest{
    quantity: Decimal;
    unit: ShoppedUnit;

    constructor(request: ApiComandaProductRequest){
        this.quantity = new Decimal(request.quantita);
        this.unit = new ShoppedUnit(
            QuantityUnits.getInstance(request.misura),
            request.descrizioneUnita
        );
    }

    public get textualQuantity(){
        return this.quantity.toFixed(
            this.unit.decimals
        );
    }

    public get textualQuantityWithUnit(){
        return `${this.textualQuantity} ${this.unit.toString()}`;
    }
}
export class Comanda{
    details: Map<Product, Array<ProductRequest>>;

    constructor(comanda: Array<ApiComandaRow>){
        this.details = new Map(
            comanda.map(
                row => [
                    new Product(row.prodotto),
                    row.comanda.map(
                        api => new ProductRequest(api)
                    )
                ]
            )
        );
    }
}
export class OrderGroup{
    user: User;
    timestamp: Date;
    orders: Array<number>;
    private _total: Decimal;
    private _totalExcludedIva: Decimal;
    private _iva: number;

    constructor(order: ApiOrderGroup){
        this.user = new User(order.utente);
        this.timestamp = new Date(order.timestamp);
        this.orders = order.ordini;
        this._total = new Decimal(order.totaleConIva);
        this._totalExcludedIva = new Decimal(order.totale);
        this.iva = 
            (this._total.minus(this._totalExcludedIva))
            .times(100)
            .dividedBy(this._totalExcludedIva)
            .toNumber();
    }

    public get total(): Decimal{
        return this._total;
    }
    public set total(total: Decimal){
        this._total = total;
        this._totalExcludedIva = total.times(100).dividedBy(100 + this.iva);
    }
    public get textualTotal(): string{
        return this._total.toFixed(3);
    }
    public set textualTotal(total: string){
        this.total = new Decimal(total);
    }

    public get totalExcludedIva(): Decimal{
        return this._totalExcludedIva;
    }
    public set totalExcludedIva(totalExcludedIva: Decimal){
        this._totalExcludedIva = totalExcludedIva;
        this._total = totalExcludedIva.plus(
            totalExcludedIva.times(this.iva).dividedBy(100)
        );
    }
    public get textualTotalExcludedIva(): string{
        return this._totalExcludedIva.toFixed(3);
    }
    public set textualTotalExcludedIva(totalExcludedIva: string){
        this.totalExcludedIva = new Decimal(totalExcludedIva);
    }

    public get iva(): number{
        return this._iva;
    }
    public set iva(iva: number){
        this._iva = iva;
        this._total = this._totalExcludedIva.plus(
            this._totalExcludedIva.times(iva).dividedBy(100)
        );
    }
}