import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Grid, TextField, InputAdornment } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { getLoadedSaleEvent, isLoading } from "../features/remote/events/commercialEvents/CommercialEventsSlice";
import { editSaleEvent, getSaleEvent } from "../features/remote/events/commercialEvents/SaleEventsActions";
import { ProductStatistics } from "../features/remote/products/ProductsAPI";
import { getLoadedProductStatistics, getProductStatistics } from "../features/remote/products/ProductsSlice";
import { useConfig } from "../features/remote/config/Config";
import { Decimal } from "decimal.js";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { SaleEvent } from "../features/remote/events/commercialEvents/SaleEventsAPI";
import { UnitDescriptionField } from "./UnitDescriptionField";
import { NumberTextField } from "./NumberTextField";

type EditSaleEventDialogProps = {
  eventId?: number;
  open: boolean;
  handleClose: () => any;
}

export default function EditSaleEventDialog(
  { eventId, open, handleClose }: EditSaleEventDialogProps
){

  const sale: SaleEvent | null = useAppSelector(getLoadedSaleEvent);
  const statistics = useAppSelector(getLoadedProductStatistics);
  const stock =
    sale && (
      (
        statistics &&
        statistics.find(statistic => statistic.unit === sale.unit)
      ) || (
        new ProductStatistics({
          quantitaDisponibile: '0',
          unita: sale.unit.name,
          quantitaAcquistata: '0',
          quantitaStornata: '0',
          quantitaVenduta: '0',
          prodottoId: sale.product.id
        })
      )
    );
  const totalStock =
    stock &&
    new ProductStatistics({
      ...stock.toApiObject(),
      quantitaDisponibile: stock.available.plus(sale.quantity).toFixed(2),
    });
  const [packageUnitDescription, setPackageUnitDescription] = useState<string>('');
  const [quantity, setQuantity] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [totPrice, setTotPrice] = useState('');
  const [iva, setIva] = useState<string>('');
  const loading = useAppSelector(isLoading);
  const { enqueueSnackbar } = useSnackbar();
  const { parseMessageFromError } = useConfig();
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if(!open){
        setPackageUnitDescription('');
        setQuantity('');
        setUnitPrice('');
        setTotPrice('');
        setIva('');
      }else if(eventId){
        dispatch(getSaleEvent({ id: eventId }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, eventId]
  );

  useEffect(
    () => {
      if(sale){
        setPackageUnitDescription(sale.unit.description || '');
        setQuantity(sale.textualQuantity);
        setUnitPrice(sale.textualUnitPrice);
        setTotPrice(sale.textualTotPriceExcludedIva);
        setIva(sale.iva.toString());
        dispatch(getProductStatistics({ id: sale.product.id }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sale]
  );

  function updateEvent(event: SyntheticEvent){
    event.preventDefault();
    if(sale){
      sale.unit.description = packageUnitDescription;
      sale.textualQuantity = quantity;
      sale.textualTotPriceExcludedIva = totPrice;
      sale.iva = parseInt(iva);
      dispatch(editSaleEvent(sale))
      .then(unwrapResult)
      .then(handleClose)
      .catch(
        error => enqueueSnackbar(
          parseMessageFromError(error),
          { variant: 'error' }
        )
      );
    }
  }

  function handleUnitPriceChange(event: React.ChangeEvent<HTMLInputElement>){
    const value = event.target.value;
    updateUnitPrice(value);
  }
  function updateUnitPrice(value: string){
    let totPrice = "";
    if(value){
      const unitPrice = new Decimal(value);
      totPrice = unitPrice.times(quantity || 0).toFixed(2);
    }
    setPricesFields(value, totPrice);
  }
  function handleTotPriceChange(event: React.ChangeEvent<HTMLInputElement>){
    const value = event.target.value;
    updateTotPrice(value);
  }
  function updateTotPrice(value: string){
    let unitPrice = "";
    if(value){
      const totPrice = new Decimal(value);
      unitPrice = totPrice.dividedBy(quantity || 0).toFixed(3);
    }
    setPricesFields(unitPrice, value);
  }
  function setPricesFields(unitPrice: string, totPrice: string){
    setUnitPrice(unitPrice);
    setTotPrice(totPrice);
  }

  useEffect(
    () => {
      if(totPrice)
        updateTotPrice(totPrice);
      else if(unitPrice)
        updateUnitPrice(unitPrice);
    }, [quantity] // eslint-disable-line react-hooks/exhaustive-deps
  );
  
  return <Dialog
    onClose={handleClose}
    open={open}
    fullWidth
  >
    <form onSubmit={updateEvent}>
      <DialogTitle>Aggiorna prodotto</DialogTitle>
      <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Prodotto"
                value={sale?.product?.name}
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Formato"
                value={sale?.unit.name}
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            {
              sale?.unit.name === "Collo" &&
              <Grid item xs={12}>
                <UnitDescriptionField
                  value={packageUnitDescription}
                  onChange={setPackageUnitDescription}
                  descriptionPrefix="Collo da "
                  disabled={loading}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <NumberTextField
                label="Quantità"
                value={quantity}
                onChange={event => setQuantity(event.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {sale?.unit.name}
                  </InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  step: 0.01
                }}
                helperText={totalStock && `in magazzino ${totalStock.textualAvailable}`}
                fullWidth
                disabled={loading}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                label="Prezzo unitario"
                value={unitPrice}
                onChange={handleUnitPriceChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">&euro;</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  step: 0.001
                }}
                fullWidth
                disabled={loading}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                label="Prezzo totale"
                value={totPrice}
                onChange={handleTotPriceChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">&euro;</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  step: 0.001
                }}
                fullWidth
                autoComplete="off"
                disabled={loading}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                label="IVA"
                value={iva}
                onChange={event => setIva(event.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  max: 100
                }}
                fullWidth
                autoComplete="off"
                disabled={loading}
                required
              />
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: 1 }}
        >
          <Button onClick={handleClose} color="error">
            Chiudi
          </Button>
          <Button type="submit">
            Aggiorna
          </Button>
        </Stack>
      </DialogActions>
    </form>
  </Dialog>
}