import { get } from "../connector/ConnectorAPI";

export async function getConfig(){
    const configuration: Config = await get('/configurazione', undefined, {noIdentity: true});
    return configuration;
}

export interface Config{
    AUTH0_DOMAIN: string;
    AUTH0_CLIENT_ID: string;
    ERRORS_CATALOG?: any;
}